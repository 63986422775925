import { createSlice } from "@reduxjs/toolkit";

const designListSlice = createSlice({
  name: "designList",
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {
    getDesignListAction(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    setDesignListDataAction(state, action) {
      return {
        loading: false,
        data: action.payload.data,
      };
    },
  },
});

export const { getDesignListAction, setDesignListDataAction } =
  designListSlice.actions;

export default designListSlice.reducer;
