import {createSlice} from "@reduxjs/toolkit";

const projectMaterialListSlice = createSlice({
  name: "projectMaterialList",
  initialState: {
    loading: false,
    data: []
  },
  reducers: {
    getProjectMaterialListAction(state, action) {
      return{
        ...state,
        loading: true
      }
    },
    setProjectMaterialListAction(state, action){
      return {
        loading: false,
        data: [...action.payload.data]
      }
    }
  }
})

export const {getProjectMaterialListAction, setProjectMaterialListAction} = projectMaterialListSlice.actions

export default projectMaterialListSlice.reducer