import PrivateRequestBase from "./rootRequest/PrivateRequestBase";

export function getCommonMaterialRequest(){
  return PrivateRequestBase("get", "api/material/")
}

export function projectMaterialCreateRequest(payload){
  return PrivateRequestBase("post", "api/projectmaterial/", payload)
}

export function getProjectMaterialListRequest(projectId) {
  return PrivateRequestBase("get", `api/projectmaterial/?project=${projectId}&order_by=created_at`)
}

export function editProjectMaterialRequest(projectMaterialId, payload){
  return PrivateRequestBase("put", `api/projectmaterial/${projectMaterialId}/`, payload)
}

export function deleteProjectMaterialRequest(projectMaterialId){
  return PrivateRequestBase("delete", `api/projectmaterial/${projectMaterialId}/`)
}