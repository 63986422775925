import {createSlice} from "@reduxjs/toolkit";

const seeList = createSlice({
  name: "seeListState",
  initialState: {
    loading: false,
    data: [],
    panoImages: []
  },
  reducers:{
    getRasterLayersAction(state, action){
      return{
        ...state, loading: true
      }
    },
    setSeeListDataAction(state, action) {
      return {
        loading: false,
        data: [...action.payload.data?.flight_resources],
        panoImages: [...action.payload.data?.pano_images] || []
      }
    },
    clearPanoAction(state, action) {
      return {
        loading: false,
        data: [],
        panoImages: []
      }
    }
  }
})

export const {getRasterLayersAction, setSeeListDataAction, clearPanoAction} = seeList.actions

export default seeList.reducer