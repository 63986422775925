import { createSlice } from "@reduxjs/toolkit";

const selectedProjectSlice = createSlice({
  name: "selectedProjectState",
  initialState: {},
  reducers: {
    selectProjectAction(state, action) {},
    setSelectedProjectAction(state, action) {
      return {
        ...action.payload.project,
      };
    },
    removeSelectedProjectAction() {
      return {};
    },
  },
});

export const {
  selectProjectAction,
  removeSelectedProjectAction,
  setSelectedProjectAction,
} = selectedProjectSlice.actions;

export default selectedProjectSlice.reducer;
