import {useLocation} from "react-router-dom";
import ProjectAppBarContent from "./ProjectAppBarContent";
import MainAppBarContent from "./MainAppBarContent";
import Invoice from "../Account/Invoice/";

const AppBarContent = () => {
  const location = useLocation()

  const renderComponent = () => {
    if (location.pathname === '/project') {
      return <ProjectAppBarContent/>
    }
    else {
      return <MainAppBarContent/>
    }
  }

  return (
    <>
      {renderComponent()}
    </>
  )
}

export default AppBarContent