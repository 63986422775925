import { createSlice } from "@reduxjs/toolkit";

const flightUploadProgressSlice = createSlice({
  name: "flightUploadProgressState",
  initialState: {
    tittleMessage: "",
    open: false,
    data: [],
  },
  reducers: {
    createNewFlightUploadProgressAction(state, action) {
      void state.data.push({
        ...action.payload.newFlightProgressUpload,
        uploadedPercentage: 0,
      });
      void (state.tittleMessage = `Uploading & Processing ${
        state.data.filter((f) => f?.uploadedPercentage < 100)?.length
      } Flights`);
    },
    updateFlightUploadedPercentageAction(state, action) {
      void state.data.forEach((item, i) => {
        if (item.flightName === action.payload.flightName) {
          state.data[i]["uploadedPercentage"] =
            action.payload.uploadedPercentage;
        }
      });
    },
    updateFlightUploadStatusAction(state, action) {
      void state.data.forEach((item, i) => {
        if (item.flightName === action.payload.flightName) {
          state.data[i]["uploadedStatus"] = action.payload.uploadedStatus;
        }
      });
    },
    deleteFlightUploadProgressEntryAction(state, action) {
      void (state.data = state.data.filter(
        (item) => item.flightName !== action.payload.flightName
      ));
      void (state.tittleMessage = `Uploading & Processing ${state.data.length} Flights`);
    },
  },
});

export const {
  createNewFlightUploadProgressAction,
  updateFlightUploadedPercentageAction,
  updateFlightUploadStatusAction,
  deleteFlightUploadProgressEntryAction,
} = flightUploadProgressSlice.actions;

export default flightUploadProgressSlice.reducer;
