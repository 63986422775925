import {createSlice} from "@reduxjs/toolkit";

const measurementDeleteSlice = createSlice({
  name: "measurementDelete",
  initialState: {
    selectedId: null,
    loading: false
  },
  reducers: {
    setMeasurementDeleteIdAction(state, action) {
      return {
        ...state,
        selectedId: action.payload.id
      }
    },
    measurementDeleteAction(state, action) {
      return {
        ...state,
        loading: true
      }
    },
    measurementDeleteSuccessAction() {
      return {
        selectedId: null,
        loading: false
      }
    }
  }
})

export const {setMeasurementDeleteIdAction, measurementDeleteAction, measurementDeleteSuccessAction} = measurementDeleteSlice.actions

export default measurementDeleteSlice.reducer