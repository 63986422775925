import {createSlice} from "@reduxjs/toolkit";

const smartTrackingCreate = createSlice({
  name: "smartTrackingCreate",
  initialState: {
    projectMaterial: null,
    loading: false,
    geojson: {}
  },
  reducers: {
    setProjectMaterialForSmartTrackingCreateAction(state, action) {
      return {
        ...state,
        projectMaterial: action.payload.id
      }
    },
    setSmartTrackingPolygonGeoJsonAction(state, action) {
      return {
        ...state,
        geojson: action.payload.geojson
      }
    },
    createSmartTrackingPolygonAction(state, action) {
      return {
        ...state,
        loading: true
      }
    },
    createSmartTrackingSuccessAction() {
      return {
        projectMaterial: null,
        loading: false,
        geojson: {}
      }
    }
  }
})

export const {
  setProjectMaterialForSmartTrackingCreateAction,
  setSmartTrackingPolygonGeoJsonAction,
  createSmartTrackingPolygonAction,
  createSmartTrackingSuccessAction,
} = smartTrackingCreate.actions

export default smartTrackingCreate.reducer