import {styled} from "@mui/material";
import {getIconComponentByType} from "../../utils/assets";
import IconButton from "@mui/material/IconButton";
import TextField from "../Common/TextField";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {editFlightNameAction, resetFlightEditAction} from "../../redux/slices/flight/edit";

const ListItemIconStyled = styled(ListItemIcon)(({theme}) => ({
  minWidth: 0
}))

const FlightEdit = ({flight}) => {
  const dispatch = useDispatch()
  const flightEdit = useSelector(state => state.flightEdit)
  const [flightName, setFlightName] = useState(flightEdit.name);
  const handleChange = e => {
    setFlightName(e.target.value)
  }
  const handleSave = () => {
    dispatch(editFlightNameAction({data: {name: flightName}, id: flightEdit.id}))
  }
  return (
    <List
    sx={{
      width: "100%"
    }}
    >
      <ListItem disableGutters disablePadding sx={{
        marginLeft: "16px"
      }}
      // secondaryAction={
      //   <>
      //   <ListItemIcon sx={{
      //     minWidth: 0
      //   }}>
      //       <IconButton>{getIconComponentByType("save")}</IconButton>
      //     </ListItemIcon>
      //     <ListItemIcon sx={{
      //     minWidth: 0
      //   }}>
      //       <IconButton>{getIconComponentByType("close")}</IconButton>
      //     </ListItemIcon>
      //   </>
      // }
      >
        <ListItemText
          disableTypography>
          <TextField
            variant={"standard"}
            label={"Change Flight Name"}
            name={"projectName"}
            value={flightName}
            onChange={handleChange}
          />
        </ListItemText>
        <ListItemSecondaryAction>
                <ListItemIconStyled>
                  <IconButton onClick={handleSave}>{getIconComponentByType("save")}</IconButton>
                </ListItemIconStyled>
                <ListItemIconStyled>
                  <IconButton onClick={() => dispatch(resetFlightEditAction())}>{getIconComponentByType("close")}</IconButton>

                </ListItemIconStyled>
              </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}

export default FlightEdit