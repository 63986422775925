export const panelData = [
    { 
    name: 'My Details', 
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="none">
        <path d="M7.35029 8.4998C9.67022 8.4998 11.5505 6.5973 11.5505 4.2499C11.5505 1.90249 9.67022 0 7.35029 0C5.03035 0 3.15012 1.90249 3.15012 4.2499C3.15012 6.5973 5.03035 8.4998 7.35029 8.4998ZM10.2904 9.56227H9.74241C9.01395 9.90094 8.20345 10.0935 7.35029 10.0935C6.49713 10.0935 5.68991 9.90094 4.95816 9.56227H4.41017C1.97539 9.56227 0 11.5611 0 14.0247V15.4059C0 16.2857 0.705496 16.9996 1.57506 16.9996H10.5956C10.5168 16.7738 10.484 16.5348 10.5103 16.2924L10.7334 14.2704L10.7728 13.9018L11.032 13.6395L13.5685 11.073C12.7646 10.1533 11.5997 9.56227 10.2904 9.56227ZM11.7769 14.3866L11.5537 16.4119C11.5176 16.7506 11.7998 17.0361 12.1313 16.9963L14.1296 16.7705L18.6546 12.1919L16.3019 9.81129L11.7769 14.3866ZM20.7711 8.92811L19.5275 7.66974C19.2223 7.36096 18.7235 7.36096 18.4184 7.66974L17.178 8.92479L17.0435 9.06092L19.3995 11.4415L20.7711 10.0537C21.0763 9.74157 21.0763 9.24021 20.7711 8.92811Z" fill="#626262"/>
        </svg>, 
    buttons: ['Personal'] 
},
    {
    name: 'My Account', 
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
         <path d="M7.13211 8.20547L3.4495 7.09453C3.02327 6.96797 2.72662 6.55664 2.72662 6.09961C2.72662 5.52656 3.17671 5.0625 3.73251 5.0625H5.99323C6.40923 5.0625 6.8184 5.19258 7.15939 5.43164C7.36739 5.57578 7.64699 5.54063 7.8243 5.36133L9.01092 4.16602C9.25302 3.92344 9.21892 3.51914 8.94954 3.30469C8.11414 2.62969 7.07073 2.25352 6.00005 2.25V0.5625C6.00005 0.253125 5.75454 0 5.45447 0H4.36333C4.06327 0 3.81776 0.253125 3.81776 0.5625V2.25H3.73251C1.56046 2.25 -0.185373 4.17305 0.0158069 6.45469C0.159019 8.07539 1.35928 9.39375 2.87324 9.85078L6.36831 10.9055C6.79454 11.0355 7.09119 11.4434 7.09119 11.9004C7.09119 12.4734 6.64109 12.9375 6.08529 12.9375H3.82458C3.40858 12.9375 2.9994 12.8074 2.65842 12.5684C2.45042 12.4242 2.17082 12.4594 1.9935 12.6387L0.806886 13.834C0.564788 14.0766 0.598887 14.4809 0.868263 14.6953C1.70367 15.3703 2.74708 15.7465 3.81776 15.75V17.4375C3.81776 17.7469 4.06327 18 4.36333 18H5.45447C5.75454 18 6.00005 17.7469 6.00005 17.4375V15.743C7.58902 15.7113 9.07912 14.7375 9.60423 13.1871C10.3373 11.0215 9.1064 8.79961 7.13211 8.20547Z" fill="black"/>
        </svg>,
   buttons: ['Update Plans', 'Invoice'] 
},
    { 
    name: 'Settings', 
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
        <path d="M15.8852 10.4262C15.9199 10.1294 15.943 9.82063 15.943 9.5C15.943 9.17937 15.9199 8.87063 15.8736 8.57375L17.8292 7.00625C18.0028 6.86375 18.0491 6.6025 17.9449 6.40062L16.0935 3.11125C15.9777 2.8975 15.7347 2.82625 15.5264 2.8975L13.2237 3.8475C12.7377 3.4675 12.2285 3.15875 11.6615 2.92125L11.3143 0.40375C11.2796 0.16625 11.0829 0 10.8515 0H7.14853C6.91709 0 6.73195 0.16625 6.69723 0.40375L6.35008 2.92125C5.78307 3.15875 5.26234 3.47937 4.7879 3.8475L2.48513 2.8975C2.27684 2.81438 2.03384 2.8975 1.91812 3.11125L0.0666476 6.40062C-0.0490694 6.61437 -0.00278243 6.86375 0.182365 7.00625L2.13798 8.57375C2.0917 8.87063 2.05698 9.19125 2.05698 9.5C2.05698 9.80875 2.08012 10.1294 2.12641 10.4262L0.170793 11.9938C-0.0027827 12.1363 -0.0490694 12.3975 0.0550759 12.5994L1.90655 15.8888C2.02226 16.1025 2.26527 16.1737 2.47356 16.1025L4.77633 15.1525C5.26234 15.5325 5.7715 15.8413 6.33851 16.0788L6.68566 18.5963C6.73195 18.8337 6.91709 19 7.14853 19H10.8515C11.0829 19 11.2796 18.8337 11.3028 18.5963L11.6499 16.0788C12.2169 15.8413 12.7377 15.5206 13.2121 15.1525L15.5149 16.1025C15.7232 16.1856 15.9662 16.1025 16.0819 15.8888L17.9334 12.5994C18.0491 12.3856 18.0028 12.1363 17.8176 11.9938L15.8852 10.4262ZM9 13.0625C7.09067 13.0625 5.52849 11.4594 5.52849 9.5C5.52849 7.54063 7.09067 5.9375 9 5.9375C10.9093 5.9375 12.4715 7.54063 12.4715 9.5C12.4715 11.4594 10.9093 13.0625 9 13.0625Z" fill="#626262"/>
      </svg>,
    buttons: ['Change Password'] 
},
{ 
    name: 'Help Center', 
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9.9 15.3H8.1V13.5H9.9V15.3ZM11.763 8.325L10.953 9.153C10.305 9.81 9.9 10.35 9.9 11.7H8.1V11.25C8.1 10.26 8.505 9.36 9.153 8.703L10.269 7.569C10.602 7.245 10.8 6.795 10.8 6.3C10.8 5.31 9.99 4.5 9 4.5C8.01 4.5 7.2 5.31 7.2 6.3H5.4C5.4 4.311 7.011 2.7 9 2.7C10.989 2.7 12.6 4.311 12.6 6.3C12.6 7.092 12.276 7.812 11.763 8.325Z" fill="#626262"/>
  </svg>,
    buttons: ['Raise an Issue', 'FAQs'] 
},
  ];