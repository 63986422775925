import {createSlice} from "@reduxjs/toolkit";

const flightEdit = createSlice({
  name: "flightEdit",
  initialState: {},
  reducers: {
    setFlightForEditAction(state, action) {
      return{
        ...action.payload.flight
      }
    },
    resetFlightEditAction(){
      return {}
    },
    editFlightNameAction(){}
  }
})

export const {setFlightForEditAction, resetFlightEditAction, editFlightNameAction} = flightEdit.actions

export default flightEdit.reducer