export function setupRawFlightImagesGeoJson(points){
  return {
    type: "FeatureCollection",
    features: points.map(point => {
      return {
        type: 'Feature',
        // properties: {
        //   user_ct_id: polygon.id,
        //   user_name: polygon.name,
        //   user_color: '#' + polygon.color
        // },
        geometry: {
          type: 'Point',
          coordinates: point
        }
      }
    })
  }
}

export function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export function setupGcpDataHelper(points) {
  return {
    type: "FeatureCollection",
    features: points.map(point => {
      return {
        type: 'Feature',
        // properties: {
        //   user_ct_id: polygon.id,
        //   user_name: polygon.name,
        //   user_color: '#' + polygon.color
        // },
        geometry: {
          type: 'Point',
          coordinates: [point[1], point[2]]
        }
      }
    })
  };
}