import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import {getIconComponentByType} from "../../utils/assets";
import Typography from "@mui/material/Typography";

const Export = ({tittle, onSave, avatarIcon, actionIcon = "saveAlt", isAction, isDisabled}) => {
  return (
    <Card raised>
      <CardHeader
        disableTypography
        sx={{
          padding: "3px 5px 3px 16px"
        }}
        avatar={
          getIconComponentByType(avatarIcon)
        }
        action={
          !isAction && <IconButton onClick={onSave} disabled={isDisabled}>
            {getIconComponentByType(actionIcon)}
          </IconButton>
        }
        title={<Typography variant="body1">{tittle}</Typography>}
      />
    </Card>
  )
}

export default Export