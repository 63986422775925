import {Avatar, ListItemAvatar, ListItemText, Menu, MenuItem, styled, Typography} from "@mui/material";
import {getIconComponentByTypeWithProps} from "../../utils/assets";
import React from "react";
import {useDispatch} from "react-redux";
import {setFlightForEditAction} from "../../redux/slices/flight/edit";
import {setFlightForUpdateAction} from "../../redux/slices/flight/updateDate";

import {setDeleteFlightSelectedIdAction} from "../../redux/slices/flight/delete";
import FlightDeleteDialog from "./DeleteDialog";
import DatePicker from "components/Common/DatePicker";


const RECENT_FLIGHT_OPERATIONS = [
  { key: 'edit', title: 'Edit' },
  { key: 'delete', title: 'Delete' },
  { key: 'add', title: 'Update Date' }
];

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.white + "!important",
  color: theme.palette.text.link + "!important",
}));

const FlightAction = ({ flight, handleToggleMenuClose, anchorEl, isLast }) => {
  const dispatch = useDispatch();
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (key) => {
    if (key === "delete") {
      dispatch(setDeleteFlightSelectedIdAction({ id: flight.id }));
    } else if (key === "edit") {
      dispatch(setFlightForEditAction({ flight }));
    } else if (key === "add") {
      dispatch(setFlightForUpdateAction({ flight }));
    } else {
      console.log("Invalid Action");
    }
    handleToggleMenuClose();
  };

  return (
    <>
      <Menu
        id="flight-card-crud-dialog"
        aria-labelledby="flight-card-crud-dialog"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleToggleMenuClose}
      >
        {RECENT_FLIGHT_OPERATIONS.map((operation, index) => {
          if (isLast || (!isLast && (operation.key === 'edit' || operation.key === 'add'))) {
            return (
              <MenuItem
                sx={{ minHeight: 32, height: 32 }}
                onClick={() => handleMenuClick(operation.key)}
                key={`flight-operation-${operation.title}-${index}`}
              >
                <ListItemAvatar>
                  <AvatarStyled>
                    {getIconComponentByTypeWithProps(operation.key)({
                      fontSize: "small",
                    })}
                  </AvatarStyled>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle2">{operation.title}</Typography>}
                />
              </MenuItem>
            );
          }
          return null;
        })}
      </Menu>
      {isLast && (
        <FlightDeleteDialog
          flight={flight}
          onClose={() => dispatch(setDeleteFlightSelectedIdAction({ id: null }))}
        />
      )}
    </>
  );
};

export default FlightAction;
