import PublicAPI from "../../../../utils/API/PublicAPI";

const PublicRequestBase = (method, url, data, options={}) => {
    return PublicAPI.request({
        method,
        url,
        data,
        ...options
    })
}

export default PublicRequestBase