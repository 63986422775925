import createSageMiddleware from "redux-saga"
import {persistReducer, persistStore} from "redux-persist";
import {persistConfig} from "./persistConfig";
import rootReducer from "./rootReducer";
import {configureStore} from "@reduxjs/toolkit";
import {watcherSaga} from "./sagas/rootSaga";

const sagaMiddleware = createSageMiddleware()

const persistedReducers = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({thunk: false}).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(watcherSaga)

export const persistor =persistStore(store)

export default store