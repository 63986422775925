import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, authRequired = true, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

      if (!authRequired || isAuthenticated) {
        return <Component {...props} />;
      } else {
        return <Redirect to="/" />;
      }
    }}
  />
);

export default PrivateRoute;
