import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "../../Common/TextField";
import {useForm} from "../../../hooks/useForm";
import {useDispatch, useSelector} from "react-redux";
import ColorPicker from "../../Common/ColorPicker";
import SubmitResetButton from "../../Common/SubmitResetButton";
import {createMeasurementAction} from "../../../redux/slices/measurement/create";
import {setSelectedMeasurementAction} from "../../../redux/slices/measurement/selected";
import {useHistory} from "react-router-dom";
import {editMeasurementAction} from "../../../redux/slices/measurement/edit";
import {useEffect} from "react";

const initialValue = {
  name: "",
  label: "",
  color: "#A430FF"
}

const MeasurementCreateOrEdit = ({drawTool, measurement}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const measurementType = useSelector(state => state.measurementCreate.type)
  const createLoading = useSelector(state => state.measurementCreate.loading)
  const editLoading = useSelector(state => state.measurementEdit.loading)

  const totalCreatedMeasurementCount = useSelector(state => {
    const type = measurementType === "Point"  ? 0 : (measurementType === "Polyline" ? 1 : 2)
    return state.measurementList.data.filter(item => item.type === type).length
  })

  const validate = (fieldValues = values) => {
    let temp = {...errors}
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required."
    setErrors({
      ...temp
    })


    if (fieldValues === values)
      return Object.values(temp).every(x => x === "")
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
    setValues
  } = useForm(measurement ? measurement : initialValue, validate, true)

  const handleColorChange = color => {
    setValues({
      ...values,
      color: color,
    })
  }

  const handleSubmit = () => {
    if (validate()) {
      if (measurement) {
        dispatch(editMeasurementAction({...values, history: history, drawTool: drawTool}))
      } else {
        dispatch(createMeasurementAction({...values, history: history, drawTool: drawTool}))

      }
    }
  }

  useEffect(() => {
    !measurement && setValues(prevState => ({
      ...prevState,
      name: `${measurementType}-${totalCreatedMeasurementCount + 1}`
    }))
  }, [measurement, measurementType, setValues, totalCreatedMeasurementCount])

  return (
    <Box pt={2} m={1}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <TextField
            label={`${measurementType} Name`}
            fullWidth
            required
            name="name"
            value={values.name}
            error={errors.name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <TextField
            label={`${measurementType} Label`}
            fullWidth
            name="label"
            value={values.label}
            error={errors.label}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <ColorPicker
            label={`Select ${measurementType} color`}
            selectedColor={values.color}
            handleColorChange={handleColorChange}
          />
        </Grid>
        <Grid item>
          <SubmitResetButton
            handleSubmit={handleSubmit}
            resetForm={resetForm}
            loading={measurement ? editLoading : createLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default MeasurementCreateOrEdit