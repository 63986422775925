import { call, put } from "redux-saga/effects";
import { seeListRequest } from "../requests/seeRequests";
import { setSeeListDataAction, clearPanoAction } from "../../slices/see/list";
import { createRasterLayerAction, resetCombinedLayers } from "../../slices/layers/rasterLayersSlice";
import { hideMeasurementLayers, moveMeasurementLayersToTop, removeMeasurementLayers } from "utils/layers/mapUtils";

export function* seeListHandler(action) {
  try {
    const flightId = action.payload.flightId;
    const response = yield call(seeListRequest, flightId);
    // removeMeasurementLayers();
    yield put(resetCombinedLayers());
    // console.log(' liisss',  response.data)
    yield put(setSeeListDataAction({ data: response.data }));
    yield put(createRasterLayerAction({ data: response.data }));
    moveMeasurementLayersToTop('pano');
  } catch (error) {
    console.log(error);
  }
}
