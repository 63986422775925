import {call, put, select} from "redux-saga/effects";
import {
  createSmartTrackingPolygonRequest,
  deleteSmartTrackingPolygonRequest, editSmartTrackingPolygonRequest,
  getSmartTrackingPolygonListRequest
} from "../requests/smartTrackingRequests";
import {createSmartTrackingSuccessAction} from "../../slices/smartTracking/create";
import {area} from "@turf/turf"
import {getProjectMaterialListAction} from "../../slices/material/projectMaterial/list";
import {clearSmartTrackingLoading, getSmartTrackingPolygonListAction, setSmartTrackingPolygonDataAction} from "../../slices/smartTracking/list";
import {createSmartTrackingPolygonLayers} from "../../slices/layers/geoJsonLayersSlice";
import {deleteSmartTrackingPolygonSuccessAction} from "../../slices/smartTracking/delete";
import {editSmartTrackingPolygonSuccessAction} from "../../slices/smartTracking/edit";

export function* createSmartTrackingPolygonHandler(action) {
  try {
    const geojson = yield select(state => state.smartTrackingCreate.geojson)
    const selectedFlightId = yield select(state => state.flightSelected.id)
    const projectMaterialColor = yield select(state => state.projectMaterialList.data.filter(item => item.id === action.payload.project_material)[0].color)
    const payload = {
      name: action.payload.name,
      project_material: action.payload.project_material,
      points: geojson.geometry,
      flight: selectedFlightId,
      color: projectMaterialColor,
      area: area(geojson)
    }
    yield call(createSmartTrackingPolygonRequest, payload)
    yield put(createSmartTrackingSuccessAction())
    action.payload.drawTool.finishGeometry()
    action.payload.history.push("/smart-tracking")
  } catch (error) {
    console.log(error)
  }
}

export function* getSmartTrackingPolygonListHandler() {
  try {
    yield put(getProjectMaterialListAction())
    const selectedFlightId = yield select(state => state.flightSelected.id)
    const response = yield call(getSmartTrackingPolygonListRequest, selectedFlightId)
    yield put(setSmartTrackingPolygonDataAction({data: response.data}))
    yield put(createSmartTrackingPolygonLayers({data: response.data}));
    yield put(clearSmartTrackingLoading());

  } catch (error) {
    console.log(error)
  }
}

export function* deleteSmartTrackingPolygonHandler(action) {
  try {
    yield call(deleteSmartTrackingPolygonRequest, action.payload.id)
    yield put(deleteSmartTrackingPolygonSuccessAction())
    yield put(getSmartTrackingPolygonListAction())
  } catch (error) {
    console.log('error in deleting smart tracking',error)
  }
}

export function* editSmartTrackingPolygonHandler(action){
  try {
    const payload = {
      name: action.payload.name,
      project_material: action.payload.project_material,
      points: action.payload.points
    }
    yield call(editSmartTrackingPolygonRequest, action.payload.id, payload)
    yield put(editSmartTrackingPolygonSuccessAction())
    action.payload.drawTool.finishGeometry()
    action.payload.history.push("/smart-tracking")
  } catch (error) {
    console.log(error)
  }
}