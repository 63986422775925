import React, { useEffect, useRef, useState } from 'react';
import Marzipano from 'marzipano';
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Tooltip, CircularProgress } from "@mui/material";
import { setPanoViewAction } from "redux/slices/mapbox/mapSlice";
import { clearPanoImage } from "redux/slices/Panorama/list";
import CloseIcon from '@mui/icons-material/Close';

const PanoramaComponent = () => {
  const dispatch = useDispatch();
  const viewerRef = useRef(null);
  const panoImage = useSelector((state) => state.pano);
  const [loading, setLoading] = useState(true);  // Loading state

  useEffect(() => {
    const viewerElement = viewerRef.current;
    const viewer = new Marzipano.Viewer(viewerElement);
    const dir_path = panoImage?.tiles_dir_path;
    const faceMap = {
      b: 'b',
      f: 'f',
      l: 'l',
      r: 'r',
      u: 'u',
      d: 'd',
    };
    const panoPath = `${process.env.REACT_APP_BACKEND_URL}/api/panorama_image_tiles/?dir_path=${dir_path}&z_value={z}&image_name={f}{y}_{x}.jpg`;
    const source = Marzipano.ImageUrlSource.fromString(panoPath, {
      faceMap: faceMap,
    });

    const geometry = new Marzipano.CubeGeometry([
      { tileSize: 512, size: 512 },
      { tileSize: 1024, size: 1024 },
    ]);

    const limiter = Marzipano.RectilinearView.limit.traditional(8192, 100 * Math.PI / 180);
    const view = new Marzipano.RectilinearView(null, limiter);

    const scene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      webglConstraints: {
        alpha: false,
        depth: false,
        antialias: false,
        preserveDrawingBuffer: false,
        powerPreference: 'high-performance',
      }
    });

    setLoading(true);
    scene.switchTo({ transitionDuration: 0 });

    source.addEventListener('tileLoaded', () => {
      setLoading(false); 
    });

    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 5000); 

    return () => {
      clearTimeout(loadingTimeout);
      viewer.destroy();
    };
  }, [panoImage]);

  const handlePanoViewClick = () => {
    dispatch(setPanoViewAction(false));
    dispatch(clearPanoImage());
  };

  return (
    <div style={{
      position: 'fixed',
      top: '8%',
      left: 0,
      width: '100%',
      height: '100vh',
      zIndex: 9999, 
      backgroundColor: 'white', 
    }}>
      <Tooltip title='Close Pano View'>
        <IconButton
          onClick={handlePanoViewClick}
          sx={{ position: 'absolute', top: '10px', right: '10px', zIndex: 10000 }}
        >
          <CloseIcon
            sx={{ width: 40, height: 40 }}
            style={{ backgroundColor: "lightgray", color: "darkgray", borderRadius: "20px" }}
          />
        </IconButton>
      </Tooltip>

      {loading && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10000,
        }}>
          <CircularProgress color="primary" />
        </div>
      )}

      <div ref={viewerRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default PanoramaComponent;
