import Layout from "../../components/Layout";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {getIconComponentByType} from "../../utils/assets";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {NavLink} from "react-router-dom";
import FlightCreate from "../../components/Flight/Create";

const FlightAddPage = () => {
  return(
    <Box pt={2}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <IconButton
            component={NavLink}
            to="/flight"
            >
            {getIconComponentByType("keyboardBack")}
          </IconButton>
          </Grid>
          <Grid item container xs={10} justifyContent="center">
             <Typography variant="h5">Flights</Typography>
          </Grid>
        </Grid>
        <FlightCreate/>
      </Box>
    // <Layout sideNavComponent={
    //
    // }/>
  )
}

export default FlightAddPage