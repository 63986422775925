import PrivateRequestBase from "./rootRequest/PrivateRequestBase";

export function getFlightListRequest(projectId){
  return PrivateRequestBase("get", `api/flight/?project=${projectId}`)
}

export function createGeoTiffFlightRequest(payload, option={}){
  return PrivateRequestBase("post", "api/processedflight/", payload, option)
}

export function createRawFlightRequest(payload){
  return PrivateRequestBase("post", "api/rawflight/", payload)
}

export function createRawFlightGCPResourceRequest(payload){
  return PrivateRequestBase("post", "api/rawflightcsvresource/", payload)
}

export function createRawFlightImageRequest(payload) {
  return PrivateRequestBase("post", "api/rawflightimageresource/", payload)
}

export function editFlightNameRequest(flightId, payload) {
  return PrivateRequestBase("patch", `api/flight/${flightId}/`, payload)
}

export function deleteFlightRequest(flightId){
  return PrivateRequestBase("delete", `api/flight/${flightId}`)
}

export function createFlightWebSocketRequest(projectUuid) {
  return new WebSocket(`${process.env.REACT_APP_BACKEND_SOCKET_URL}/ws/flight/${projectUuid}/`)
}