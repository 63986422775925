import StockpileReport from "../../../components/StockpileReport";

const StockpileReportPage = () => {
    return (
      <>
        <StockpileReport />
        </>
    )
};

export default StockpileReportPage;