import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  file: null,
};

const panoSlice = createSlice({
  name: 'panoSlice',
  initialState,
  reducers: {
    setPanoImage: (state, action) => {
      state.id = action.payload.id;
      state.file = action.payload.file;
      state.tiles_dir_path = action.payload.tiles_dir_path;
    },
    clearPanoImage: (state) => {
      state.id = null;
      state.file = null;
    },
  },
});

export const { setPanoImage, clearPanoImage } = panoSlice.actions;

export default panoSlice.reducer;
