import PublicRequestBase from "../rootRequest/PublicRequestBase";
import PrivateRequestBase from "../rootRequest/PrivateRequestBase";

export function googleLoginRequest(payload) {
  return PublicRequestBase("post", "api/auth/google-login/", payload);
}

export function loginRequest(payload) {
  return PublicRequestBase("post", "api/auth/login/", payload);
}

export function userRecordRequest() {
  return PrivateRequestBase("get", "api/auth/user/");
}

export function logoutRequest() {
  return PublicRequestBase("post", "api/auth/logout/");
}
