import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useForm } from "../../hooks/useForm";
import FileDropBox from "../Common/FileDropBox";
import SubmitResetButton from "../Common/SubmitResetButton";
import Loader from "../Common/Loader";
import { createLineworkAction } from "redux/slices/linework/create";

const initialValues = {
  name: '',
  file: null,
};

const LineworkCreate = ({ linework }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const createLoading = useSelector((state) => state?.lineworkCreate?.loading);
  const projectUuid = useSelector((state) => state.selectedProject);
  const createdLinework = useSelector((state) => state?.lineworkCreate?.createdLinework);
  const [isLoading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ('file' in fieldValues)
      temp.file = fieldValues.file ? "" : "This field is required.";
    
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
  };

  const { values, errors, setErrors, handleInputChange, resetForm, setValues } = useForm(initialValues, validate, true);

  const handleSubmit = () => {
    if (validate()) {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('file', values.file?.[0]);
      formData.append('project', projectUuid?.id); 

      setLoading(true);
      dispatch(createLineworkAction({ formData, history }));
      setLoading(false);
    }
  };

  return (
    <Box pt={2} m={1}>
      {isLoading && <Loader />}
      {createdLinework && (
        <Typography align="center" mt={4}>
          Linework "{createdLinework.name}" created successfully!
        </Typography>
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            label="Linework Name"
            fullWidth
            required
            name="name"
            value={values.name}
            error={Boolean(errors.name)}
            helperText={errors.name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <FileDropBox
            label="KML or KMZ File"
            onFileDrop={(file) => setValues({ ...values, file })}
            name="file"
            acceptFileType=".kml,.kmz"
            maxSize={3 * 1024 * 1024} // 3MB size limit
            error={errors.file}
          />
        </Grid>
        <Grid item>
          <SubmitResetButton resetForm={resetForm} handleSubmit={handleSubmit} loading={createLoading} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LineworkCreate;
