import Invoice from "../../../components/Account/Invoice";


const InvoicePage = () => {

  return (
    <>
   <Invoice />
   </>
  );
};

export default InvoicePage;
