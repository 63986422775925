import {createSlice} from "@reduxjs/toolkit";

const flightDateUpdate = createSlice({
  name: "flightDateUpdate",
  initialState: {},
  reducers: {
    setFlightForUpdateAction(state, action) {
      return{
        ...action.payload.flight
      }
    },
    resetFlightUpdateAction(){
      return {}
    },
  }
})

export const {setFlightForUpdateAction, resetFlightUpdateAction } = flightDateUpdate.actions

export default flightDateUpdate.reducer