import {createSlice} from "@reduxjs/toolkit";

const commonMaterialSlice = createSlice({
  name: "commonMaterial",
  initialState: [],
  reducers: {
    getCommonMaterialAction(){},
    setCommonMaterialAction(state, action){
      return [
        ...action.payload.materials
      ]
    }
  }
})

export const {getCommonMaterialAction, setCommonMaterialAction} = commonMaterialSlice.actions

export default commonMaterialSlice.reducer