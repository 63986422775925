import {createContext} from "react";
import DrawTool from "./DrawTool";

export const DrawToolContext = createContext(null);

const DrawToolContextProvider = (props) => {
  const drawTool = new DrawTool()
  return (
    <DrawToolContext.Provider value={drawTool}>
      {props.children}
    </DrawToolContext.Provider>
  )
}

export default DrawToolContextProvider