import {createSlice} from "@reduxjs/toolkit";

const smartTrackingList = createSlice({
  name: "smartTrackingList",
  initialState: {
    loading: false,
    data: []
  },
  reducers: {
    getSmartTrackingPolygonListAction(state, action){
      return {
        loading: true,
        data: []
      }
    },
    setSmartTrackingPolygonDataAction(state, action){
      return {
        loading: true,
        data: action.payload.data
      }
    },
    clearSmartTrackingLoading(state, action) {
      return {
        ...state,
        loading: false
      }
    },
    updateSmartTrackingPolygonDataAction(state, action){
      void (state.data = [...state.data.filter(item => item.id !== action.payload.data.id), action.payload.data])
    }
  }
})

export const {
  getSmartTrackingPolygonListAction, 
  setSmartTrackingPolygonDataAction,
   updateSmartTrackingPolygonDataAction, 
  clearSmartTrackingLoading
} = smartTrackingList.actions
export default smartTrackingList.reducer