import palette from './palette';

const typo = {
  fontFamily: "Roboto, sans-serif",
  h1: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '35px',
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '29px',
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '28.13px',
    letterSpacing: '-0.44px'
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '15px',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: '-0.33px',
  },
  h6: {
    color: palette.grey[700],
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    fontStyle: 'normal'
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '14px',
  },
  subtitle2: {
    color: palette.secondary.dark,
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '11.72px',
    letterSpacing: '-0.55px'
  },
  body1: {
    color: palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.41px',
    letterSpacing: '-0.33px',
  },
  body2: {
    color: palette.text.secondary,
    fontSize: '12px',
    letterSpacing: '-0.387234px',
    lineHeight: '14.06px',
  },
  button: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16.41px',
    letterSpacing: '-0.33px',
    textTransform: 'none',
  },
  caption: {
    color: palette.white,
    fontSize: '10px',
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '11px',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
};

export default typo;
