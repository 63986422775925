import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {NavLink} from "react-router-dom";
import {getIconComponentByType} from "../../utils/assets";
import Typography from "@mui/material/Typography";
import FlightCreate from "../../components/Flight/Create";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MaterialCreateOrEdit from "../../components/Material/CreateOrEdit";

const MaterialAddPage = () => {
  return (
    <Box pt={2}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <IconButton
            component={NavLink}
            to="/material"
            >
            {getIconComponentByType("keyboardBack")}
          </IconButton>
          </Grid>
          <Grid item container xs={10} justifyContent="center">
             <Typography variant="h5">Add Material</Typography>
          </Grid>
        </Grid>
      <Divider/>
        <MaterialCreateOrEdit/>
      </Box>
  )
}

export default MaterialAddPage