import React from 'react';
import headerImage from '../../assets/images/header.jpeg';
import { Box } from '@mui/material';

const boxStyle = {
  width: '1355px',
  height: '196px',
  flexShrink: 0,
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.49) 0%, rgba(0, 0, 0, 0.49) 100%), url(${headerImage})`,
  backgroundSize: 'cover', 
  backgroundPosition: 'center', 
  boxShadow: '0px 20px 27px 0px rgba(0, 0, 0, 0.05)',
};


const header = ({ title, descriptionText }) => {
  return (
    <Box style={boxStyle}
    alignItems="center"
    display="flex"
    flexDirection="column"
    pt={2}
    pb={2}
    mt={3}
    >
</Box>
  );
};

export default header;
