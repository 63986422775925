import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const presetColors = [
  "#A430FF", "#3C704C", "#EC4E50", "#FEB300", "#F30C7D", "#FE0F00", "#029B89",
  "#0A1D37", "#0081C8", "#BC4E82", "#00FF19", "#F44B12", "#482222", "#09DCEA",
  "#1C1AB0", "#00930F", "#FF0300", "#6A4601", "#1D7100", "#0B0035", "#003213",
  "#FAC900", "#3B289F", "#181818", "#AEA70A", "#FF0080", "#AE0A13", "#340067"
];

const ColorPicker = ({label, selectedColor, handleColorChange}) => {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item> <Typography>{label}</Typography></Grid>
      <Grid item>
        <div className="picker__swatches">
          {presetColors.map((presetColor) => (
            <button
              key={presetColor}
              className={presetColor === selectedColor ? "picker__swatch picker__swatch__selected" : "picker__swatch"}
              style={{background: presetColor}}
              onClick={() => handleColorChange(presetColor)}
            />
          ))}
        </div>
      </Grid>
    </Grid>
  )
}

export default ColorPicker