import { createSlice } from "@reduxjs/toolkit";

const reportSlice = createSlice({
    name: "reportSlice",
    initialState: {
        data: {}
    },
    reducers: {
        stockpileReportAction(state, action) {},
        setStockpileReportDataAction(state, action) {
        return {
            ...state, 
            data: action.payload  
        };

    },
    }

});

export const { stockpileReportAction, setStockpileReportDataAction } = reportSlice.actions;
export default reportSlice.reducer;