import Layout from "../../components/Layout";
import FlightList from "../../components/Flight/List";

const FlightPage = () => {
  return(
    <FlightList/>
    // <Layout
    //   sideNavComponent={
    //
    //   }
    // />
  )
}

export default FlightPage