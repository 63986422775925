import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
  name: "drawerState",
  initialState: {
    menuItems: [
      {
        name: "Projects",
        icon: "FolderSpecialIcon",
        selected: true,
        initialDrawer: true,
        link: "/project",
      },
      {
        name: "Flights",
        icon: "FlightTakeoffIcon",
        selected: false,
        initialDrawer: false,
        link: "/flight",
      },
      {
        name: "See",
        icon: "VisibilityIcon",
        selected: false,
        initialDrawer: false,
        link: "/see",
      },
      {
        name: "Measure",
        icon: "StraightenIcon",
        selected: false,
        initialDrawer: false,
        link: "/measure",
      },
      {
        name: "Materials",
        icon: "ViewQuiltIcon",
        selected: false,
        initialDrawer: false,
        link: "/material",
      },
      {
        name: "Smart Tracking",
        icon: "TerrainIcon",
        selected: false,
        initialDrawer: false,
        link: "/smart-tracking",
      },
      {
        name: "Design",
        icon: "TransformIcon",
        selected: false,
        initialDrawer: false,
        link: "/design",
      },
      {
        name: "Report",
        icon: "CreateNewFolderIcon",
        selected: false,
        initialDrawer: false,
        link: "/report",
      },
      {
        name: "Export",
        icon: "GetAppIcon",
        selected: false,
        initialDrawer: false,
        link: "/export",
      },
      {
        name: "LineWork",
        icon: "profileIcon",
        selected: false,
        initialDrawer: false,
        link: "/linework",
      }, 
      {
        name: "Submit Ticket",
        icon: "NoteAddIcon",
        selected: false,
        initialDrawer: false,
        link: "/support",
      },
    {
        name: "Support",
        icon: "QuizIcon",
        selected: false,
        initialDrawer: false,
        link: "",
      },
      {
        name: "Account",
        icon: "AccountCircleIcon",
        selected: false,
        initialDrawer: false,
        link: "/account",
      }
    ],
    drawerExpand: true,
  },
  reducers: {
    updateDrawerItemSelectedAction(state, action) {
      void state.menuItems.map((item, index) =>
        index === action.payload.index
          ? (item.selected = true)
          : (item.selected = false)
      );
    },
    setDrawerExpandAction(state, action) {
      return {
        ...state,
        drawerExpand: action.payload.drawerExpand,
      };
    },
    updateDrawerByCurrentLinkAction(state, action) {
      const links = state.menuItems.map((item) => item.link);
      const index = links.indexOf(action.payload.link);
      if (index !== -1) {
        void state.menuItems.map((item, index) =>
          item.link === action.payload.link
            ? (item.selected = true)
            : (item.selected = false)
        );
      }
    },
  },
});

export const {
  updateDrawerItemSelectedAction,
  setDrawerExpandAction,
  updateDrawerByCurrentLinkAction,
} = drawerSlice.actions;
export default drawerSlice.reducer;
