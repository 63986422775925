import Grid from "@mui/material/Grid";
import Button from "./Button";
import LoadingButton from "./LoadingButton";

const SubmitResetButton = ({ resetForm, handleSubmit, loading = false }) => {
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item>
        <Button
          label="Reset"
          onClick={resetForm}
          type="secondary"
          sx={{
            width: 94,
          }}
        />
      </Grid>
      <Grid item>
        <LoadingButton
          label="Submit"
          sx={{
            width: 94,
            height: 37,
          }}
          loading={loading}
          onClick={handleSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default SubmitResetButton;
