import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getProjectListAction } from "../../redux/slices/project/list";

const ProjectPage = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getProjectListAction())
  }, [])



  return null
}

export default ProjectPage