import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import {
  getIconComponentByType,
  getIconComponentByTypeWithProps,
} from "../../utils/assets";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { formatDate } from "../../utils/formattings";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { CircularProgress, Tooltip, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDesignFileAction,
  saveDesignFileAction,
  setDeleteDesignIdAction,
} from "../../redux/slices/design/deleteAndSave";
import { setDeleteProjectMaterialIdAction } from "../../redux/slices/material/projectMaterial/delete";
import DeleteDialog from "../Common/DeleteDialog";

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.white + "!important",
  color: theme.palette.text.link + "!important",
}));

const DesignItem = ({ design }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const deleteSelectedId = useSelector(
    (state) => state.designDeleteAndSave.selectedId
  );
  const loading = useSelector((state) => state.designDeleteAndSave.loading);

  const openMenu = Boolean(anchorEl);

  const handleToggleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };

  const saveDesignFile = () => {
    if (design?.processed) {
      const designFile = design?.resources?.filter(f => f?.type === 'design_raster');
      dispatch(saveDesignFileAction({ file: designFile?.[0]?.file }));
    }
  };

  const handleDelete = () => {
    dispatch(deleteDesignFileAction({ id: design.id }));
  };

  const deletePopup = () => {
    dispatch(setDeleteDesignIdAction({ id: design.id }));
    handleToggleMenuClose();
  };
  return (
    <>
      <Card sx={{ width: "100%" }} raised>
        <CardHeader
          action={
            <Box display="flex" justifyContent="center" alignContent="center">
              {!design.processed ? (
                <CircularProgress size={22} />
              ) : (
                <Tooltip title="Completed">
                  {getIconComponentByTypeWithProps("checkCircleOutlined")({
                    color: "secondary",
                  })}
                </Tooltip>
              )}
              <IconButton onClick={handleToggleMenuOpen} aria-label="settings">
                {getIconComponentByType("moreVert")}
              </IconButton>
            </Box>
          }
          title={design.name}
        />
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Box display="inline-flex" justifyContent="space-between">
              <Typography>Date</Typography>
              <Typography>
                {formatDate(design.created_at, "dd-mm-yyyy")}
              </Typography>
            </Box>
            <Box display="inline-flex" justifyContent="space-between">
              <Typography>EPSG</Typography>
              <Typography>{design.epsg}</Typography>
            </Box>
            {/* <Box display="inline-flex" justifyContent="space-between">
              <Typography>File</Typography>
              <Typography>{design.name}</Typography>
            </Box> */}
          </Box>
        </CardContent>
      </Card>
      <Menu
        id="design-card-crud-dialog"
        aria-labelledby="design-card-crud-dialog"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleToggleMenuClose}
      >
        <MenuItem
          onClick={saveDesignFile}
          sx={{
            minHeight: 32,
            height: 32,
          }}
        >
          <ListItemAvatar>
            <AvatarStyled>
              {getIconComponentByTypeWithProps("saveAlt")({
                fontSize: "small",
              })}
            </AvatarStyled>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant="subtitle2">Download</Typography>}
          />
        </MenuItem>
        <MenuItem
          onClick={deletePopup}
          sx={{
            minHeight: 32,
            height: 32,
          }}
        >
          <ListItemAvatar>
            <AvatarStyled>
              {getIconComponentByTypeWithProps("delete")({
                fontSize: "small",
              })}
            </AvatarStyled>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant="subtitle2">Delete</Typography>}
          />
        </MenuItem>
      </Menu>
      <DeleteDialog
        item={design}
        selectedId={deleteSelectedId}
        onClose={() => dispatch(setDeleteDesignIdAction({ id: null }))}
        handleDelete={handleDelete}
        loading={loading}
      />
    </>
  );
};

export default DesignItem;
