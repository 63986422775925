import _ from "lodash";
import mapboxgl from 'mapbox-gl';

// import WMSCapabilities from 'ol/format/WMSCapabilities';
import {addMeasurementClickEvent} from "../../redux/sagas/helpers/measurementHelper";
import {setPanoImage} from "redux/slices/Panorama/list";
import store from "redux/store";
import { setCesiumView, setPanoViewAction } from "redux/slices/mapbox/mapSlice";
import { log } from "@craco/craco/lib/logger";
import { getRasterLayerBase } from "utils/layers/rasterIntialLayers";
// const parser = new WMSCapabilities();
import icon from 'assets/images/360_red_1.png';
import selected_icon from 'assets/images/360_green.png';
import { createRasterLayerAction, resetCombinedLayers } from "redux/slices/layers/rasterLayersSlice";

const geolib = require('geolib');

export function getMapBoxResourceId(name, flightId, isLayer = false) {
    const type = isLayer ? "layer" : "source";
    return `${type}-${name}-${flightId}`.toLowerCase();
}


export function createRasterLayer(map, rasterLayer, sourceId, layerId, tileSize = 256, cogUrl) {
    if (cogUrl) {
        const url = `https://25p5jflglc.execute-api.ca-central-1.amazonaws.com/cog/tiles/{z}/{x}/{y}/WebMercatorQuad&url=${cogUrl}&bidx=1&bidx=2&bidx=4`;
        map.addSource(sourceId, {
            type: 'raster',
            tiles: [
                url
            ],

            tileSize: 256,
        });

        // map.addSource(sourceId, {
        //   'type': 'raster',
        //   "tiles": [`https://25p5jflglc.execute-api.ca-central-1.amazonaws.com/cog/tiles/{z}/{x}/{y}/?TileMatrixSetId=WebMercatorQuad&scale=1&url=${cogUrl}`],
        //   'tileSize': tileSize
        // })
    } else {
        map.addSource(sourceId, {
            'type': 'raster',
            "url": `mapbox://${rasterLayer['mapboxTileIdKey']}/?fresh=true`,
            'tileSize': tileSize
        })
    }

// map.addLayer({
//   id: 'custom-tiles',
//   type: 'raster',
//   source: sourceId,
// });
    map.addLayer({
        "id": layerId,
        "type": "raster",
        "source": sourceId,
        "layout": rasterLayer.layout,
        "paint": rasterLayer.paint,
        "minzoom": 0,
        "maxzoom": 22
    });
}

export function setRasterLayerToMap(map, rasterLayerInfo, flightId, visibility, cogUrl) {
    const sourceId = getMapBoxResourceId(rasterLayerInfo.name, flightId, false)
    const layerId = getMapBoxResourceId(rasterLayerInfo.name, flightId, true)
    const layer = map?.getLayer(layerId)
    if (layer) {
        map.setLayoutProperty(layerId, "visibility", visibility)
    } else {
        createRasterLayer(map, rasterLayerInfo, sourceId, layerId, 256, cogUrl)
    }

}

function createGeoJsonLayer(map, geoJsonLayerInfo, sourceId, layerId, subLayerKey) {
    const subLayer = geoJsonLayerInfo.subLayers[subLayerKey];
    if (!map.getSource(sourceId)) {
        map.addSource(sourceId, {
            type: 'geojson',
            data: subLayer.data,
        });
    } else {
        map.getSource(sourceId).setData(subLayer.data);
    }

  if (!map.getLayer(layerId)) {
    map.addLayer({
      'id': layerId,
      'source': sourceId,
      'type': subLayer.renderType,
      'paint': subLayer.paint,
      'layout': geoJsonLayerInfo.layout,
  });
  const style = map.getStyle();
  const lastLayerId = style.layers[style.layers.length - 1].id;
  map.moveLayer(layerId, lastLayerId);

  }
}

export function setGeoJsonLayerToMap(map, geoJsonLayerInfo, flightId, visibility) {
    Object.keys(geoJsonLayerInfo.subLayers).forEach(sublayerKey => {
        const sourceId = getMapBoxResourceId(`${geoJsonLayerInfo.name}-${sublayerKey}`, flightId, false)
        const layerId = getMapBoxResourceId(`${geoJsonLayerInfo.name}-${sublayerKey}`, flightId, true)
        let layer = map.getLayer(layerId)
        if (_.isEmpty(layer)) {
            createGeoJsonLayer(map, geoJsonLayerInfo, sourceId, layerId, sublayerKey)
        } else {
            const data = geoJsonLayerInfo.subLayers[sublayerKey].data
            const source = map.getSource(sourceId)
            source.setData(data)
        }
        map.setLayoutProperty(layerId, "visibility", visibility)
        if (geoJsonLayerInfo.name === "user-measurement") addMeasurementClickEvent(layerId, map)
    })
}

export function setGeoJsonData(map, layerId, flightId, data) {
    const sourceId = layerId.replace("layer", "source")
    const source = map.getSource(sourceId)
    if (source) {
        source.setData(data)
    }
}

export function updateSourceLayerGeoJsonData(map, sourceId, data) {
    const source = map.getSource(sourceId)
    if (source) {
        source.setData(data)
    }
}

export function setMapLayerLayoutProperty(layerId, property, value) {
    if (!window.map) return
    const layer = window.map.getLayer(layerId)
    if (layer) {
        window.map.setLayoutProperty(layerId, property, value)
        return true
    }
    return false
}

export function removeMapLayer(layerId) {
    if (!window.map) return
    const layer = window.map.getLayer(layerId)
    if (layer) window.map.removeLayer(layerId).removeSource(layerId.replace("layer", "source"))
}

export function getSelectedFlightLayers(layers, selectedFlightId) {
    return Object.entries(layers).filter(layer => layer[0].includes(selectedFlightId))
}


export function getMapMapLayers() {
    // if (!window?.map) return
    // const style = window.map.getStyle();
    // const layers = style.layers;
    // const layers2 = window.map.getLayers()
}

export const formatDate = (date) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}

export const ticketTypes = [
    {data_upload: 'Data Upload ( any issues related to project upload)'},
    {data_export: 'Data Export (any problem regarding to data export)'},
    {measurement: 'Measurement (any problem related to measurements)'},
    {data_visibility: 'Data Visibility Problem (ortho, cut fill, design cut fill and hillshade)'},
    {other: 'Other (anything else)'}
]


export const checkCutandFill = (value, unit, label) => {
    if (!value) return false;
    const unitString = unit && checkUnit(label) !== '0' ? unit + formatSuperscript(checkUnit(label)) : unit;
    // const unitString = unit ? unit + formatSuperscript(checkUnit(label)) : '';
    if (label === 'design_elevation') {
        return Math.abs(value.toFixed(2)) + ' ' + unitString;
    }
    if ((label === 'stockpile_volume_TIN_METHOD' || label === 'stockpile_volume_MIN_METHOD') && unit === 'ft') {
        const cutAndFill = value > 0 ? '(Fill)' : '(Cut)';
        return (
            <>
                {Math.abs(value.toFixed(2))} yd<sup>3</sup> {cutAndFill}
            </>
        );
    }

    return cutFillLabel(value, label, unitString);
}

const cutFillLabel = (value, label, unitString) => {
    if (value > 0 && label !== 'design_elevation') {
        return value.toFixed(2) + ' ' + unitString + ' (Fill)';
    } else {
        return Math.abs(value.toFixed(2)) + ' ' + unitString + ' (Cut)';
    }
}

const formatSuperscript = (num) => {
    const superscriptDigits = {'2': '²', '3': '³'};
    const res = num.toString().split('').map(digit => superscriptDigits[digit] || digit).join('');
    if (res) return res;
    else
        return '';
};

export const measurement_units = {
    0: ['X', 'Y', 'elevation', 'progress_cut_fill', 'design_cut_fill', 'perimeter', 'length', 'design_elevation',
        'diff_in_elevation'],
    2: ['area'],
    3: ['progress_volume', 'stockpile_volume_TIN_METHOD', 'stockpile_volume_MIN_METHOD', 'design_volume'],
}

export const checkUnit = (value) => {
    for (const key in measurement_units) {
        if (measurement_units[key].includes(value)) {
            return key === 0 ? 'm' : key;
        }
    }
    return '';
}

export const cut = (text, maxSize = 22, isTreeDots = true) => {
    if (text && text.length > maxSize) {
        const newText = text.slice(0, maxSize - 3);
        return isTreeDots ? `${newText}...` : newText;
    }
    return text;
};


export const getCenterOfLocations = (locations) => {
    const latitudes = locations.map(location => location[1]);
    const longitudes = locations.map(location => location[0]);
    const center = geolib.getCenterOfBounds(locations);

    return {
        latitude: center.latitude,
        longitude: center.longitude
    };
}

const modifyGeoserverUrl = (serverUrl) => {
    let url = serverUrl?.trim();
    if (url && url.match(/((http|https):\/\/)(www.){0,1}[a-zA-Z0-9@:%._/+~#?&/=]{2,256}(\.[a-z]{2,6}\b){0,1}([-a-zA-Z0-9@:%._\\+~#?&/=]*)/)) {
        const param = 'request=GetCapabilities';
        if (!url?.includes(param)) {
            if (url?.includes('?')) {
                url = `${url}&${param}`;
            } else {
                url = `${url}?${param}`;
            }
        }
    }
    return url;
};

const getLayersAndStyles = (xmlDoc) => {
    const layers = [];
    const layerNodes = xmlDoc.getElementsByTagName('Layer');
    for (let i = 0; i < layerNodes.length; i++) {
        const layerName = layerNodes[i].getElementsByTagName('Name')[0].textContent;
        layers.push(layerName);
    }
    return layers;
};

// const getLayersAndStyles = (xml) => {
//     const json = parser.read(xml);
//     const allLayers = json?.jsonCapability?.Layer?.Layer || [];
//     const allWMSLayers = [];
//     allLayers.forEach(({ Name, Title, BoundingBox, Style }) => {
//         let extent = [];
//         const styles = [];
//         (Style || []).forEach(({ Name, Title }) => {
//             if (Name && Title) {
//                 styles.push({ value: Name, label: Title });
//             }
//         });
//         BoundingBox.forEach((box: Object) => {
//             const { extent: boxExtent, crs } = box || {};
//             if (crs === 'CRS:84') {
//                 extent = transformExtent(boxExtent, 'EPSG:4326', 'EPSG:3857');
//             }
//         });
//         allWMSLayers.push({ value: Name, label: Title, extent, styles });
//     });
//     return { allWMSLayers: allWMSLayers.filter(lyr => lyr.styles.length) };
// };

export const getGeoserverInfo = (url, callback) => {
    try {
        const updatedUrl = modifyGeoserverUrl(url);
        if (updatedUrl) {
            const x = new XMLHttpRequest();
            x.open('GET', updatedUrl, true);
            x.onreadystatechange = () => {
                if (x.readyState === 4 && x.status === 200) {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(x.responseText, 'text/xml');
                    const data = getLayersAndStyles(xmlDoc);
                    // const data = getLayersAndStyles(x.responseXML);
                    return callback(data);
                    return callback(data);
                } else if (x.readyState === 4) {
                    return callback({error: `Error: ${x.status} - ${x.statusText}`});
                }
            };
            x.send(null);
        }
    } catch (error) {
        return callback({msg: 'Please enter a valid URL.', error});
    }
};

export function addPanoPointsToMap(panoImages, map) {
    const panoSourceId = 'pano-points';
    const panoLayerId = 'pano-points-layer';
    const panoIconId = 'pano-icon';
    const selectedIconId = 'pano-icon-selected';

    if (!map?.hasImage(panoIconId)) {
        map?.loadImage(icon, (error, image) => {
            if (error) throw error;
            if (!map.hasImage(panoIconId)) {
                // map.addImage(panoIconId, image, {sdf: true});
                map.addImage(panoIconId, image);
            }
        });
        map?.loadImage(selected_icon, (error, image) => {
            if (error) throw error;
            if (!map?.hasImage(selectedIconId)) {
                map?.addImage(selectedIconId, image);
            }
        });
    }


    if (!map?.getSource(panoSourceId)) {
        map?.addSource(panoSourceId, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

        map?.addLayer({
            id: panoLayerId,
            type: 'symbol',
            source: panoSourceId,
            layout: {
                'icon-image': panoIconId,
                'icon-size': 0.1,
                'icon-allow-overlap': true,
            },
        });
    }

    const existingFeatures = map?.getSource(panoSourceId)._data.features;

    panoImages && panoImages.length && panoImages.forEach(pano => {
        const coordinates = pano.location.coordinates;
        const id = pano.id;

        const exists = existingFeatures?.some(
            feature => feature.properties.id === id
        );

        if (!exists) {
            const newFeature = {
                type: 'Feature',
                properties: {
                    id: id,
                    name: pano.name,
                    file: pano.file,
                    tiles_dir_path: pano?.tiles_dir_path
                },
                geometry: {
                    type: 'Point',
                    coordinates: coordinates
                }
            };

            existingFeatures?.push(newFeature);
        }
    });

    map?.getSource(panoSourceId).setData({
        type: 'FeatureCollection',
        features: existingFeatures
    });

    const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
    });

    map?.on('mouseenter', panoLayerId, (e) => {
        map.getCanvas().style.cursor = 'pointer';

        const coordinates = e.features[0].geometry.coordinates.slice();
        const name = e.features[0].properties.name;

        popup.setLngLat(coordinates)
            .setHTML(`<strong>Click on Icon to View Pano</strong>`)
            .addTo(map);
    });

    map?.on('mouseleave', panoLayerId, () => {
        map.getCanvas().style.cursor = '';
        popup.remove();
    });

    map?.on('click', panoLayerId, (e) => {
        if (e.features.length > 0) {
            const clickedId = e.features[0].properties.id;
            const clickedFile = e.features[0].properties.file;
            const tiles_dir_path = e.features[0].properties?.tiles_dir_path;

            store.dispatch(setPanoImage({id: clickedId, file: clickedFile, tiles_dir_path}));
            store.dispatch(setPanoViewAction(true));

            map?.setLayoutProperty(panoLayerId, 'icon-image', [
                'case',
                ['==', ['get', 'id'], clickedId],
                selectedIconId,
                panoIconId
            ]);
        }
    });

}


export const moveMeasurementLayersToTop = (layerName) => {
  const map = window.map;
  if (!map || !map.getStyle() || !map.getStyle?.()?.layers) return;
  map.getStyle().layers.forEach(layer => {
    if (layer.id.includes(layerName)) {
      map.setLayoutProperty(layer.id, 'visibility', 'visible');
      map.moveLayer(layer.id); 
    }
  });
};

export const hideMeasurementLayers = () => {
  const map = window.map;
  if (!map || !map.getStyle() || !map.getStyle?.()?.layers) return;
  try {
    map?.getStyle()?.layers?.forEach(layer => {
      if (layer.id.includes('measure') || layer.id.includes('polygon') || layer.name?.includes('polygon')) {
        map.setLayoutProperty(layer.id, 'visibility', 'none');
      }
    });
  } catch(e) {
  
  }
  
};


export const removeMeasurementLayers = () => {
  const map = window.map;
  if (map) {
    map?.getStyle()?.layers?.forEach(layer => {
      if (layer.id.includes('measurement') ) {
        map?.removeLayer(layer.id);
      }
    });
 const sources = map.getStyle()?.sources;
  if (sources) {
    Object.keys(sources).forEach(source => {
      if ( source.includes('measurement')) {
        map.removeSource(source);
      }
    });
  }

  map?.getStyle()?.layers?.forEach(layer => {
      if (layer.id.includes('tracking') || layer.id.includes('polygons') ) {
        map.setLayoutProperty(layer.id, 'visibility', 'none');
      }
    });
  }
}

export const drawWMSLayers = () => {
    const userEmail = localStorage.getItem("userEmail");
      
  try {
    const bbox = [-2336824.9573, -724284.1658000015, 2842801.139399998, 3827745.487400003];
     const wmsUrl1 = 'https://proxyinternet.nrcan.gc.ca/arcgis/services/CLSS-SATC/CLSS_Administrative_Boundaries/MapServer/WMSServer?service=WMS&version=1.1.1&request=GetMap&layers=0&styles=&format=image/png&srs=EPSG:3857&bbox={bbox-epsg-3857}&width=256&height=256&transparent=true';
    const wmsUrl2 = 'https://proxyinternet.nrcan-rncan.gc.ca/arcgis/services/CLSS-SATC/CLSS_Administrative_Boundaries/MapServer/WMSServer?service=WMS&version=1.1.0&request=GetMap&layers=1&styles=&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&format=image/png&transparent=true';
    const parcelFabric = 'https://openmaps.gov.bc.ca/geo/pub/WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW/ows?service=WMS&version=1.1.0&request=GetMap&layers=pub:WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW&styles=&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&format=image/png&transparent=true';
    const storm_lines = 'https://services5.arcgis.com/YdvAKihFVMKJfTGm/arcgis/rest/services/Storm_lines_view/FeatureServer/23/query?where=1%3D1&outFields=*&outSR=3857&f=geojson';
    
  if (userEmail === 'lce@hillplain.com' && (!window?.map?.getSource('parcel-fabric-wms-source') ||
      !window?.map?.getSource('admin-boundaries-wms-source') || !window?.map?.getSource('storm-lines-wms') 
      )
 
    ) { //lce

      window.map.addSource('parcel-fabric-wms-source', {
        type: 'raster',
        tiles: [
          `${parcelFabric}`
        ],
        tileSize: 256,
      });

      window.map.addLayer({
        id: 'parcel-fabric-wms-layer',
        type: 'raster',
        source: 'parcel-fabric-wms-source',
        paint: { },
      });

    window.map.addSource('admin-boundaries-wms-source', {
        type: 'raster',
        tiles: [
          `${wmsUrl2}`
        ],
        tileSize: 256,
      });

      window.map.addLayer({
        id: 'admin-boundaries-wms-layer',
        type: 'raster',
        source: 'admin-boundaries-wms-source',
        paint: { },
      });

       const geojsonUrl = 'https://services5.arcgis.com/YdvAKihFVMKJfTGm/arcgis/rest/services/Storm_lines_view/FeatureServer/23/query?where=1%3D1&outFields=*&outSR=3857&f=geojson';

      fetch(geojsonUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
        
          data.features.forEach(feature => {
            feature.geometry.coordinates = feature.geometry.coordinates.map(coord => {
              return proj4('EPSG:3857', 'EPSG:4326', coord);
            });
          });
          window.map.addSource('storm-lines-wms', {
            type: 'geojson',
            data: data
          });

          window.map.addLayer({
            id: 'storm-lines-wms',
            type: 'line',
            source: 'storm-lines-wms',
            layout: {},
            paint: {
              'line-color': '#149ECE',
              'line-width': 5
            }
          });
          const coordinates = data.features.flatMap(f => f.geometry.coordinates);
          const bounds = coordinates.reduce((bounds, coord) => bounds.extend(coord), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
          // window.map.fitBounds(bounds, { padding: 20 });
        })

        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
        });

    
    }

  } catch (error) {
    console.error("Error adding WMS layer:", error);
  }

};

export const checkMapLayersSource = (rasterLayers, panoImages) => {
    const userEmail = localStorage.getItem("userEmail");
    if (!rasterLayers?.length) return;

    try {
        let hasDispatched = false;
        for (const rasterLayer of rasterLayers) {
            const rasterLayerData = getRasterLayerBase(rasterLayer.type);
            const layerId = rasterLayerData?.name && getMapBoxResourceId(rasterLayerData.name, rasterLayer.flight, true);
            if (window.map.getLayer(layerId) && window.map.isSourceLoaded(layerId)) continue;
            const onSourceData = (e) => {
                const layerExists = map.getLayer(layerId);
                const sourceLoaded = e.isSourceLoaded;

                if (layerExists && sourceLoaded) {
                    if (!hasDispatched) {
                        hasDispatched = true;  
                        addPanoPointsToMap(panoImages, window.map);
                        store?.dispatch(createRasterLayerAction({ data: { flight_resources: rasterLayers } }));
                        window.map.off('sourcedata', onSourceData);
                    }
                }
            };

            window.map?.on('sourcedata', onSourceData);
        }

        if (userEmail === 'lce@hillplain.com' && 
            (!window.map.getSource('parcel-fabric-wms-source') || 
             !window.map.getSource('admin-boundaries-wms-source') || 
             !window.map.getSource('storm_lines'))) {
            drawWMSLayers();
        }

    } catch (e) {
        console.error("Error in checkMapLayersSource:", e);
    }
};
