import PrivateRequestBase from "./rootRequest/PrivateRequestBase";

export function createSmartTrackingPolygonRequest(payload){
  return PrivateRequestBase("post", "api/polygon/", payload)
}

export function getSmartTrackingPolygonListRequest(flightId){
  return PrivateRequestBase("get", `api/polygon/?flight=${flightId}`)
}

export function deleteSmartTrackingPolygonRequest(id){
  return PrivateRequestBase("delete", `api/polygon/${id}/`)
}

export function editSmartTrackingPolygonRequest(id, payload) {
  return PrivateRequestBase("put", `api/polygon/${id}/`, payload)
}