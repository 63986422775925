const DATE_FORMATS = {
  'yyyy-mm-dd': (date) => {
    return new Date(date).toLocaleDateString().split('/').reverse().join('-');
  },
  'dd-mm-yyyy': (date) => {
    return new Date(date).toLocaleDateString().split('/').join('-');
  }
};

export const formatDate = (date, format) => {
  return DATE_FORMATS[format](date);
};

export const getStringDateFromDate = (date) => {
  return date.toDateString()
}
