import {createSlice} from "@reduxjs/toolkit";

const measurementProfilerSlice = createSlice({
    name: "measurementProfilerSlice",
    initialState: {
        loading: false,
        data: [],
        latLon:[],
        chartData: [],
        isProfileExpanded: false
    },
    reducers: {
        createMeasurementProfileAction(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        formatMeasurementProfileDataAction(){

        },
        setMeasurementProfileDataAction(state, action){
            return{
                loading:false,
                latLon:action.payload.latLon,
                chartData: action.payload.data
            }
        },
        removeMeasureProfileSelectAction(state, action){
            return{
                loading:false,
                latLon: null,
                chartData: null
            }
        },
        enableProfileAction(state, action){
            return {
                isProfileExpanded:true,
            }
        },
        disableProfileAction(state, action){
            return{
                isProfileExpanded:true,
            }
        },
        
    }
})

export const {createMeasurementProfileAction,
    formatMeasurementProfileDataAction,
    setMeasurementProfileDataAction, 
    removeMeasureProfileSelectAction,
    enableProfileAction,
    disableProfileAction
} = measurementProfilerSlice.actions

export default measurementProfilerSlice.reducer