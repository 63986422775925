import {createSlice} from "@reduxjs/toolkit";

const exportSlice = createSlice({
  name: "exportSlice",
  initialState: {},
  reducers: {
    saveOrthoExportAction(){},
    saveTerrainExportAction(){},
    saveMeasurementExportAction(){},
    saveDSMExportAction(){},
    saveExportSmartTrackingAction(){},
    saveExportExcelAction(){},

  }
})

export const {saveTerrainExportAction, saveMeasurementExportAction, saveOrthoExportAction, saveDSMExportAction, saveExportExcelAction, saveExportSmartTrackingAction} = exportSlice.actions

export default exportSlice.reducer