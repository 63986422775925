import {createSlice} from "@reduxjs/toolkit";

const projectMaterialCreateSlice = createSlice({
  name: "projectMaterialCreate",
  initialState: {
    loading: false,
  },
  reducers: {
    createProjectMaterialAction(){
      return{
        loading: true
      }
    },
    createProjectMaterialSuccessAction(){
      return {
        loading: false
      }
    }
  }
})

export const {createProjectMaterialAction, createProjectMaterialSuccessAction} = projectMaterialCreateSlice.actions

export default projectMaterialCreateSlice.reducer