import axios from "axios";

const PrivateAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

PrivateAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem("token")
  if (token) {
    config.headers.Authorization = `Token ${token}`
  }
  return config
},
  (error => Promise.reject(error)))

PrivateAPI.interceptors.response.use(response => response, error => {
  if (error?.response?.status === 401) {
      localStorage.clear()
      window.location.href = "/"
  } else {
    return Promise.reject(error)
  }
})

export default PrivateAPI