import PublicRequestBase from "../rootRequest/PublicRequestBase";

export function resetPasswordRequest(payload) {
  return PublicRequestBase("post", "api/auth/reset_password_email/", payload);
}

export function resetPasswordConfirmRequest(payload) {
  return PublicRequestBase("post", "api/auth/reset_password/", payload);
}

export function authorizeLinkRequest(payload) {
  return PublicRequestBase("post", "api/auth/authorize_reset_link/", payload);
}
