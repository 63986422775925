import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MeasureButtons from "../../components/Measurement/Buttons";
import MeasurementList from "../../components/Measurement/List";


const MeasurementPage = () => {
  return (
    <>
    <Box alignItems="center" display="flex" flexDirection="column" pt={2} pb={2}>
        <Typography variant="h4">Measure</Typography>
        <Box pt={2} width="100%">
          <MeasureButtons/>
        </Box>
      </Box>
      <Divider/>
      <MeasurementList/>
    </>
  )
}

export default MeasurementPage