import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "../Common/TextField";
import { useForm } from "../../hooks/useForm";
import Select from "../Common/Select";
import Typography from "@mui/material/Typography";
import "./materialCreate.css";
import SubmitResetButton from "../Common/SubmitResetButton";
import { useDispatch, useSelector } from "react-redux";
import { createProjectMaterialAction } from "../../redux/slices/material/projectMaterial/create";
import { useHistory } from "react-router-dom";
import { editProjectMaterialAction } from "../../redux/slices/material/projectMaterial/edit";

const presetColors = [
  "#A430FF",
  "#3C704C",
  "#EC4E50",
  "#FEB300",
  "#F30C7D",
  "#FE0F00",
  "#029B89",
  "#0A1D37",
  "#0081C8",
  "#BC4E82",
  "#00FF19",
  "#F44B12",
  "#482222",
  "#09DCEA",
  "#1C1AB0",
  "#00930F",
  "#FF0300",
  "#6A4601",
  "#1D7100",
  "#0B0035",
  "#003213",
  "#FAC900",
  "#3B289F",
  "#181818",
  "#AEA70A",
  "#FF0080",
  "#AE0A13",
  "#340067",
];

const initialValue = {
  name: "",
  material: "",
  color: "#A430FF",
};
const MaterialCreateOrEdit = ({ projectMaterial }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const commonMaterial = useSelector((state) => state.commonMaterial);
  const createLoading = useSelector(
    (state) => state.projectMaterialCreate.loading
  );
  const editLoading = useSelector((state) => state.projectMaterialEdit.loading);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm, setValues } =
    useForm(projectMaterial ? projectMaterial : initialValue, validate, true);

  const handleColorChange = (color) => {
    setValues({
      ...values,
      color: color,
    });
  };

  const handleSubmit = () => {
    if (validate()) {
      if (projectMaterial) {
        dispatch(editProjectMaterialAction({ ...values, history: history }));
      } else {
        dispatch(createProjectMaterialAction({ ...values, history: history }));
      }
    }
  };

  return (
    <Box pt={2} m={1}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <TextField
            label="Material Name"
            fullWidth
            required
            name="name"
            value={values.name}
            error={errors.name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            {" "}
            <Typography>Select Material Color</Typography>
          </Grid>
          <Grid item>
            <div className="picker__swatches">
              {presetColors.map((presetColor) => (
                <button
                  key={presetColor}
                  className={
                    presetColor === values.color
                      ? "picker__swatch picker__swatch__selected"
                      : "picker__swatch"
                  }
                  style={{ background: presetColor }}
                  onClick={() => handleColorChange(presetColor)}
                />
              ))}
            </div>
          </Grid>
        </Grid>
        <Grid item>
          <SubmitResetButton
            handleSubmit={handleSubmit}
            resetForm={resetForm}
            loading={projectMaterial ? editLoading : createLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MaterialCreateOrEdit;
