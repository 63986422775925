import React from 'react';
import {Redirect, Route} from 'react-router-dom';

const PublicRoute = ({component: Component, ...rest}) => (
  <Route {...rest} component={(props) => (
      (window.location.pathname.includes("confirm-email") ? (
          <Component {...props} />
      ) : (localStorage.getItem("isAuthenticated") === "true" ? (
      <Redirect to="/project"/>
    ) : (
      <Component {...props} />
    )))
  )}/>
)

export default PublicRoute
