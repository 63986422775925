import PrivateRequestBase from "./rootRequest/PrivateRequestBase";

export function projectCreateRequest(payload){
  return PrivateRequestBase("post", "api/project/", payload)
}

export function getProjectListRequest(){
  return PrivateRequestBase("get", "api/project/?order_by=-created_at")
}

export function deleteProjectRequest(id){
  return PrivateRequestBase("delete", `api/project/${id}/`)
}

export function editProjectRequest(id, payload) {
  return PrivateRequestBase("put", `api/project/${id}/`, payload)
}