import React, { useEffect, useRef, useState } from 'react';
import { Viewer, Cesium3DTileset, Color, SceneMode, HeadingPitchRange, Math } from 'cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch"; 
import CircularProgress from '@mui/material/CircularProgress';
import { setCesiumView } from 'redux/slices/mapbox/mapSlice';
import { selectFlightAction } from 'redux/slices/flight/selected';
import Loader from "components/Common/Loader";

const ToolbarStyled = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "4px", 
  right: "10px", 
  zIndex: 1000, 
  backgroundColor: theme.palette.primary.dark, 
  padding: theme.spacing(0.5), 
  borderRadius: "8px", 
}));

const LoadingOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999
});

const ModeLabel = styled('span')(({ theme, isChecked }) => ({
  fontWeight: 'bold',
  color: theme.palette.secondary, 
  marginRight: isChecked ? '0' : '8px', 
  marginLeft: isChecked ? '8px' : '0',
}));

const CesiumMap = () => {
  const viewerRef = useRef(null);
  const dispatch = useDispatch();
  const flights = useSelector((state) => state.flightList.data);
  const selectedFlight = useSelector((state) => state.flightSelected);
  const flightUuId = flights?.find((fid) => fid.id === selectedFlight.id)?.uuid;
  const tilesApiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/3d_tileset_file/${flightUuId}/tileset.json`;
  const is3D = useSelector((state) => state.mapState.is3D);
  const [cesium, setCesium] = useState(is3D);
  const [loading, setLoading] = useState(true); 

  const switchtoCesium = () => {
    setCesium((prevCesium) => {
      const updatedCesium = !prevCesium;
      dispatch(setCesiumView(updatedCesium));
      if(!updatedCesium) {
        dispatch(selectFlightAction({ flight : selectedFlight }));
      }
      return updatedCesium;
    });
  };

  useEffect(() => {
    if (flightUuId) {
      const viewer = new Viewer(viewerRef.current, {
        animation: false,
        timeline: false,
        baseLayerPicker: false, 
        geocoder: false, 
        homeButton: false, 
        sceneModePicker: false,
        navigationHelpButton: false, 
        infoBox: false,
        selectionIndicator: false, 
        fullscreenButton: false,
        skyBox: false, 
        skyAtmosphere: false,
        sceneMode: SceneMode.SCENE3D, 
        backgroundColor: Color.BLACK 
      });

      viewer.scene.globe.show = false;
      const tileset = new Cesium3DTileset({
        url: tilesApiUrl,
      });

      viewer.scene.primitives.add(tileset);
      tileset.readyPromise
        .then(() => {
          const boundingSphere = tileset.boundingSphere;
          viewer.camera.flyToBoundingSphere(boundingSphere, {
            duration: 2,
            offset: new HeadingPitchRange(0, Math.toRadians(-300), boundingSphere.radius * 0)
          });
          setLoading(false); 
        })
        .catch((error) => {
          console.error('Failed to load tileset:', error);
          setLoading(false); 
        });

      return () => {
        if (viewer && !viewer.isDestroyed()) {
          viewer.destroy();
        }
      };
    }
  }, [flightUuId]);

  return (
    <>
      <div
        ref={viewerRef}
        style={{ width: '100%', height: '100vh' }}
      />
      
      {loading && (
        <Loader />
      )}

      <ToolbarStyled>
        <ModeLabel isChecked={false}>2D</ModeLabel>
          <Switch
            checked={is3D}
            onChange={switchtoCesium}
            color="primary"
            disabled={!is3D}
          />
        <ModeLabel isChecked={false}>3D</ModeLabel>
      </ToolbarStyled>
    </>
  );
};

export default CesiumMap;
