import {createSlice} from "@reduxjs/toolkit";

const appbarSlice = createSlice({
  name: "appbar",
  initialState: {
    openDrawer: false
  },
  reducers:{
    setOpenDrawerAction(state, action) {
      return {
        openDrawer: action.payload.openDrawer
      }
    }
  }
})

export const {setOpenDrawerAction} = appbarSlice.actions

export default appbarSlice.reducer