import {createSlice} from "@reduxjs/toolkit";

const smartTrackingDeleteSlice = createSlice({
  name: "smartTrackingDelete",
  initialState: {
    loading: false,
    selectedId: null
  },
  reducers: {
    setSmartTrackingPolygonDeleteIdAction(state, action) {
      return {
        ...state,
        selectedId: action.payload.id
      }
    },
    deleteSmartTrackingPolygonAction(state, action) {
      return {
        ...state,
        loading: true
      }
    },
    deleteSmartTrackingPolygonSuccessAction() {
      return {
        loading: false,
        selectedId: null
      }
    }

  }
})

export const {
  setSmartTrackingPolygonDeleteIdAction,
  deleteSmartTrackingPolygonAction,
  deleteSmartTrackingPolygonSuccessAction
} = smartTrackingDeleteSlice.actions

export default smartTrackingDeleteSlice.reducer