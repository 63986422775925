import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { getIconComponentByType, getIconComponentByTypeWithProps } from "../../utils/assets";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Slider from "@mui/material/Slider";
import { getMapBoxResourceId, moveMeasurementLayersToTop } from "../../utils/layers/mapUtils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import scale from "../../images/scale.png";
import {
  updateRasterLayerTransparencyAction,
  updateRasterLayerVisibilityAction
} from "../../redux/slices/layers/rasterLayersSlice";
import { log } from "@craco/craco/lib/logger";

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  height: "100%",
  width: "100%"
}));

const CardHeaderStyled = styled(CardHeader)(({ theme }) => ({
  "& .MuiCardHeader-avatar": {
    marginRight: "5px"
  }
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  padding: "0 16px",
  "&:last-child": {
    paddingBottom: 0
  }
}));

const SeeItem = ({ layerData, layerKey, provided, onToggleWmsLayer }) => {
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(layerData.type === 'wmsLayer' ? 'visible' : layerData?.layout?.visibility || "visible");
  const [defaultValue, setDefaultValue] = useState((layerData?.paint?.["raster-opacity"] || 1) * 100);
  const selectFlightId = useSelector(state => state.flightSelected?.id);

  useEffect(() => {    
  if(layerData?.type === 'wmsLayer') {
    moveMeasurementLayersToTop('wms');
  }
  },[]);





  const toggleLayer = () => {
    if (!window.map) return;

    let newVisibility = visibility === "visible" ? "none" : "visible";
    if (layerData?.type === "panoramaGroup") {
      setVisibility(newVisibility);
      window.map.setLayoutProperty('pano-points-layer', "visibility", newVisibility);
    }
    if (layerData?.type === "wmsLayer") {
      const wmsLayerIds = ["parcel-fabric-wms-layer", "admin-boundaries-wms-layer"];
      wmsLayerIds.forEach(layerId => {
        if (window.map.getLayer(layerId)) {
          window.map.setLayoutProperty(layerId, "visibility", newVisibility);
        }
      });
    } else if (layerData?.type === "linework") {
      const baseId = "linework";
      const pointLayerId = `${baseId}-point-${layerData?.uuid}`;
      const lineLayerId = `${baseId}-line-${layerData?.uuid}`;
      const polygonLayerId = `${baseId}-polygon-${layerData?.uuid}`;

      window.map.setLayoutProperty(lineLayerId, "visibility", newVisibility);
      window.map.setLayoutProperty(pointLayerId, "visibility", newVisibility);
      window.map.setLayoutProperty(polygonLayerId, "visibility", newVisibility);
    } else if (layerData?.type === "rasterLayer") {
      const name = getMapBoxResourceId(layerData?.name, selectFlightId, true);
      window.map.setLayoutProperty(name, "visibility", newVisibility);
      dispatch(updateRasterLayerVisibilityAction({ key: layerKey, visibility: newVisibility }));
    }

    setVisibility(newVisibility);
  };

  const setTransparency = (e, newValue) => {
    if (!window.map) return;

    const newOpacityValue = newValue / 100;
    if (layerData?.type === "rasterLayer") {
      const name = getMapBoxResourceId(layerData?.name, selectFlightId, true);
      window.map.setPaintProperty(name, "raster-opacity", newOpacityValue);
    } else if (layerData?.type === "linework") {
      const { uuid } = layerData;
      const baseId = "linework";
      const pointLayerId = `${baseId}-point-${uuid}`;
      const lineLayerId = `${baseId}-line-${uuid}`;
      const polygonLayerId = `${baseId}-polygon-${uuid}`;

      window.map.setPaintProperty(pointLayerId, "circle-opacity", newOpacityValue);
      window.map.setPaintProperty(lineLayerId, "line-opacity", newOpacityValue);
      window.map.setPaintProperty(polygonLayerId, "fill-opacity", newOpacityValue);
    } else if (layerData?.type === "wmsLayer") {
      const wmsLayerIds = ["parcel-fabric-wms-layer", "admin-boundaries-wms-layer"];
      wmsLayerIds.forEach(layerId => {
        if (window.map.getLayer(layerId)) {
          window.map.setPaintProperty(layerId, "raster-opacity", newOpacityValue);
    }
      });
    }
    setDefaultValue(newValue);
  };

  const commitChanges = (e, newValue) => {
    const newOpacityValue = newValue / 100;

    if (!window.map) return;

    if (layerData?.type === "rasterLayer") {
      const name = getMapBoxResourceId(layerData?.name, selectFlightId, true);
      window.map.setPaintProperty(name, "raster-opacity", newOpacityValue);
      dispatch(updateRasterLayerTransparencyAction({ key: layerKey, opacity: newOpacityValue }));
    } else if (layerData?.type === "linework") {
      const { uuid } = layerData;
      const baseId = "linework";
      const pointLayerId = `${baseId}-point-${uuid}`;
      const lineLayerId = `${baseId}-line-${uuid}`;
      const polygonLayerId = `${baseId}-polygon-${uuid}`;

      window.map.setPaintProperty(pointLayerId, "circle-opacity", newOpacityValue);
      window.map.setPaintProperty(lineLayerId, "line-opacity", newOpacityValue);
      window.map.setPaintProperty(polygonLayerId, "fill-opacity", newOpacityValue);
    } else if (layerData?.type === "wmsLayer") {
      const wmsLayerIds = ["parcel-fabric-wms-layer", "admin-boundaries-wms-layer"];
        wmsLayerIds.forEach(layerId => {
            if (window.map.getLayer(layerId)) {
          window.map.setPaintProperty(layerId, "raster-opacity", newOpacityValue);
            }
        });
    }
  };

  const imgProp = layerData?.type === "rasterLayer" ? "imageIcon" : (layerData?.type === "linework" ? "polyLine" : "cloudUpload");
  const IconComponent = getIconComponentByTypeWithProps(imgProp);
  const CardImageComponent = IconComponent && <IconComponent color="disabled" fontSize="small" />;

  return (
    <Card raised>
      <CardHeaderStyled
        sx={{
          padding: "3px 5px 3px 16px",
        }}
        avatar={
          <AvatarStyled sx={{ bgcolor: "#fff", color: "#000" }} {...provided.dragHandleProps}>
            {getIconComponentByType("dragIndicator")}
          </AvatarStyled>
        }
        action={
          <IconButton onClick={toggleLayer}>
            {visibility === "visible" ? getIconComponentByTypeWithProps("eyeOpen")({
              color: "primary"
            }) : getIconComponentByType("eyeClose")}
          </IconButton>
        }
        title={<Typography>{layerData?.title || layerData?.name}</Typography>}
      />
      {layerData && (
        <CardContentStyled>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            {CardImageComponent}
            <Slider
              size="small"
              aria-labelledby={`continuous-slider-${layerKey || ""}`}
              onChange={setTransparency}
              onChangeCommitted={commitChanges}
              value={defaultValue}
              valueLabelDisplay={"auto"}
            />
            {IconComponent && <IconComponent fontSize="small" />}
          </Stack>

          {(layerData?.name === "user-design-heatmap" || layerData?.name === "user-heatmap") && (
            <Box pb={1} display="flex" flexDirection="column">
              <Box display="inline-flex" justifyContent="space-between" paddingLeft="auto" paddingRight="auto" pb={1}>
                <Typography sx={{ paddingLeft: "24px" }}>Cut</Typography>
                <Typography sx={{ paddingRight: "24px" }}>Fill</Typography>
              </Box>
              <img src={scale} style={{ height: "100%", width: "100%" }} />
            </Box>
          )}
        </CardContentStyled>
      )}
    </Card>
  );
};

export default SeeItem;
