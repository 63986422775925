import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";

import { useDispatch } from "react-redux";
import { resetResetPasswordAction } from "../../../redux/slices/basic/resetPasswordSlice";
import Link from "@mui/material/Link";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";

const GridStyled = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  justifyContent: "center",
}));

const TypoStyle = styled(Typography)(({ theme }) => ({
  lineHeight: 1.4,
  fontSize: 16,
  padding: "5px 10px",
}));
const ButtonStyled = styled(Button)(({ theme }) => ({
  //height: 34,
  borderRadius: "12px",
  padding: 10,
  boxShadow:
    "0px 4px 7px -1px rgba(0, 0, 0, 0.11), 0px 2px 4px -1px rgba(0, 0, 0, 0.07)",
}));
const SetPasswordMessage = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    return () => {
      dispatch(resetResetPasswordAction());
    };
  }, [location]);

  const handleClick = () => {
    dispatch(resetResetPasswordAction());
    history.push("/");
  };

  return (
    <section>
      <Box pt={2} pb={2}>
        <Box mb={3} textAlign="center">
          <TypoStyle variant="h3" component="h2" gutterBottom>
            Congratulation's
          </TypoStyle>
          <TypoStyle variant="subtitle1" component="h2" gutterBottom>
            Password has been reset with the new password
          </TypoStyle>

          <Divider />

          <GridStyled container mb={2}>
            <Grid>
              <ButtonStyled
                onClick={handleClick}
                variant="contained"
                component={Link}
              >
                Go back to Sign In Page
              </ButtonStyled>
            </Grid>
          </GridStyled>
        </Box>
      </Box>
    </section>
  );
};

export default SetPasswordMessage;
