import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {drawGeometryStyle} from "./grawGeometryStyle";
import autoBind from "auto-bind";
import _ from "lodash";
import {removeMeasurementClickEvent} from "../../../redux/sagas/helpers/measurementHelper";


class DrawTool {
  callback = null;

  constructor() {
    autoBind(this);
    let interval = null
    if (_.isEmpty(window.mapboxDraw)) {
      interval = window.setInterval(() => {
        if (!_.isEmpty(window.map)) {
          this.getMapboxDraw().then(mapboxDraw => {
            window.clearInterval(interval);
          });
        }
      }, 2000);
    }
    window.clearInterval(interval)
  }

  async createNewMapboxDraw() {
    await this.getMapboxDraw(true)
  }

  async getMapboxDraw(forceNew = false) {
    if (_.isEmpty(window.mapboxDraw)) {
      if (!_.isEmpty(window.mapboxDraw)) {
        await window.map.removeControl(window.mapboxDraw);
      }
      if (!window.map.hasControl(window.mapboxDraw)) {
        window.mapboxDraw = new MapboxDraw({displayControlsDefault: false, styles: drawGeometryStyle});
        window.map.addControl(window.mapboxDraw);
      }


    }
    return window.mapboxDraw;
  }

  drawGeometry(drawGeometryType, cb) {
    removeMeasurementClickEvent(window.map)
    this.createNewMapboxDraw().then(() => {
      this.callback = cb;
      window.mapboxDraw.changeMode(drawGeometryType.name);
      window.map.on('draw.create', this.createDrawFeature);
      // window.M.toast({html: "Begin drawing your tracking area", classes: 'blue'});
    });
  }

  createDrawFeature() {
    const featureCollection = window.mapboxDraw.getAll();
    this.callback(featureCollection.features);
  }

  finishGeometry() {
    this.deleteDrawFeatures();
    window.map.off('draw.create', this.createDrawFeature);
  }


  editDrawGeometry(geometry, cb) {
    this.createNewMapboxDraw().then(() => {
      this.callback = cb
      this.deleteDrawFeatures();
      const featureId = window.mapboxDraw.add(geometry);
      if (geometry.type === "Point") {
        window.mapboxDraw.changeMode('simple_select', {featureId: featureId[0]});
        window.map.on('draw.update', this.finishEditing);
      } else {
        window.mapboxDraw.changeMode('direct_select', {featureId: featureId[0]});
        window.map.on('draw.update', (e) => this.callback(e.features));
      }
    })
  }

  finishEditing() {
    // this.deleteDrawFeatures();
    const featureCollection = this.getDrawFeatureCollection();
    this.callback(featureCollection.features)
    window.map.off('draw.update', this.finishEditing)
  }

  getDrawFeatureCollection() {
    return window.mapboxDraw.getAll();
  }

  deleteDrawFeatures() {
    window.mapboxDraw.deleteAll();
  }


  changeMode(mode = 'simple_select') {
    window.mapboxDraw.changeMode(mode);
  }

}

export default DrawTool
