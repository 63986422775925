import {createSlice} from "@reduxjs/toolkit";

const measurementEditSlice = createSlice({
  name: "measurementEdit",
  initialState: {
    data: {},
    loading: false,
    geojson: {}
  },
  reducers: {
    setMeasurementForEditAction(state, action) {
      return {
        ...state,
        data: action.payload.data
      }
    },
    setMeasurementGeoJsonForEditAction(state, action) {
      return {
        ...state,
        geojson: action.payload.geojson
      }
    },
    editMeasurementAction(state, action) {
      return {
        ...state,
        loading: true
      }
    },
    editMeasurementSuccessAction() {
      return {
        data: {},
        loading: false,
        geojson: {}
      }
    }
  }
})

export const {
  setMeasurementForEditAction,
  editMeasurementAction,
  setMeasurementGeoJsonForEditAction,
  editMeasurementSuccessAction
} = measurementEditSlice.actions

export default measurementEditSlice.reducer