import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {NavLink, useHistory} from "react-router-dom";
import {getIconComponentByType} from "../../utils/assets";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import MeasurementCreateOrEdit from "../../components/Measurement/Create";
import {useContext} from "react";
import {DrawToolContext} from "../../utils/layers/draw/DrawToolContext";

const MeasurementAddPage = () => {
  const measurementType = useSelector(state => state.measurementCreate.type)
  const drawTool = useContext(DrawToolContext)
  const history = useHistory()
  const handleBackClick = () => {
    drawTool.finishGeometry()
    history.push("/measure")
  }
  return (
    <Box pt={2}>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <IconButton
            onClick={handleBackClick}
          >
            {getIconComponentByType("keyboardBack")}
          </IconButton>
        </Grid>
        <Grid item container xs={10} justifyContent="center">
          <Typography variant="h5">Add {measurementType}</Typography>
        </Grid>
      </Grid>
      <Divider/>
      <MeasurementCreateOrEdit drawTool={drawTool}/>
    </Box>
  )
}

export default MeasurementAddPage