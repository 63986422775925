import { Avatar, ListItemAvatar, ListItemText, Menu, MenuItem, styled, Typography} from "@mui/material";
import { getIconComponentByTypeWithProps } from "../../utils/assets";
import React from "react";
import { downloadMeasurementGeoJsonAction } from "../../redux/slices/measurement/list";
import { useDispatch, useSelector } from "react-redux";
import { setMeasurementForEditAction } from "../../redux/slices/measurement/edit";
import { useHistory } from "react-router-dom";
import { measurementDeleteAction, setMeasurementDeleteIdAction } from "../../redux/slices/measurement/delete";
import {
  removeMeasureProfileSelectAction,
} from "../../redux/slices/measurement/profiler";
import DeleteDialog from "../Common/DeleteDialog";

const AvatarStyled = styled(Avatar)(({theme}) => ({
  backgroundColor: theme.palette.white + "!important",
  color: theme.palette.text.link + "!important",
}))

const MEASUREMENT_OPERATIONS = [
  {key: "saveAlt", title: "Download"},
  // {key: "hide", title: "Hide"},
  {key: 'edit', title: 'Edit'},
  {key: 'delete', title: 'Delete'},
]


const MeasurementActions = ({measurement, handleToggleMenuClose, anchorEl}) => {
  const openMenu = Boolean(anchorEl);
  const dispatch = useDispatch()
  const history = useHistory()

  const deleteSelectedId = useSelector(state => state.measurementDelete.selectedId)
  const loading = useSelector(state => state.measurementDelete.loading)

  const handleDelete = () => {
      dispatch(measurementDeleteAction({id: measurement.id}))
      dispatch(removeMeasureProfileSelectAction())
  }

  const handleMenuClick = (key) => {
    if(key === "saveAlt") {
      dispatch(downloadMeasurementGeoJsonAction({data: measurement}))
    }
    if(key === "edit") {
      dispatch(setMeasurementForEditAction({data: measurement}))
      history.push("/measure/edit")
    }
    if(key === "delete") {
      dispatch(setMeasurementDeleteIdAction({id:measurement.id}))
    }
    handleToggleMenuClose()
  }

  return (
    <>
      <Menu
        id="measurement-card-crud-dialog"
        aria-labelledby="measurement-card-crud-dialog"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleToggleMenuClose}
      >
        {MEASUREMENT_OPERATIONS.map((operation, index) => (
          <MenuItem
            sx={{
              minHeight: 32,
              height: 32,
            }}
            // button
            onClick={() => handleMenuClick(operation.key)}
            key={`measurement-operation-${operation.title}-${index}`}
          >
            <ListItemAvatar>
              <AvatarStyled>
                {getIconComponentByTypeWithProps(operation.key)({
                  fontSize: "small",
                })}
              </AvatarStyled>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="subtitle2">{operation.title}</Typography>
              }
            />
          </MenuItem>
        ))}
      </Menu>
      <DeleteDialog
        item={measurement}
        selectedId={deleteSelectedId}
        onClose={() => dispatch(setMeasurementDeleteIdAction({id: null}))}
        handleDelete={handleDelete}
        loading={loading}
      />
    </>
  )
}

export default MeasurementActions