import {createSlice} from "@reduxjs/toolkit";

const commonLayers = createSlice({
  name: "commonLayerSlice",
  initialState: {},
  reducers: {
    hideAllLayersAction(){},
    retainMapStateAfterRefreshAction(){},
    rearrangeLayersAfterRefreshAction(){}
  },

})

export const {hideAllLayersAction, retainMapStateAfterRefreshAction, rearrangeLayersAfterRefreshAction} = commonLayers.actions

export default commonLayers.reducer