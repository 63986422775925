import PrivateAPI from "../../../../utils/API/PrivateAPI";
import {END, eventChannel} from "redux-saga";

export const PrivateRequestExcel = (method, url) => {
    return PrivateAPI.request({
          method,
          url,
          responseType: 'blob'
      })
  }
  
const PrivateRequestBase = (method, url, data, options={}) => {
  return PrivateAPI.request({
        method,
        url,
        data,
        ...options
    })
}

export default PrivateRequestBase

export function RequestEmitter(requestFunction, payload) {
  return eventChannel((emit) => {
    const request = requestFunction(payload, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        emit({ percent: percentCompleted });
      },
    });

    request
      .then((response) => {
        emit({ percent: 100, response });
        emit(END);
      })
      .catch((error) => {
        emit({ percent: 0, error });
        emit(END);
      });

    return () => {};
  });
}