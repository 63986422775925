import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, TextareaAutosize, FormControl, InputLabel } from '@mui/material';
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import styled from 'styled-components';
import Export from "components/Export";
import { Link } from "react-router-dom";
import { ticketTypes } from "utils/layers/mapUtils";
import PrivateRequestBase from "redux/sagas/requests/rootRequest/PrivateRequestBase";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const StyledLink = styled(Link)`
  margin-left: 2px;
  margin-top: 5px;
`;
const SupportPage = () => {
  const [open, setOpen] = useState(false);
  const userEmail = localStorage.getItem("userEmail");
  const name = localStorage.getItem("name");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  

  const [formData, setFormData] = useState({
    name: name,
    email: userEmail,
    type: '',
    description: '',
    image: null,
  });
  const handleRaiseTicket = () => {

  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    setFormData({ ...formData, image: imageFile });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedData = new FormData();
    updatedData.append('name', formData.name);
    updatedData.append('email', formData.email);
    updatedData.append('description', formData.description); 
    updatedData.append('type', formData.type); 
    updatedData.append('file', formData.image); 

        PrivateRequestBase("post", 'api/support_ticket/', updatedData)
        .then((response) => {
          if (response.status  !== 201) {
              setSnackbarMessage('fail');
              setOpenSnackbar(true);
            // throw new Error(`HTTP error! Status: ${response.status}`);
          }
          setSnackbarMessage('Successfully created a ticket.');
          setOpenSnackbar(true);
          return response;
        })
        .then((result) => {
        })
        .catch((error) => {
          setSnackbarMessage('Failed to create support ticket.');
          setOpenSnackbar(true);
          console.error("Error fetching data:", error);
        });
    handleClose();
  };

  const handleReset = () => {
    setFormData({
      name: name,
      email: userEmail,
      type: '',
      description: '',
      image: null,
    });
  };
  
  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={2}
        pb={2}
      >
        <Typography variant="h4">Support</Typography>
      </Box>
      <Divider />
      <Grid
        container
        direction="column"
        spacing={1}
        sx={{
          padding: "8px 8px 8px",
        }}
      >
        
      <Grid item>
      <a href="https://civiltracker.xyz/docs/" target="_blank" rel="noopener noreferrer"
        style={{ textDecoration: "none",
        marginLeft: '7px',
        marginTop: '5px'
      }} 
        >
          <Export
            tittle="Knowledge Base"
            avatarIcon="knowledgeBaseIcon"
            isAction={true}
          />
    </a>
        </Grid>

    <Grid item onClick={handleOpen}>
    <a href="#" style={{ textDecoration: "none" }}>

        <Export
          tittle="Support"
          avatarIcon="supportIcon"
          onSave={handleRaiseTicket}
          isAction={true}
        />
    </a>

      </Grid>
    <Modal open={open} >
    <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="text"
            color="secondary"
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: '10px', 
              right: '10px', 
            }}
          >
            x
          </Button>
          <Typography variant="h6" gutterBottom>
            Support Form
          </Typography>
          <Divider />
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              name="email"
              disabled={true}
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={handleInputChange}
              >
              {ticketTypes.map((item, index) => (
                <MenuItem key={index} value={Object.keys(item)[0]}>
                  {Object.values(item)[0]}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
            <TextareaAutosize
                minRows={10}
                maxRows={20}
                placeholder="description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                style={{ width: '99%' }}
                margin="normal"
                
            />
            <input
              type="file"
              accept="image/*"
              name="image"
              onChange={handleImageChange}
              style={{ margin: '10px 0' }}
            />
            <Divider />
            <div style={{ marginTop: '10px' }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
            <Button variant="outlined" color="primary" onClick={handleReset} style={{ marginLeft: '10px' }}>
              Reset
            </Button>
            </div>
          </form>
        </Box>
      </Modal>
        </Grid>
        <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleCloseSnackbar}
        severity="success"
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
    </>
  );
};

export default SupportPage;
