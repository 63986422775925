import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PublicRequestBase from "../../redux/sagas/requests/rootRequest/PublicRequestBase";
import BasicLayout from "../../components/Basic/Layout";

const EmailVerifyPage = () => {
  const params = useParams();
  const [verify, setVerify] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    PublicRequestBase("post", "api/auth/register/verify-email/", {
      key: params.key,
    })
      .then((response) => {
        if (response.data.detail === "ok") {
          setVerify(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setVerify(false);
        setLoading(false);
        console.log({ ...error });
      });
  }, []);
  return (
    <BasicLayout headerButtonLabel="Sign In" headerButtonLink="/">
      {!loading && verify ? (
        <section>
          <Box pt={2} pb={2}>
            <Box mb={2} textAlign="center">
              <Typography variant="h4" component="h2" gutterBottom>
                Email Verified successfully.
              </Typography>
              <Box mb={2} textAlign="center">
                <Typography variant="subtitle1">
                  Click{" "}
                  <Link color="secondary" href="/" variant="subtitle1">
                    here{" "}
                  </Link>
                  to Sign In.
                </Typography>
              </Box>
            </Box>
          </Box>
        </section>
      ) : (
        <section>
          <Box pt={2} pb={2}>
            <Box mb={2} textAlign="center">
              <Typography variant="h4" component="h2" gutterBottom>
                Email Not Verified successfully.
              </Typography>
              <Box mb={2} textAlign="center">
                <Typography variant="subtitle1">
                  Click{" "}
                  <Link color="secondary" href="/" variant="subtitle1">
                    here{" "}
                  </Link>
                  for Home Page.
                </Typography>
              </Box>
              <Divider />
            </Box>
          </Box>
        </section>
      )}
    </BasicLayout>
  );
};

export default EmailVerifyPage;
