import {createSlice} from "@reduxjs/toolkit";

const projectDeleteSlice = createSlice({
  name: "deleteProjectState",
  initialState: {
    loading: false,
    selectedId: false
  },
  reducers: {
    deleteProjectAction(state, action){
      return {
        ...state,
        loading: true
      }
    },
    setDeleteProjectLoadingAction(state, action) {
      return {
        ...state,
        loading: false
      }
    },
    setDeleteProjectSelectedIdAction(state, action){
      return{
        ...state,
        selectedId: action.payload.id
      }
    }
  }
})

export const {deleteProjectAction, setDeleteProjectLoadingAction, setDeleteProjectSelectedIdAction} = projectDeleteSlice.actions

export default projectDeleteSlice.reducer