
const template = {
  name: null,
  title: null,
  type: 'geojson',
  layout: {'visibility': 'none'},
  subLayers: {},
  showInLayerSwitcher: false,
}

function getEmptyGeoJson() {
  return {type: 'FeatureCollection', features: []}
}

export function getGeoJsonLayerBase(layerType, subLayer=null) {
  const geoJsonLayers = {
    "tracking_polygons": {
      ...template,
      name: 'user-polygons',
      title: 'Tracking Polygons',
      order: 7,
      subLayers: {
        polygon: {
          renderType: 'fill',
          'paint': {
            'fill-outline-color': '#000',
            'fill-color': ['get', 'user_color'],
            'fill-opacity': 0.6,
          },
          data: getEmptyGeoJson()
        },
        polyline: {
          renderType: 'line',
          'paint': {
            'line-color': ['get', 'user_color'],
            'line-width': 3
          },
          data: getEmptyGeoJson()
        },
      }
    },
    "measurement": {
      ...template,
      name: 'user-measurement',
      title: 'measurements',
      order: 8,
      subLayers: {
        'Point': {
          renderType: "circle",
          paint: {
            "circle-radius": 7,
            "circle-color": ["get", "point_color"],
            "circle-opacity": ["get", "visibility"]
          },
          data: getEmptyGeoJson()
        },
        'LineString': {
          renderType: "line",
          paint: {
            "line-color": ["get", "linestring_color"],
            "line-width": 3,
            "line-opacity": ["get", "visibility"],
          },
          data: getEmptyGeoJson()
        },
        'Polygon': {
          renderType: "fill",
          paint: {
            'fill-outline-color': ['get', 'polygon_color'],
            // 'fill-outline-color': '#fff',
            'fill-color': ['get', 'polygon_color'],
            'fill-opacity': ['get', 'visibility']
          },
          data: getEmptyGeoJson()
        }
      },
    },
    "gcp-points": {
      ...template,
       name: 'gcp-flight',
      subLayers : {
        'point': {
          renderType: "circle",
          paint: {
            "circle-radius": 7,
            "circle-color": "#0276FD",
            "circle-opacity": 1
          },
          data: getEmptyGeoJson()
        },
      },
      order: 9,
    },
    "raw-image": {
      ...template,
     name: "raw-points",
      subLayers : {
        'point': {
          renderType: "circle",
          paint: {
            "circle-radius": 7,
            "circle-color": "#FFC500",
            "circle-opacity": 1
          },
          data: getEmptyGeoJson()
        },
      },
      order: 9,
    }
  }

  if (subLayer) {
    let layer = geoJsonLayers[layerType]
    layer.subLayers = {[subLayer]: geoJsonLayers[layerType].subLayers[subLayer]}
    return layer
  }

  return geoJsonLayers[layerType]
}

