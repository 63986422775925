import {useMediaQuery, useTheme} from "@mui/material";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "./TextField";

const DatePicker = ({label, value, onChange, name, ...props}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true
  });

  const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

  return (
    isMobile ?
      <MobileDatePicker
        label={label}
        value={value}
        onChange={date =>onChange(convertToDefEventPara(name,date))}
        renderInput={(params) => <TextField name={name} {...params} {...props}/>}
        {...props}
      />
      :
      <DesktopDatePicker
        label={label}
        value={value}
        onChange={date =>onChange(convertToDefEventPara(name,date))}
        renderInput={(params) => <TextField name={name} {...params} {...props}/>}
        {...props}
      />
  )
}

export default DatePicker