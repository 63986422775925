import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  styled,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "../../components/Common/TextField";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LoadingButton from "../../components/Common/LoadingButton";
import BasicLayout from "../../components/Basic/Layout";
// import { useForm } from "../../hooks/useForm";
import {
  resetPasswordConfirmAction,
  resetResetPasswordAction,
  setAuthorizeLinkRequest,
} from "../../redux/slices/basic/resetPasswordSlice";
import { useDispatch, useSelector } from "react-redux";

import Link from "@mui/material/Link";
import SetPasswordMessage from "../../components/Basic/ForgotPassword/SetPasswordMessage";
import Loader from "../../components/Common/Loader";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validationSchemaSetPassword } from "../../components/Common/Validations";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";

const GridItemStyled = styled(Grid)(({ theme }) => ({
  width: "100%",
}));

const ResetLinkWrapp = styled(Box)(({ theme }) => ({
  padding: "20px",
  height: " 20%",
  display: "flex",
  alignItems: "center",
  justifyContent: " center",
  marginTop: "85px",
  flexDirection: "column",
}));

const initialValues = {
  new_password: "",
  confirm_password: "",
};

const ResetPasswordConfirmPage = () => {
  const params = useParams();
  const { uid } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const serverErrors = useSelector((state) => state.resetPassword.serverErrors);
  const loading = useSelector((state) => state.resetPassword.loading);
  const success = useSelector((state) => state.resetPassword.success);
  const isLoggedIn = useSelector((state) => state.logout.isLoggedIn);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmitForm = (data) => {
    const payloadData = {
      new_password: data.new_password,
      confirm_password: data.confirm_password,
    };
    // if (validate()) {
    setLoading(true);
    setTimeout(() => {
      dispatch(resetPasswordConfirmAction({ ...payloadData, uuid: uid }));

      setLoading(false);
    }, 2000);
    // }
  };

  const handleLogin = () => {
    dispatch(resetResetPasswordAction());
    history.push("/");
  };

  const handleResetPassword = () => {
    dispatch(resetResetPasswordAction());
    history.push("/forgot-password");
  };

  useEffect(() => {
    const getData = () => {
      dispatch(
        setAuthorizeLinkRequest({
          uuid: uid,
        })
      );
      // history.push("/forgot-password");
    };
    if (uid) {
      getData();
    }
  }, [dispatch, uid]);

  const handleResendLink = () => {
    history.replace("/reset-password");
  };

  const { handleSubmit,register, formState: { errors }, } = useForm({ defaultValues: initialValues, resolver: yupResolver(validationSchemaSetPassword),
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <BasicLayout headerButtonLabel="Sign Up" headerButtonLink="/signup">
      {isLoading ? <Loader /> : null}
      {Object.keys(serverErrors).length !== 0 ? (
        <ResetLinkWrapp>
          <p>Invalid Link</p>
          <GridItemStyled item>
            <Box px={2} pb={2}>
              <LoadingButton
                onclick={handleResendLink}
                loading={loading && isLoggedIn}
                label="Resend"
                fullWidth
                sx={{
                  height: "30px",
                }}
              />
            </Box>
          </GridItemStyled>
        </ResetLinkWrapp>
      ) : (
        <>
          <form
            method="post"
            style={{ marginTop: 20 }}
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              py={2}
            >
              {/*<Box pt={2}><Typography>Login with</Typography></Box>*/}
              <Box pt={2}>
                <Typography variant="h5">Set Password</Typography>
              </Box>
            </Box>
            {success ? (
              <SetPasswordMessage />
            ) : (
              <Grid
                container
                direction="column"
                spacing={2}
                alignItems="center"
              >
                {errors.token && (
                  <GridItemStyled item>
                    <Box px={2} display="inline-flex">
                      <Typography color="error">
                        Invalid request, Please resend reset password email.
                      </Typography>
                      <Link
                        component={Typography}
                        onClick={handleResetPassword}
                        underline="none"
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        Click here.
                      </Link>
                    </Box>
                  </GridItemStyled>
                )}
                <GridItemStyled item>
                  <Box px={2}>
                    <FormControl sx={{ m: 1, width: "95%" }} variant="outlined">
                      <InputLabel htmlFor="new_password">Password</InputLabel>
                      <OutlinedInput
                        id="new_password"
                        {...register("new_password")}
                        placeholder="Password"
                        label="new_password"
                        required
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <span className="errorCls">
                        {errors.new_password?.message}
                      </span>
                    </FormControl>
                  </Box>
                  {/* <Box px={2}>
                  <TextField
                    label="Password"
                    fullWidth
                    required
                    type="password"
                    name="new_password"
                    value={values.new_password}
                    error={errors.new_password}
                    onChange={handleInputChange}
                  />
                </Box> */}
                </GridItemStyled>
                <GridItemStyled item>
                  <Box px={2}>
                    <FormControl sx={{ m: 1, width: "95%" }} variant="outlined">
                      <InputLabel htmlFor="confirm_password">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="confirm_password"
                        type={showPassword ? "text" : "password"}
                        {...register("confirm_password")}
                        placeholder="Confirm password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="confirm_password"
                        required
                      />
                      <span className="errorCls">
                        {errors.confirm_password?.message}
                      </span>
                    </FormControl>
                  </Box>
                  {/* <Box px={2}>
                  <TextField
                    label="Confirm Password"
                    fullWidth
                    required
                    type="password"
                    name="confirm_password"
                    value={values.confirm_password}
                    error={errors.confirm_password}
                    onChange={handleInputChange}
                  />
                </Box> */}
                </GridItemStyled>
                <GridItemStyled item>
                  <Box px={2} pb={2}>
                    <Button
                      type="submit"
                      //onClick={handleSubmit}
                      name="Sign In"
                      loading={isLoading && isLoggedIn}
                      variant="contained"
                      className="mainBtn"
                    >
                      Reset Password
                    </Button>
                    {/* <LoadingButton
                      onclick={handleSubmit}
                      loading={loading && isLoggedIn}
                      label="Next"
                      fullWidth
                      sx={{
                        height: "30px",
                      }}
                    /> */}
                  </Box>
                </GridItemStyled>
                {/* <GridItemStyled>
            <Box
              px={2}
              pb={2}
              justifyContent="center"
              display="flex"
              width="100%"
            >
              <Link
                underline="none"
                sx={{ cursor: "pointer" }}
                onClick={handleLogin}
              >
                Login Here
              </Link>
            </Box>
          </GridItemStyled> */}
              </Grid>
            )}
          </form>
        </>
      )}
    </BasicLayout>
  );
};

export default ResetPasswordConfirmPage;
