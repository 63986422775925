import Report from "../../../components/Report";

const DisplayReport = () => {
    return (
      <>
        <Report />
        </>
    )
};

export default DisplayReport;