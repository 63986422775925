import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import BasicLayout from "../../components/Basic/Layout";
import { styled } from "@mui/material";
import { useForm } from "../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPasswordAction,
  resetResetPasswordAction,
} from "../../redux/slices/basic/resetPasswordSlice";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import TextField from "../../components/Common/TextField";
import LoadingButton from "../../components/Common/LoadingButton";
import ForgotPasswordSuccess from "../../components/Basic/ForgotPassword/ForgotPasswordSuccess";
import { Modal } from "../../components/Common/Modal";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { passwordMessage } from 'components/Basic/ForgotPassword/ForgotPasswordSuccess';

const GridItemStyled = styled(Grid)(({ theme }) => ({
  width: "100%",
}));

const ModalChildren = styled(Box)(({ theme }) => ({
  width: "100%",
  // padding: "0px 10px",
}));
const GridStyled = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  justifyContent: "center",
}));
const TypoStyle = styled(Typography)(({ theme }) => ({
  lineHeight: 1.4,
  fontSize: 16,
  padding: "5px 10px",
}));
const ButtonStyled = styled(Button)(({ theme }) => ({
  //height: 34,
  borderRadius: "12px",
  padding: 10,
  boxShadow:
    "0px 4px 7px -1px rgba(0, 0, 0, 0.11), 0px 2px 4px -1px rgba(0, 0, 0, 0.07)",
}));

const initialValues = {
  email: "",
};

const ResetPasswordPage = () => {
  const dispatch = useDispatch();

  const serverErrors = useSelector((state) => state.resetPassword.serverErrors);
  const loading = useSelector((state) => state.resetPassword.loading);
  const success = useSelector((state) => state.resetPassword.success);
  const isLoggedIn = useSelector((state) => state.logout.isLoggedIn);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    temp.email = fieldValues.email ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const history = useHistory();
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, validate, true);

  const handleSubmit = () => {
    if (validate()) {
      dispatch(resetPasswordAction({ ...values }));
      setOpen(true);
    }
  };
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    dispatch(resetResetPasswordAction());
    history.push("/");
  };

  return (
    <BasicLayout headerButtonLabel="Sign In" headerButtonLink="/">
      {open ? (
        <Modal open={open} setOpen={setOpen} title="Reset Password">
          <Divider />
          <ModalChildren>
            <TypoStyle variant="h3" component="h2" gutterBottom>
              Help is on the way
            </TypoStyle>
            <TypoStyle variant="subtitle1" component="h2" gutterBottom>
              We're combing through our records to find the Civil Tracker
              account for
            </TypoStyle>
            <Box
              fontWeight="bold"
              display="inline"
              style={{ margin: "10px 10px", width: "100%", display: "flex" }}
            >
              {values.email}
            </Box>

            <TypoStyle>
             {passwordMessage}
            </TypoStyle>
          </ModalChildren>
          <Divider />
          <GridStyled container mb={2}>
            <Grid>
              <ButtonStyled
                onClick={handleClick}
                variant="contained"
                component={Link}
              >
                Go back to Sign In Page
              </ButtonStyled>
              {/* <LoadingButton
                onclick={handleClick}
                loading={loading && isLoggedIn}
                label="Go to Sign In page"
                fullWidth
                sx={{
                  height: "30px",
                }}
              /> */}
              {/* <Link
                onClick={handleClick}
                sx={{
                  cursor: "pointer",
                }}
                underline="none"
                variant="body2"
              >
                Go back to Sign In Page
              </Link> */}
            </Grid>
          </GridStyled>
        </Modal>
      ) : (
        // <ForgotPasswordSuccess email={values.email} />
        <>
          <Box display="flex" flexDirection="column" alignItems="center" py={2}>
            <Box pt={2}>
              <Typography variant="h5">Forgot Password</Typography>
            </Box>
          </Box>

          <Grid container direction="column" spacing={2} alignItems="center">
            <GridItemStyled item>
              <Box px={2}>
                <TextField
                  label="Email"
                  fullWidth
                  required
                  name="email"
                  value={values.email}
                  error={errors.email}
                  onChange={handleInputChange}
                />
              </Box>
            </GridItemStyled>
            <GridItemStyled item>
              <Box px={2} pb={2}>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  name="Sign In"
                  loading={loading && isLoggedIn}
                  variant="contained"
                  className="mainBtn"
                >
                  Next
                </Button>
                {/* <LoadingButton
                  onclick={handleSubmit}
                  loading={loading && isLoggedIn}
                  label="Next"
                  fullWidth
                  sx={{
                    height: "30px",
                  }}
                /> */}
              </Box>
            </GridItemStyled>
          </Grid>
        </>
      )}
    </BasicLayout>
  );
};

export default ResetPasswordPage;
