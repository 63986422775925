import {useState} from "react";
import {getIconComponentByType} from "../../../utils/assets";
import IconButton from "@mui/material/IconButton";
import SortMeasurementMenu from "../Menu/SortMeasurementMenu";

const SortMeasurementButton = ({buttonStyle}) => {
   const [anchorEl, setAnchorEl] = useState(null);

  const handleToggleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton sx={buttonStyle} onClick={handleToggleMenuOpen}>
        {getIconComponentByType("sort")}
      </IconButton>
      <SortMeasurementMenu anchorEl={anchorEl} handleToggleMenuClose={handleToggleMenuClose}/>
    </>
  )
}

export default SortMeasurementButton
