import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetResetPasswordAction } from "../../../redux/slices/basic/resetPasswordSlice";
import Link from "@mui/material/Link";
import { useHistory, useLocation } from "react-router-dom";

export const passwordMessage = `If we find a match, you'll get an email with further instructions.
If you don't hear from us in next 15 minutes, please double check
that you entered the correct email address.`;

const GridStyled = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  justifyContent: "center",
}));

const ForgotPasswordSuccess = ({ email }) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    return () => {
      dispatch(resetResetPasswordAction());
    };
  }, [location]);

  const handleClick = () => {
    dispatch(resetResetPasswordAction());
    history.push("/");
  };

  return (
    <section>
      <Box pt={2} pb={2}>
        <Box mb={3} textAlign="center">
          <Typography variant="h4" component="h2" gutterBottom>
            Help is on the way
          </Typography>
          <Typography variant="subtitle1" component="h2" gutterBottom>
            We're combing through our records to find the Civil Tracker account
            for{" "}
            <Box fontWeight="bold" display="inline">
              {email}
            </Box>
            <Typography>
              {passwordMessage}
            </Typography>
          </Typography>
          <Divider />
          <GridStyled container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Link
                onClick={handleClick}
                sx={{
                  cursor: "pointer",
                }}
                underline="none"
                variant="body2"
              >
                Go back to Sign In Page
              </Link>
            </Grid>
          </GridStyled>
        </Box>
      </Box>
    </section>
  );
};

export default ForgotPasswordSuccess;
