import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../Common/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import FlightItem from "./FlightItem";
import FlightEdit from "./Edit";
import FlightDate from "./FlightDate";
import { isEmpty } from "lodash";
import mapboxgl from 'mapbox-gl';
import React, { useState, useEffect } from 'react';
import { updateFlightDataListAction, clearFlightListAction, getFlightListAction } from "../../redux/slices/flight/list";
import { getRasterLayersAction } from "../../redux/slices/see/list";
import proj4 from 'proj4';
import { createRasterLayerAction, resetCombinedLayers } from "redux/slices/layers/rasterLayersSlice";
import { checkMapLayersSource, drawWMSLayers, moveMeasurementLayersToTop } from 'utils/layers/mapUtils';
import PrivateRequestBase from "../../redux/sagas/requests/rootRequest/PrivateRequestBase";


const FlightList = () => {
  const dispatch = useDispatch();
  const flights = useSelector((state) => state.flightList.data);
  const currentProject = useSelector((state) => state.selectedProject);
  const flightEdit = useSelector((state) => state.flightEdit);
  const flightDateUpdate = useSelector((state) =>{
    return state.flightDateUpdate;
  } );
  const flightSelectedId = useSelector((state) => state.flightSelected.id);
  const flightUuId = flights?.filter(fid => fid.id === flightSelectedId);
  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/events/flight/${flightUuId?.[0]?.uuid}`;
  const [isFlightedChecked, setIsFlightedChecked] = useState(false); 
  const loading = useSelector((state) => state.flightList.loading);
  const userEmail = localStorage.getItem("userEmail");
  const flightData = useSelector((state) => state.seeList);
  

 useEffect(() => {
    drawWMSLayers();
    moveMeasurementLayersToTop('wms');
  },[flightSelectedId]);


  useEffect(() => {
    moveMeasurementLayersToTop('wms');

  }, [])

  useEffect(() => {
    let fetchDataInterval;
    const fetchData = async () => {
      try {
        if(flightUuId && !(flightUuId?.[0]?.processed)) {
        const response = await PrivateRequestBase("get", apiUrl);
        if (response?.data?.status) {
          dispatch(getFlightListAction());
          window.map &&
          window.map?.flyTo({
            center: response?.data?.project_data?.location.coordinates,
            zoom: 15,
            speed: 2,
          });
          dispatch(getRasterLayersAction({ flightId: response?.data?.flight_data?.id }));
          setIsFlightedChecked(true); 
          clearInterval(fetchDataInterval);
        }
      }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (flights?.length && !isFlightedChecked) {
      fetchDataInterval = setInterval(fetchData, 5000);
    }

    return () => clearInterval(fetchDataInterval);
  }, [flights, apiUrl, dispatch, isFlightedChecked]);

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={2}
        pb={2}
      >
        <Typography variant="h4">Flights</Typography>
        <Box pt={2}>
          <Button
            label="Add New Flight"
            size="small"
            sx={{
              height: 37,
            }}
            component={NavLink}
            to="/flight/add"
          />
        </Box>
      </Box>
      <Divider />
      {flights.length > 0 ? (
        <Grid
          container
          direction="column"
          spacing={1}
          sx={{
            padding: "8px 8px 8px",
          }}
        >
         {flights?.map((flight, index) => {
           const isLast = index === 0;
          return (
            <Grid item xs key={flight.id}>
              {!isEmpty(flightDateUpdate) ? (flightDateUpdate?.id === flight?.id ? <FlightDate flight={flight}  /> : null) : null}
              {!isEmpty(flightEdit) ? (
                flightEdit.id === flight.id ? (
                  <>
                  <FlightEdit flight={flight} />
                  </>
                ) : (
                  <FlightItem flight={flight} isLast={isLast} />
                )
              ) : (
                <FlightItem flight={flight} isLast={isLast} />
              )}
            </Grid>
          );
        })}
        </Grid>

      ) : (
        <Typography align="center" mt={4}>
          No Flight Found
        </Typography>
      )}
    </>
  );
};

export default FlightList;
