import React, { useState } from "react";
import { Typography, Grid, Paper, TextareaAutosize,TextField, Box, Button, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Header from '../header';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '450px'
}));


const Invoice = () => {
  const [isEditable, setIsEditable] = useState(false);

  const handleEditToggle = () => {
    setIsEditable(!isEditable);
  };
  return (
    <div style={{ backgroundColor: "", padding: "20px" }}>
      <Header />
  <div style={{ display: 'flex', padding: '0px 150px 0px 150px', height: '100vh' }}>

<Grid container spacing={2} style={{ marginTop: '-100px' }} >
  <Grid item xs={4} >
      <Item>
      <Box display="flex" justifyContent="space-between" alignItems="center" >
          <Typography variant="h6">Profile Information</Typography>
          <IconButton onClick={handleEditToggle}>
            {isEditable ? (
              <SaveIcon color="primary" />
            ) : (
              <EditIcon color="primary" />
            )}
          </IconButton>
        </Box>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={2}>
            <TextareaAutosize
        style={{
          width: '315px',
          height: '217px',
          padding: '8px',
          border: '1px solid #ccc',
        }}
        placeholder="Enter your text here..."
        rowsMin={4}
      />
            </Grid>
            
          </Grid>
          <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '16px', backgroundColor: '#FFC500' }}
        >
          Save
        </Button>
        </form>
    </Item>
    </Grid>

  <Grid item xs={6}>
    <Item>
    <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Personal Information</Typography>
        <IconButton onClick={handleEditToggle}>
          {isEditable ? (
            <SaveIcon color="primary" />
          ) : (
            <EditIcon color="primary" />
          )}
        </IconButton>
      </Box>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Company Name"
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Designation"
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Mobile"
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Location (Address)"
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Website URL"
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={!isEditable}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '16px', backgroundColor: '#FFC500' }}
        >
          Save
        </Button>
      </form>
    </Item>
  </Grid>
  </Grid>

</div>
      
    </div>
  );
};

export default Invoice;
