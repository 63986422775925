import PrivateRequestBase from "./rootRequest/PrivateRequestBase";

export function createDesignRequest(payload) {
  return PrivateRequestBase("post", `api/design/`, payload);
}

export function getDesignListRequest(projectId) {
  return PrivateRequestBase("get", `api/design?project=${projectId}`);
}

export function deleteDesignFileRequest(id) {
  return PrivateRequestBase("delete", `api/design/${id}/`);
}
