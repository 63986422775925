import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import IconMenuItems from "./IconMenuItems";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

const MainDrawer = ({ children }) => {
  const drawerExpand = useSelector((state) => state.drawerState.drawerExpand);
  return (
    <Stack
      divider={<Divider orientation="vertical" flexItem />}
      direction="row"
      sx={{ height: "100%" }}
    >
      {/* <div style={{ display: "flex" }}> */}
      <Box display="flex" overflow="scroll">
        <IconMenuItems />
      </Box>
      <Box
        overflow="scroll"
        sx={{
          width: {
            xs: drawerExpand ? 223 : 0,
            sm: drawerExpand ? 238 : 0,
          },
        }}
      >
        {drawerExpand && children}
      </Box>
    </Stack>
  );
};

export default MainDrawer;
