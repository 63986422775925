import {useForm} from "../../hooks/useForm";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "../Common/TextField";
import Select from "../Common/Select";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import SubmitResetButton from "../Common/SubmitResetButton";
import {createSmartTrackingPolygonAction} from "../../redux/slices/smartTracking/create";
import {useHistory} from "react-router-dom";
import {editSmartTrackingPolygonAction} from "../../redux/slices/smartTracking/edit";

const initialValue = {
  name: "",
  project_material: "",
}

const SmartTrackingCreateOrEdit = ({drawTool, polygon}) => {

  const projectMaterial = useSelector(state => state.projectMaterialList.data)
  const selectedProjectMaterial = useSelector(state => state.smartTrackingCreate.projectMaterial)

  const smartTrackingCreateLoading = useSelector(state => state.smartTrackingCreate.loading)
  const smartTrackingEditLoading = useSelector(state => state.smartTrackingEdit.loading)
  const totalCreatedSmartTrackingCount = useSelector(state => state.smartTrackingList.data.length)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSubmit = () => {
    if (polygon) {
      dispatch(editSmartTrackingPolygonAction({...values, history: history, drawTool: drawTool}))
    } else {
      dispatch(createSmartTrackingPolygonAction({...values, history: history, drawTool: drawTool}))
    }
  }

  const validate = (fieldValues = values) => {
    let temp = {...errors}
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required."
    if ('project_material' in fieldValues)
      temp.project_material = fieldValues.project_material ? "" : "This field is required."
    setErrors({
      ...temp
    })


    if (fieldValues === values)
      return Object.values(temp).every(x => x === "")
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
    setValues
  } = useForm(polygon ? polygon : initialValue, validate, true)

  const editGeometryCallback = (feature) => {
    setValues(prevState => ({
      ...prevState,
      points: feature[0].geometry
    }))
  }

  useEffect(() => {
    if (polygon) {
      drawTool.editDrawGeometry(polygon.points, editGeometryCallback)
    } else {
      setValues(prevState => ({
        ...prevState,
        project_material: selectedProjectMaterial,
        name: `Polygon-${totalCreatedSmartTrackingCount + 1}`
      }))
    }

    return () => {
      drawTool.finishGeometry()
    }
  }, [])

  return (
    <Box pt={2} m={1}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <TextField
            label="Name"
            fullWidth
            required
            name="name"
            value={values.name}
            error={errors.name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <SubmitResetButton handleSubmit={handleSubmit} resetForm={resetForm}
                             loading={polygon ? smartTrackingEditLoading : smartTrackingCreateLoading}/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SmartTrackingCreateOrEdit