import { call, select, put } from "redux-saga/effects";
import { stockpileReportRequest } from "redux/sagas/requests/reportRequests";
import { setStockpileReportDataAction } from 'redux/slices/report';

export function* stockpileReportHandler() {
  try {
    const flightSelected = yield select(state => state.flightSelected);
    const flight_uuid = flightSelected?.uuid;
    const response = yield call(stockpileReportRequest, flight_uuid );
    if(response) {
        yield put((setStockpileReportDataAction(response?.data)));
    }
    else {
        console.error("error instockpileReportHandler ", response.message);
    }
   
  } catch (error) {
    console.error(error);
  }
}







