import {createTheme} from '@mui/material/styles';
import typography from './typography';
import overrides from './overrides';
import palette from './palette';

const theme = createTheme({
  typography,
  overrides,
  palette,
  props: {
    MuiFormControl: {
      variant: 'outlined',
      size: 'small',
    },
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
    },
    MuiButton: {
      size: 'small',
    },
  },
});

export default theme;
