import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { NavLink } from "react-router-dom";
import { getIconComponentByType } from "../../utils/assets";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import DesignCreate from "../../components/Design/Create";

const DesignAddPage = () => {
  return (
    <Box pt={2}>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <IconButton component={NavLink} to="/design">
            {getIconComponentByType("keyboardBack")}
          </IconButton>
        </Grid>
        <Grid item container xs={10} justifyContent="center">
          <Typography variant="h5">Add Design Surface</Typography>
        </Grid>
      </Grid>
      <Divider />
      <DesignCreate />
    </Box>
  );
};

export default DesignAddPage;
