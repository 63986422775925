import React from "react";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const TitleWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "14px 10px",
  backgroundColor: "#FFC500",
}));
const ChildWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const Modal = ({ title, primaryHeading, open, setOpen, children }) => {
  const handleClose = () => setOpen(false);
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ maxWidth: "100% !important", marginBottom: "5%" }}
    >
      <TitleWrap>
        <Typography id="modal-title" variant="h4" component="h2">
          {title}
        </Typography>
        {/* <CloseIcon
          style={{
            cursor: "pointer",
          }}
          onClick={handleClose}
        /> */}
      </TitleWrap>

      {/* <SecondTitle>{primaryHeading}</SecondTitle> */}
      <ChildWrapper>{children && <> {children}</>}</ChildWrapper>
    </Dialog>
  );
};

export { Modal };
