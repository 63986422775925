import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {getIconComponentByType, getIconComponentByTypeWithProps} from "../../../utils/assets";
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "react";
import AddMeasurementButton from "./AddMeasurementButton";
import FilterMeasurementButton from "./FilterMeasurementButton";
import SortMeasurementButton from "./SortMeasurementButton";
import {useDispatch, useSelector} from "react-redux";
import {getGeoJsonLayerBase} from "../../../utils/layers/initialGeoJsonLayers";
import _ from "lodash";
import {getMapBoxResourceId, setMapLayerLayoutProperty} from "../../../utils/layers/mapUtils";
import {updateGeoJsonLayerVisibilityAction} from "../../../redux/slices/layers/geoJsonLayersSlice";

const buttonStyle = {
  padding: 0,
  width: 31,
  height: 23
}

const MeasureButtons = () => {

  const filterData = useSelector(
    (state) => state.measurementList.filterData
  );
  const dispatch = useDispatch()

  const selectedFlightId = useSelector(state => state.flightSelected.id)
  const [layerVisibility, setLayerVisibility] = useState(true);

  useEffect(() => {
    if (_.isUndefined(window.map)) return
    const visibility = layerVisibility === true ? "visible" : "none"
    const baseGeoJsonLayerData = getGeoJsonLayerBase("measurement");
    const filteredMeasurementArray = filterData?.map(item => {
      const { geometry, flight } = item;
      const typeName = geometry.type.toLowerCase();
      return `user-measurement-${typeName}-${flight}`;
  });
  
    Object.keys(baseGeoJsonLayerData.subLayers).forEach(subLayerKey => {
      const mboxResId = `${baseGeoJsonLayerData.name}-${subLayerKey}-${selectedFlightId}`;
      if (filteredMeasurementArray?.includes(mboxResId.toLowerCase())) {
        const lastDashIndex = mboxResId.lastIndexOf("-");
      const result = mboxResId.substring(0, lastDashIndex);
      const layerId = getMapBoxResourceId(result, selectedFlightId, true)
      if(setMapLayerLayoutProperty(layerId, "visibility", visibility)) dispatch(updateGeoJsonLayerVisibilityAction({key: layerId, visibility: visibility}))
    
  }
  else if(!filteredMeasurementArray?.length) {
      const layerId = getMapBoxResourceId(`${baseGeoJsonLayerData.name}-${subLayerKey}`, selectedFlightId, true)
      if(setMapLayerLayoutProperty(layerId, "visibility", visibility)) dispatch(updateGeoJsonLayerVisibilityAction({key: layerId, visibility: visibility}))
    
  }
  })
  }, [layerVisibility])

  return (
      <Grid container justifyContent="space-around">
      <Grid item>
        <Paper variant="outlined">
         <AddMeasurementButton buttonStyle={buttonStyle}/>
        </Paper>
      </Grid>
      <Grid item>
        <Paper variant="outlined">
          <FilterMeasurementButton buttonStyle={buttonStyle}/>
        </Paper>
      </Grid>
      <Grid item>
        <Paper variant="outlined">
          <SortMeasurementButton buttonStyle={buttonStyle}/>
        </Paper>
      </Grid>
      <Grid item>
        <Paper variant="outlined">
          <IconButton sx={buttonStyle} color="primary" onClick={() => setLayerVisibility(!layerVisibility)}>
            {layerVisibility ? getIconComponentByType("eyeOpen") : getIconComponentByTypeWithProps("eyeClose")({
              color: "secondary"
            })}
            {}
          </IconButton>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default MeasureButtons