import store from "../../store";
import {measurementItemSelectAction} from "../../slices/layers/geoJsonLayersSlice";
import {getMapBoxResourceId, updateSourceLayerGeoJsonData} from "../../../utils/layers/mapUtils";
import {getGeoJsonLayerBase} from "../../../utils/layers/initialGeoJsonLayers";

export function setupMeasurementGeoJson(data, type) {
  return {
    type: "FeatureCollection",
    features: data.map(item => {
      return {
        type: 'Feature',
        properties: {
          ...item.properties,
          [`${type.toLowerCase()}_color`]: item.color,
          ['visibility']: item.type === 2 ? 0.5 : 1,
          selected: false,
          id: item.id,
          type: item.type
        },
        geometry: {
          type: type,
          coordinates: item.geometry.coordinates
        }
      }
    })
  }
}

export function downloadMeasurementGeoJsonHelper(measurement) {
  return {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "properties": {...measurement.properties, color: measurement.color},
        "geometry": measurement.geometry
      }
    ]
  }
}

const handleClick = (event, map) => {
  const properties = event.features[0].properties;
  const sublayerKey = properties.type === 0 ? "Point" : (properties.type === 1 ? "LineString" : "Polygon")
  store.dispatch(measurementItemSelectAction({
    id: properties.id,
    type: properties.type,
    selected: !properties.selected
  }))
  const geoJson = store.getState().geoJsonLayers[event.features[0].layer.id].subLayers[sublayerKey].data
  updateSourceLayerGeoJsonData(map, event.features[0].source, geoJson)
}

export function removeMeasurementClickEvent() {
  const geoJsonLayers = Object.keys(store.getState().geoJsonLayers)
  geoJsonLayers.forEach(layer => {
    window.map.off("click", layer, (event) => {
      handleClick(event, window.map)
    })
  })
}

export function addMeasurementClickEvent(layerId, map) {
  map.on('mouseenter', layerId, () => {
    map.getCanvas().style.cursor = 'pointer';
  })

  map.on('mouseleave', layerId, () => {
    map.getCanvas().style.cursor = '';
  });

  map.on("click", layerId, (event) => {
    handleClick(event, map)
  })
}

export function setupSmartTrackingGeoJson(data) {
  return {
    type: "FeatureCollection",
    features: data.map(item => {
      return {
        type: 'Feature',
        properties: {
          ...item.properties,
          user_color: item.color,
          id: item.id,
          name: item.name
        },
        geometry: item.points
      }
    })
  }
}

export function getMeasurementSelectedValue(geoJsonLayers, item, selectedFlightId) {
  const sublayerKey = item.type === 0 ? "Point" : (item.type === 1 ? "LineString" : "Polygon")
    const geoJsonLayerInfo = getGeoJsonLayerBase("measurement", sublayerKey)
    const layerId = getMapBoxResourceId(`${geoJsonLayerInfo.name}-${sublayerKey}`, selectedFlightId, true)
  if(geoJsonLayers.hasOwnProperty(layerId) && geoJsonLayers[layerId].hasOwnProperty("subLayers") && geoJsonLayers[layerId].subLayers.hasOwnProperty(sublayerKey)){
    const data = geoJsonLayers[layerId].subLayers[sublayerKey].data.features.filter(measureItem => measureItem.properties && measureItem.properties.id === item.id)
    if (data.length > 0) {
      return data[0].properties.selected
    }
    return false
  }
  return false

}