import PrivateRequestBase from "./rootRequest/PrivateRequestBase";
import { PrivateRequestExcel } from "./rootRequest/PrivateRequestBase";

export function saveOrthoExportRequest(flightId){
  return PrivateRequestBase('get', `api/processedflightresource/?flight=${flightId}&type=ortho`)
}

export function exportFileDownloadRequest(data){
  return PrivateRequestBase('get', `api/file_download/${data?.flight_uuid}/${data?.type}`)
}

export function saveMeasureExportRequest(flightId){
  return PrivateRequestBase('get', `api/flight/${flightId}/measurement_kml_download/`)
}
export function saveExportRequest(flightId, type){
  return PrivateRequestBase('get', `api/processedflightresource/?flight=${flightId}&type=${type}`)
}


export function saveExportSmartTrackingRequest(flightId){
  return PrivateRequestBase('get', `api/flight/${flightId}/smart_tracking_kml_download/`)
}

export function saveExportExcelRequest(flightId){
  return PrivateRequestExcel('get', `api/download_measurement_xlsx/${flightId}`)
}
