import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../Common/Button";
import { NavLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import MaterialItem from "./MaterialItem";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectMaterialListAction } from "../../redux/slices/material/projectMaterial/list";
import { deleteProjectMaterialAction } from "../../redux/slices/material/projectMaterial/delete";
import Grid from "@mui/material/Grid";
import { isEmpty } from "lodash";
import FlightEdit from "../Flight/Edit";
import FlightItem from "../Flight/FlightItem";

const MaterialList = () => {
  const dispatch = useDispatch();

  const projectMaterials = useSelector(
    (state) => state.projectMaterialList.data
  );
  const commonMaterials = useSelector((state) => state.commonMaterial);
  const flightSelectedId = useSelector((state) => state.flightSelected.id);
  const selectedFlightId = useSelector((state) => state.flightSelected.id);
  const flights = useSelector((state) => state.flightList.data);
  useEffect(() => {
    if (flightSelectedId == null || undefined) {
      // Remove markers if no flight is selected
      dispatch(deleteProjectMaterialAction());
    }
  }, [flightSelectedId]);

  useEffect(() => {
    dispatch(getProjectMaterialListAction());
  }, [selectedFlightId]);

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={2}
        pb={2}
      >
        <Typography variant="h4">Material</Typography>
        <Box pt={2}>
          <Button
            label="Add Material"
            size="small"
            sx={{
              height: 37,
            }}
            component={NavLink}
            to="/material/add"
          />
        </Box>
      </Box>
      <Divider />
      {flights.length > 0 && projectMaterials.length > 0 ? (
        <Grid
          container
          direction="column"
          spacing={1}
          sx={{
            padding: "8px 8px 8px",
          }}
        >
          {flights.length > 0 &&
            projectMaterials.map((projectMaterial) => (
              <Grid item xs key={projectMaterial.id}>
                <MaterialItem
                  projectMaterial={projectMaterial}
                  commonMaterial={commonMaterials}
                />
                {/*{!isEmpty(flightEdit) ? flightEdit.id === flight.id ?*/}
                {/*  <FlightEdit flight={flight}/> : <FlightItem flight={flight}/> : <FlightItem flight={flight}/>}*/}
              </Grid>
            ))}
        </Grid>
      ) : (
        <Typography align="center" mt={4}>
          No Material Found
        </Typography>
      )}
      {/*<MaterialItem/>*/}
    </>
  );
};

export default MaterialList;
