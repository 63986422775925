import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, styled } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import diamondImage from "../../../images/diamond.svg";

const BoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  margin: "0px 15px",
  height: 104,
  borderRadius: 16,
  padding: "16px 18px",
  background: "linear-gradient(135deg, #C2C2C2 0%, #383838 100%);",
}));

const PlanMiddleLineTypographyStyled = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-wrap",
  textAlign: "center",
  color: theme.palette.common.white,
}));

const PlanUpgradeBanner = () => {
  return (
    <BoxStyled>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="auto"
      >
        <Avatar
          src={diamondImage}
          variant="rounded"
          alt="Upgrade Plan"
          imgProps={{
            sx: {
              minWidth: 34,
              minHeight: 30,
              maxWidth: 34,
              maxHeight: 30,
              backgroundColor: "black",
            },
          }}
        />
        <Typography color="white" variant="h6">
          Upgrade
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          margin: "2px 0px 0px 0px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <PlanMiddleLineTypographyStyled
          // classes={{body1: classes.body1}}
          variant="body1"
        >
          Pick the right plan fo your organization
        </PlanMiddleLineTypographyStyled>
      </Box>
      <Box>
        <Button
          variant="contained"
          // color="secondary"
          fullWidth
          sx={{ padding: "8px 16px !important" }}
        >
          Choose a plan
        </Button>
      </Box>
    </BoxStyled>
  );
};

export default PlanUpgradeBanner;
