import {LoadingButton as MuiLoadingButton} from "@mui/lab";

export default function LoadingButton({label, color, variant, onclick, loading = false, ...props}) {
  return (
    <MuiLoadingButton
      variant={!!variant ? variant : "contained"}
      loading={loading}
      onClick={onclick}
      color={color}
      {...props}
    >
      {label}
    </MuiLoadingButton>
  )
}