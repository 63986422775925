// Material-UI icons
import PinDropIcon from "@mui/icons-material/PinDrop";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import RoomIcon from "@mui/icons-material/Room";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import TerrainOutlinedIcon from "@mui/icons-material/TerrainOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ImageIcon from "@mui/icons-material/Image";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import StraightenIcon from "@mui/icons-material/Straighten";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import TimelineIcon from "@mui/icons-material/Timeline";
import BorderStyleIcon from "@mui/icons-material/BorderStyle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import GoogleIcon from "@mui/icons-material/Google";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FlightIcon from "@mui/icons-material/Flight";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SsidChartIcon from '@mui/icons-material/SsidChart';
import PrintReportIcon from '@mui/icons-material/Print';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import QuizIcon from '@mui/icons-material/Quiz';

const getIconComponentByType = (type) => {
  return {
    pinDrop: <PinDropIcon />,
    groupAdd: <GroupAddIcon />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
    deleteOutline: <DeleteOutlineIcon />,
    eyeOpen: <VisibilityIcon />,
    eyeClose: <VisibilityOffIcon />,
    save: <SaveIcon />,
    check: <CheckIcon />,
    close: <CloseIcon />,
    add: <AddIcon />,
    help: <HelpIcon />,
    expandMore: <ExpandMoreIcon />,
    expandLess: <ExpandLessIcon />,
    moreVert: <MoreVertIcon />,
    search: <SearchIcon />,
    gridView: <ViewComfyIcon />,
    location: <RoomIcon />,
    checkCircleOutlined: <CheckCircleOutlinedIcon />,
    keyboardBack: <KeyboardBackspaceIcon />,
    terrain: <TerrainOutlinedIcon />,
    cloudUpload: <CloudUploadOutlinedIcon />,
    checkCircle: <CheckCircleIcon />,
    error: <ErrorIcon />,
    dragIndicator: <DragIndicatorIcon />,
    imageIcon: <ImageIcon />,
    saveAlt: <SaveAltIcon />,
    straightenIcon: <StraightenIcon />,
    addLocation: <AddLocationIcon />,
    polyLine: <TimelineIcon />,
    polygon: <BorderStyleIcon />,
    filter: <FilterAltIcon />,
    sort: <UnfoldMoreIcon />,
    arrowUp: <ArrowUpwardIcon />,
    arrowDown: <ArrowDownwardIcon />,
    google: <GoogleIcon />,
    arrowBackIos: <ArrowBackIosIcon />,
    arrowForwardIos: <ArrowForwardIosIcon />,
    flightIcon: <FlightIcon />,
    filterAltOffIcon: <FilterAltOffIcon />,
    profileIcon: <SsidChartIcon />,
    PrintIcon: <PrintReportIcon />,
    supportIcon: <PsychologyAltIcon />,
    knowledgeBaseIcon: <QuizIcon />
  }[type];
};

const getIconComponentByTypeWithProps = (type) => {
  return {
    pinDrop: (props = {}) => <PinDropIcon {...props} />,
    groupAdd: (props = {}) => <GroupAddIcon {...props} />,
    edit: (props = {}) => <EditIcon {...props} />,
    delete: (props = {}) => <DeleteIcon {...props} />,
    deleteOutline: (props = {}) => <DeleteOutlineIcon {...props} />,
    eyeOpen: (props = {}) => <VisibilityIcon {...props} />,
    eyeClose: (props = {}) => <VisibilityOffIcon {...props} />,
    save: (props = {}) => <SaveIcon {...props} />,
    check: (props = {}) => <CheckIcon {...props} />,
    close: (props = {}) => <CloseIcon {...props} />,
    add: (props = {}) => <AddIcon {...props} />,
    help: (props = {}) => <HelpIcon {...props} />,
    expandMore: (props = {}) => <ExpandMoreIcon {...props} />,
    expandLess: (props = {}) => <ExpandLessIcon {...props} />,
    moreVert: (props = {}) => <MoreVertIcon {...props} />,
    search: (props = {}) => <SearchIcon {...props} />,
    gridView: (props = {}) => <ViewComfyIcon {...props} />,
    checkCircleOutlined: (props = {}) => <CheckCircleOutlinedIcon {...props} />,
    keyboardBack: (props = {}) => <KeyboardBackspaceIcon {...props} />,
    terrain: (props = {}) => <TerrainOutlinedIcon {...props} />,
    cloudUpload: (props = {}) => <CloudUploadOutlinedIcon {...props} />,
    checkCircle: (props = {}) => <CheckCircleIcon {...props} />,
    error: (props = {}) => <ErrorIcon {...props} />,
    dragIndicator: (props = {}) => <DragIndicatorIcon {...props} />,
    imageIcon: (props = {}) => <ImageIcon {...props} />,
    saveAlt: (props = {}) => <SaveAltIcon {...props} />,
    addLocation: (props = {}) => <AddLocationIcon {...props} />,
    polyLine: (props = {}) => <TimelineIcon {...props} />,
    polygon: (props = {}) => <BorderStyleIcon {...props} />,
    filter: (props = {}) => <FilterAltIcon {...props} />,
    sort: (props = {}) => <UnfoldMoreIcon {...props} />,
    arrowUp: (props = {}) => <ArrowUpwardIcon {...props} />,
    arrowDown: (props = {}) => <ArrowDownwardIcon {...props} />,
    google: (props = {}) => <GoogleIcon {...props} />,
    arrowBackIos: (props = {}) => <ArrowBackIosIcon {...props} />,
    arrowForwardIos: (props = {}) => <ArrowForwardIosIcon {...props} />,
    flightIcon: (props = {}) => <FlightIcon {...props} />,
    filterAltOffIcon: (props = {}) => <FilterAltOffIcon {...props} />,
    profileIcon: (props = {}) => <SsidChartIcon {...props} />,
    PrintIcon: (props = {}) => <PrintReportIcon {...props} />,
    supportIcon: (props = {}) => <supportIcon {...props} />,
    knowledgeBaseIcon: (props = {}) => <QuizIcon {...props} />,

  }[type];
};

export { getIconComponentByType, getIconComponentByTypeWithProps };
