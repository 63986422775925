import PrivateRequestBase from "./rootRequest/PrivateRequestBase";

export const getLineworkListRequest = async (projectuuid) => {
  return await PrivateRequestBase("get", `/api/line_work_file/?project_uuid=${projectuuid}`);
};

export const createLineworkRequest = async (formData) => {
  return await PrivateRequestBase("post", '/api/line_work_file/', formData);
};

export const getLineworkKLRequest = async (fileId) => {
  return await PrivateRequestBase("get", `/api/line_work_features/?file_uuid=${fileId}`);
};

export const deleteLineworkKLRequest = async (fileId) => {
  return await PrivateRequestBase("delete", `/api/line_work_file/?file_uuid=${fileId}`);
};