import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {getIconComponentByType} from "../../utils/assets";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MeasurementCreateOrEdit from "../../components/Measurement/Create";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import SmartTrackingCreateOrEdit from "../../components/SmartTracking/CreateOrEdit";
import {useContext} from "react";
import {DrawToolContext} from "../../utils/layers/draw/DrawToolContext";

const SmartTrackingEditPage = () => {

  const history = useHistory()
   const drawTool = useContext(DrawToolContext)

  const polygon = useSelector(state => state.smartTrackingEdit.data)

  const handleBackClick = () => {
    history.push("/smart-tracking")
  }

  return (
    <Box pt={2}>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <IconButton
            onClick={handleBackClick}
          >
            {getIconComponentByType("keyboardBack")}
          </IconButton>
        </Grid>
        <Grid item container xs={10} justifyContent="center">
          <Typography
            variant="h5">Edit Polygon</Typography>
        </Grid>
      </Grid>
      <Divider/>
      <SmartTrackingCreateOrEdit drawTool={drawTool} polygon={polygon}/>
    </Box>
  )
}

export default SmartTrackingEditPage