import {createSlice} from "@reduxjs/toolkit";

const measurementSelected = createSlice({
  name: "measurementSelectedState",
  initialState: {},
  reducers: {
    selectMeasurementAction(state, action) {
    },
    setSelectedMeasurementAction(state, action) {
      return {
        ...action.payload.measurement
      }
    },
    removeSelectedMeasurementAction() {
      return {}
    }
  }
})

export const { selectMeasurementAction, removeSelectedMeasurementAction, setSelectedMeasurementAction} = measurementSelected.actions

export default measurementSelected.reducer