const template = {
    name: null,
    title: null,
    type: "raster",
    sourceType: "mapbox",
    mapboxTileIdKey: 'mapbox_info',
    mapboxTileId: null,
    layout: {visibility: 'none'},
    paint: {"raster-opacity": 1},
    showInLayerSwitcher: true,
    firstFlight: true,
    initOnFlightSelect: true
}

export function getRasterLayerBase(layerType="") {
    const rasterLayers = {
        ortho: {
            ...template,
            name: 'user-ortho',
            title: 'Imagery',
            // mapboxTileIdKey: 'mapbox_tile_id',
            layout: {visibility: 'visible'},
            order: 1
        },
        ortho_cog: {
            ...template,
            name: 'user-ortho-cog',
            title: 'Imagery',
            // mapboxTileIdKey: 'mapbox_tile_id',
            layout: {visibility: 'visible'},
            order: 1
        },
        heatmap: {
            ...template,
            name: 'user-heatmap',
            title: "Cut-Fill",
            // mapboxTileIdKey: 'mapbox_change_tile_id',
            firstFlight: false,
            order: 2
        },
        cut_fill_cog: {
            ...template,
            name: 'user-heatmap_cog',
            title: "Cut-Fill",
            // mapboxTileIdKey: 'mapbox_change_tile_id',
            firstFlight: false,
            order: 2
        },
        design_heatmap: {
            ...template,
            name: 'user-design-heatmap',
            title: "Design Cut-Fill",
            order: 3
        },
        hillshade: {
            ...template,
            name: 'user-hillshade',
            title: 'Terrain',
            // sourceType: "wms"
            order: 4
        },
        hillshade_cog: {
            ...template,
            name: 'user-hillshade_cog',
            title: 'Terrain',
            // sourceType: "wms"
            order: 4
        },
        design_hillshade: {
            ...template,
            name: 'user-design-hillshade',
            title: 'Design Terrain',
            // sourceType: "wms",
            order: 5
        }
    }
    if (layerType) return rasterLayers[layerType]

    return rasterLayers
}