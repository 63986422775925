import {put, select} from "redux-saga/effects";
import {setupGcpDataHelper, setupRawFlightImagesGeoJson} from "../../helpers/layersHelper";
import {getMapBoxResourceId, removeMapLayer, setGeoJsonLayerToMap} from "../../../../utils/layers/mapUtils";
import {getGeoJsonLayerBase} from "../../../../utils/layers/initialGeoJsonLayers";
import {
  setRawFlightGCPLayerDataAction,
  setRawFlightImageLayerDataAction
} from "../../../slices/layers/flightUploadLayers";
import mapboxgl from "mapbox-gl";

export function* createRawFlightImageLayerHandler(action) {
  try {
    const locations = action.payload.locations
    // const imagesData = yield select(state => state.flightCreate.rawFlight[action.payload.flightUuid].data)
    const layerGeoJson = setupRawFlightImagesGeoJson(locations)
    const geoJsonLayerInfo = getGeoJsonLayerBase("raw-image", "point")
    const layerId = getMapBoxResourceId(`${geoJsonLayerInfo.name}-${"point"}`, "layer", true)
    geoJsonLayerInfo.subLayers["point"].data = layerGeoJson
    window.map && setGeoJsonLayerToMap(window.map, geoJsonLayerInfo, "layer", "visible", "point")
    const layerData = {
      layerId: layerId,
      sourceId: getMapBoxResourceId(`${geoJsonLayerInfo.name}-${"point"}`, "layer", false)
    }
    const bounds = new mapboxgl.LngLatBounds(
      locations[0].location,
      locations[0].location
    )
    for (const location of locations) {
      bounds.extend(location);
    }
    yield put(setRawFlightImageLayerDataAction({layerData: layerData}))
    window.map && window.map.fitBounds(bounds, {padding: 50})
  } catch (error) {
    console.log(error)
  }
}

export function* createGcpGeoJsonLayerHandler(action) {
  try {
    const points = action.payload.points
    const geoJsonLayerInfo = getGeoJsonLayerBase("gcp-points", "point")
    const layerId = getMapBoxResourceId(`${geoJsonLayerInfo.name}-${"point"}`, "layer", true)
    geoJsonLayerInfo.subLayers["point"].data = setupGcpDataHelper(points)
    window.map && setGeoJsonLayerToMap(window.map, geoJsonLayerInfo, "layer", "visible", "point")
    const layerData = {
      layerId: layerId,
      sourceId: getMapBoxResourceId(`${geoJsonLayerInfo.name}-${"point"}`, "layer", false)
    }
    yield put(setRawFlightGCPLayerDataAction({layerData: layerData}))
    window.map && window.map.flyTo({center: [points[0][1], points[0][2]], zoom: 15, speed: 2})
  } catch (error) {
    console.log(error)
  }
}

export function* removeRawFlightLayersHandler() {
  try {
    const layers = yield select(state => state.flightUploadLayers)
    for (let key in layers) {
      removeMapLayer(layers[key].layerId)
    }
  } catch (error) {
    console.log(error)
  }
}