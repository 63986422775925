import IconButton from "@mui/material/IconButton";
import {getIconComponentByType} from "../../../utils/assets";
import {useDispatch} from "react-redux";
import {useContext} from "react";
import {DrawToolContext} from "../../../utils/layers/draw/DrawToolContext";
import {useHistory} from "react-router-dom";
import {setMeasurementGeoJsonAndType} from "../../../redux/slices/measurement/create";
import {drawGeometryType} from "../../../utils/layers/draw/drawGeometryType";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const MeasurePolyLineButton = ({buttonStyle = {}, showLabel=false}) => {
  const dispatch = useDispatch()
  const drawTool = useContext(DrawToolContext)
  const history = useHistory()

  const pointGeometryData = (feature) => {
    if (feature && feature.length > 0) {
      dispatch(setMeasurementGeoJsonAndType({geojson: feature[0], type: "Polyline"}))
      history.push("/measure/add")
    }
  }
  const handleClick = (callback) => {
    drawTool.drawGeometry(drawGeometryType.polyline, callback)
  }
  return (
    <Box display="inline-flex" alignItems="center" onClick={() => handleClick(pointGeometryData)}>
      <IconButton sx={buttonStyle}>
        {getIconComponentByType("polyLine")}
      </IconButton>
      {showLabel && <Typography>Polyline</Typography>}

    </Box>

  )
}

export default MeasurePolyLineButton