import IconButton from "@mui/material/IconButton";
import { getIconComponentByType } from "../../../utils/assets";
import { useState } from "react";
import AddMeasurementMenu from "../Menu/AddMeasurementMenu";

const AddMeasurementButton = ({ buttonStyle }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={buttonStyle} onClick={handleToggleMenuOpen}>
        {getIconComponentByType("add")}
      </IconButton>
      <AddMeasurementMenu
        handleToggleMenuClose={handleToggleMenuClose}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default AddMeasurementButton;
