import { createSlice } from "@reduxjs/toolkit";

const geoJsonLayersSlice = createSlice({
  name: "geoJsonLayers",
  initialState: {},
  reducers: {
    createMeasurementLayerAction() {},
    createSmartTrackingPolygonLayers() {},
    updateGeoJsonLayerDataAction(state, action) {
      void (state[action.payload.key].subLayers[
        action.payload.sublayerKey
      ].data = action.payload.data);
    },
    createGeoJsonLayersAction(state, action) {
      return {
        ...state,
        [action.payload.layerKey]: { ...action.payload.layerData },
      };
    },
    updateGeoJsonLayerVisibilityAction(state, action) {
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          layout: { visibility: action.payload.visibility },
        },
      };
    },
    measurementItemSelectAction(state, action) {
      return {
        ...state,
        measurementData: action.payload,
      };
    },
    measureProfileSelectAction(state, action) {
      return {
        ...state,
        measurementData: action.payload,
      };
    },
    
    updateGeoJsonLayerItemSelectAction(state, action) {
      void state[action.payload.key].subLayers[
        action.payload.layerType
      ].data.features.forEach((item) => {
        if (item.properties.id === action.payload.id) {
          item.properties.selected = action.payload.selected;
        }
      });
    },
  },
});

export const {
  createMeasurementLayerAction,
  updateGeoJsonLayerDataAction,
  createGeoJsonLayersAction,
  updateGeoJsonLayerVisibilityAction,
  updateGeoJsonLayerItemSelectAction,
  measurementItemSelectAction,
  createSmartTrackingPolygonLayers,
  measureProfileSelectAction,
} = geoJsonLayersSlice.actions;

export default geoJsonLayersSlice.reducer;
