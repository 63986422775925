import { call, put, select, take } from "redux-saga/effects";
import {
  createFlightWebSocketRequest,
  createGeoTiffFlightRequest,
  createRawFlightGCPResourceRequest,
  createRawFlightImageRequest,
  createRawFlightRequest,
  deleteFlightRequest,
  editFlightNameRequest,
  getFlightListRequest,
} from "../requests/flightRequests";
import {
  getFlightListAction,
  setFlightListDataAction,
} from "../../slices/flight/list";

import { RequestEmitter } from "../requests/rootRequest/PrivateRequestBase";
import {
  createNewFlightUploadProgressAction,
  updateFlightUploadedPercentageAction,
  updateFlightUploadStatusAction,
} from "../../slices/flight/upload";
import {
  setTotalFilesCountAction,
  updateUploadedFilesCountAction,
  setRawFlightLoadingAction,
  setRawFlightUploadingAction,
  setTotalRawImageAction,
  setGeoTiffLoadingAction,
  updateRawFlightDataImageURLAction,
  rawFilesDropActionComplete,
  setRawFileDataAction,
  removeRawFlightItemAction,
  clearTotalFilesCountAction,
  clearUploadedFilesCountAction,
  setFlightDuplicateNameAction
} from "../../slices/flight/create";
import {
  createRawFlightGCPLayerAction,
  createRawFlightImageLayerAction,
  removeRawFlightLayersAction,
} from "../../slices/layers/flightUploadLayers";
import proj4 from "proj4";
import { validate as uuidValidate } from "uuid";
import isNull from "lodash/isNull";
import { resetFlightEditAction } from "../../slices/flight/edit";
import {
  setDeleteFlightLoadingAction,
  setDeleteFlightSelectedIdAction,
} from "../../slices/flight/delete";
import {
  getRasterLayersAction,
  setSeeListDataAction,
} from "../../slices/see/list";
import {
  removeSelectedFlightAction,
  selectFlightAction,
  setSelectedFlightAction,
} from "../../slices/flight/selected";
import store from "../../store";
import {
  createFileFromBlobURL,
  getImageTagsHelper,
} from "../helpers/flightHelper";
import { addWmsLayer, toggleWmsVisibility, setWmsOpacity } from "../../slices/flight/list";

import { getCenterOfLocations, moveMeasurementLayersToTop, removeMeasurementLayers } from "utils/layers/mapUtils";

export function* getFlightListHandler(action) {
  try {
    const selectedProjectId = yield select((state) => state.selectedProject.id);
    const response = yield call(getFlightListRequest, selectedProjectId);
    const currentProject = yield select((state) => state.selectedProject);

    yield put(setFlightListDataAction({ data: response.data }));

    if (response.data.length > 0) {
      const selectedFlightId = select((state) => state.flightSelected.id);
      if (
        response.data.filter((flight) => flight.id === selectedFlightId)
          .length > 0
      ) {
        yield put(
          selectFlightAction({
            flight: response.data.filter(
              (flight) => flight.id === selectedFlightId
            )[0],
          })
        );
      } else {
        yield put(selectFlightAction({ flight: response.data[0] }));
      }
    } else if (response.data.length === 0) {
      window.map._markers.forEach((marker) => marker.remove());
      const selectedProjectId = yield select(
        (state) => state.selectedProject.id
      );
      const response = yield call(getFlightListRequest, selectedProjectId);
      const defaultLatitude = "53.69";
      const defaultLongitude = "-106.81";

      window.map.flyTo({
        center: currentProject?.location?.coordinates || [defaultLongitude, defaultLatitude],
        zoom: 14,
      });
      window.map._markers.forEach((marker) => marker.remove());
      yield put(removeSelectedFlightAction());
    }
  } catch (error) {
    console.log(error);
  }
}
export function* createGeoTiffFlightHandler(action) {
  try {
    const projectId = yield select((state) => state.selectedProject.id);
    const formData = new FormData();
    formData.append("flight_name", action.payload.flight_name);
    formData.append("flown_date", action.payload.flown_date.toISOString());
    formData.append("ortho", action.payload.ortho);
    formData.append("elevation", action.payload.elevation);
    formData.append("project", projectId);

    yield put(createNewFlightUploadProgressAction({
      newFlightProgressUpload: { flightName: formData.get("flight_name") },
    }));

    const channel = yield call(RequestEmitter, createGeoTiffFlightRequest, formData);

    while (true) {
      const { percent, response, error } = yield take(channel);
      if (response) {
        if (response?.data?.message === "Flight name already exists.") {
          yield put(setFlightDuplicateNameAction('Flight with this name already exists'));
          yield put(setGeoTiffLoadingAction());
          yield put(updateFlightUploadStatusAction({
              flightName: action.payload.flight_name,
              uploadedStatus: "error",
            }));
          return;
        } else {
          yield put(getFlightListAction());
          yield put(setGeoTiffLoadingAction());
          yield put(updateFlightUploadStatusAction({
            flightName: formData.get("flight_name"),
            uploadedStatus: "success",
          }));
          action.payload.history.push("/flight");
        }
        yield put(getFlightListAction());
        return;
      }
      yield put(updateFlightUploadedPercentageAction({
        flightName: formData.get("flight_name"),
        uploadedPercentage: percent,
      }));
    }
  } catch (error) {
    yield put(setGeoTiffLoadingAction());
    console.log(error, "error 2");
    yield put(updateFlightUploadStatusAction({
      flightName: action.payload.flight_name,
      uploadedStatus: "error",
    }));
    action.payload.history.push("/flight");
    yield put(getFlightListAction());
  }
}

export function* createRawFlightHandler(action) {
  try {
    yield put(removeRawFlightLayersAction());

    yield put(
      createNewFlightUploadProgressAction({
        newFlightProgressUpload: { flightName: action.payload.flight_name },
      })
    );

    const flightUuid = action.payload.flightUuid;
    const flightCenter = action.payload.flightCenter;

    const projectId = yield select((state) => state.selectedProject.id);
    const rawImageData = yield select(
      (state) => state?.flightCreate?.rawFlight?.[flightUuid]?.data || []
    );

    if (!rawImageData.length) {
      throw new Error('No raw image data found');
    }

    yield put(
      setTotalFilesCountAction({
        totalFiles: rawImageData.length,
      })
    );

    yield put(
      setTotalRawImageAction({
        totalImages: rawImageData.length,
        flightUuid: flightUuid,
      })
    );
    yield put(
      setRawFlightUploadingAction({ flightUuid: flightUuid, status: true })
    );

    const payload = {
      flown_date: rawImageData[0].capturedTime,
      flight_name: action.payload.flight_name,
      is_gcp_flight: false,
      project: projectId,
      total_files: rawImageData.length,
      lat_long: flightCenter?.flightCenter
    };

    if (action.payload.gcp_file) {
      payload.is_gcp_flight = true;
      payload.epsg_code = action.payload.epsg_code;
    }

    const response = yield call(createRawFlightRequest, payload);
    yield put(getFlightListAction());
    yield put(setRawFlightLoadingAction());
    action.payload.history.push("/flight");
    const flight_uuid = response.data.flight_uuid;

    if (payload.is_gcp_flight) {
      const csvFormData = new FormData();
      csvFormData.append("flight_uuid", flight_uuid);
      csvFormData.append("file", action.payload.gcp_file);
      yield call(createRawFlightGCPResourceRequest, csvFormData);
    }

    let uploaded_images = 0;

    for (const [index, rawImage] of rawImageData.entries()) {
      const blob = yield call(createFileFromBlobURL, rawImage.image);
      const file = new File([blob], rawImage.filename);
      const imageFormData = new FormData();
      imageFormData.append("flight_uuid", flight_uuid);
      imageFormData.append("file", file);

      if (index === rawImageData.length - 1) {
        imageFormData.append("last_image", true.toString());

        yield call(createRawFlightImageRequest, imageFormData);

        yield put(
          updateFlightUploadedPercentageAction({
            flightName: action.payload.flight_name,
            uploadedPercentage: 100,
          })
        );

        yield put(
          updateFlightUploadStatusAction({
            flightName: action.payload.flight_name,
            uploadedStatus: "success",
          })
        );
      } else {
        yield call(createRawFlightImageRequest, imageFormData);
        uploaded_images += 1;

        const percent = Math.floor(
          (uploaded_images * 100) / rawImageData.length
        );

        yield put(
          updateFlightUploadedPercentageAction({
            flightName: action.payload.flight_name,
            uploadedPercentage: percent,
          })
        );

        yield put(
          updateUploadedFilesCountAction({
            uploadedFiles: uploaded_images,
          })
        );
      }
      URL.revokeObjectURL(rawImage.image);
    }

    yield put(clearTotalFilesCountAction());
    yield put(clearUploadedFilesCountAction());
  } catch (error) {
    yield put(
      updateFlightUploadStatusAction({
        flightName: action.payload.flight_name,
        uploadedStatus: "error",
      })
    );
    yield put(clearTotalFilesCountAction());
    yield put(clearUploadedFilesCountAction());
    yield put(setRawFlightLoadingAction());
    action.payload.history.push("/flight");
    console.error(error);
  }
}


export function* rawFilesDropHandler(action) {
  try {
    const files = action.payload.files;
    const flightUuid = action.payload.flightUuid;
    const rawImagesData = yield select(
      (state) => state.flightCreate.rawFlight[flightUuid]
    );
    yield put(
      setRawFlightUploadingAction({ flightUuid: flightUuid, status: false })
    );

    const start = new Date();

    const newImagesData = [];
    const locations = [];

    for (const file of files) {
      const { tags, longitude, latitude } = yield call(
        getImageTagsHelper,
        file
      );
      locations.push([longitude, latitude]);
      newImagesData.push({
        location: [longitude, latitude],
        capturedTime: tags.CreateDate.toISOString(),
        file: file,
        filename: tags.ImageDescription,
        uploaded: false,
      });
      // const imageData = {
      //     location: [longitude, latitude],
      //     capturedTime: tags.CreateDate.toISOString(),
      //     image: URL.createObjectURL(file),
      //     filename: tags.ImageDescription,
      //     uploaded: false
      //   }
      //
      //   yield put(setRawFileDataAction({data: imageData, flightUuid: flightUuid}))
    }
    const flightCenter = getCenterOfLocations(locations);
    yield put(
      createRawFlightImageLayerAction({
        flightUuid: flightUuid,
        locations: locations,
        flightCenter: flightCenter
      })
    );
    newImagesData.forEach((imageData) => {
      if (
        rawImagesData &&
        rawImagesData.data &&
        rawImagesData.data.length > 0
      ) {
        const foundData = rawImagesData.data.filter(
          (el) => el.capturedTime === imageData.capturedTime
        );
        if (foundData.length > 0) {
          URL.revokeObjectURL(foundData[0].image);
          const file = imageData.file;
          delete imageData.file;
          store.dispatch(
            updateRawFlightDataImageURLAction({
              capturedTime: imageData.capturedTime,
              image: URL.createObjectURL(file),
              flightUuid: flightUuid,
              center: flightCenter
            })
          );
        store.dispatch(rawFilesDropActionComplete());

        } else {
          const file = imageData.file;
          delete imageData.file;
          store.dispatch(
            setRawFileDataAction({
              data: { ...imageData, image: URL.createObjectURL(file) },
              flightUuid: flightUuid,
              center: flightCenter
            })
          );
        }
        store.dispatch(rawFilesDropActionComplete());
      } else {
        const file = imageData.file;
        delete imageData.file;
        store.dispatch(
          setRawFileDataAction({
            data: { ...imageData, image: URL.createObjectURL(file) },
            flightUuid: flightUuid,
          })
        );
        store.dispatch(rawFilesDropActionComplete());

      }
    });

    // files.forEach(file => {
    //
    //   exifr.parse(file, ["ImageDescription", "CreateDate",]).then(async tags => {
    //     const {longitude, latitude} = await exifr.gps(file)
    //
    //     // const latitudeMultiplier = tags.latitude.description === "North latitude" ? 1 : -1;
    //     // const longitudeMultiplier = tags.GPSLongitudeRef.description === "East longitude" ? 1 : -1;
    //     // if (rawImagesData && rawImagesData.data && rawImagesData.data.length > 0) {
    //     //   let is_already_present = false
    //     //   rawImagesData.data.every(point => {
    //     //     if (point.capturedTime !== tags.CreateDate.toISOString()) {
    //     //       is_already_present = false
    //     //       return true
    //     //     } else {
    //     //       is_already_present = true
    //     //       return false
    //     //     }
    //     //   })
    //     //   console.log(tags)
    //     //   if (!is_already_present) {
    //     //     const imageData = {
    //     //       location: [longitude, latitude],
    //     //       capturedTime: tags.CreateDate.toISOString(),
    //     //       image: URL.createObjectURL(file),
    //     //       filename: tags.ImageDescription,
    //     //       uploaded: false
    //     //     }
    //     //     store.dispatch(setRawFileDataAction({data: imageData, flightUuid: flightUuid}))
    //     //     store.dispatch(decreaseRawImageForExifProcessAction({flightUuid: flightUuid}))
    //     //
    //     //   } else {
    //     //     store.dispatch(updateRawFlightDataImageURLAction({
    //     //       capturedTime: tags.CreateDate.toISOString(),
    //     //       image: URL.createObjectURL(file),
    //     //       flightUuid: flightUuid
    //     //     }))
    //     //     store.dispatch(decreaseRawImageForExifProcessAction({flightUuid: flightUuid}))
    //     //   }
    //     // } else {
    //     const imageData = {
    //       location: [longitude, latitude],
    //       capturedTime: tags.CreateDate.toISOString(),
    //       image: URL.createObjectURL(file),
    //       filename: tags.ImageDescription,
    //       uploaded: false
    //     }
    //     store.dispatch(setRawFileDataAction({data: imageData, flightUuid: flightUuid}))
    //     store.dispatch(decreaseRawImageForExifProcessAction({flightUuid: flightUuid}))
    //     // }
    //
    //   })
    //
    // })
    // let timeInterval = setInterval(() => {
    //   console.log("time interval")
    //   const totalImages = store.getState().flightCreate.rawFlight[flightUuid].totalImages
    //   if (totalImages === 0) {
    //     clearInterval(timeInterval)
    //     store.dispatch(createRawFlightImageLayerAction({flightUuid: flightUuid}))
    //   }
    // }, 200)
    // console.log()
  } catch (error) {
    console.log(error);
  }
}

export function* downloadSampleGCPFileHandler() {
  try {
    const csvHeader =
      "PointId,Northing,Easting,Elevation,Description (optional)";
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvHeader);
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = "SampleGCPFile.csv";
    hiddenElement.click();
  } catch (error) {
    console.log(error);
  }
}

export function* handleGcpFileDropHandler(action) {
  try {
    const epsg = action.payload.epsg;
    const file = action.payload.file;
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;
      const headers = text.slice(0, text.indexOf("\n")).split(",");
      const rows = text.slice(text.indexOf("\n") + 1).split("\n");

      const newArray = rows.map((row) => {
        const values = row.split(",");
        return headers.reduce((obj, header, i) => {
          obj[header] = values[i];
          return obj;
        }, {});
      });
      let points = [];
      newArray.forEach((point) => {
        const p = proj4(`EPSG:${epsg}`, `EPSG:4326`);
        if (
          !Number.isNaN(parseFloat(point["Northing"])) &&
          !Number.isNaN(parseFloat(point["Easting"]))
        ) {
          points.push([
            point["PointId"],
            ...p.forward([
              parseFloat(point["Easting"]),
              parseFloat(point["Northing"]),
            ]),
          ]);
        }
      });

      store.dispatch(createRawFlightGCPLayerAction({ points: points }));
    };
    reader.readAsText(file);
  } catch (error) {
    console.log(error);
  }
}

export function* editFlightNameHandler(action) {
  try {
    yield call(editFlightNameRequest, action.payload.id, action.payload.data);
    yield put(resetFlightEditAction());
    yield put(getFlightListAction());
  } catch (error) {
    console.log(error);
  }
}

export function* deleteFlightHandler() {
  try {
    const flightId = yield select((state) => state.flightDelete.selectedId);
    yield call(deleteFlightRequest, flightId);
    yield put(setDeleteFlightSelectedIdAction({ id: null }));
    yield put(setDeleteFlightLoadingAction());
    yield put(getFlightListAction());
  } catch (error) {
    yield put(setDeleteFlightLoadingAction());
    console.log(error);
  }
}

export function* selectedFlightHandler(action) {
  try {
    const flightId = action.payload.flight.id;
    const alreadySelectedFlightId = yield select(
      (state) => state.flightSelected.id
    );
    // window.map._markers.forEach((marker) => marker.remove());
    removeMeasurementLayers();
  
    yield put(setSelectedFlightAction({ flight: action.payload.flight }));
    yield put(getRasterLayersAction({ flightId: flightId }));
    moveMeasurementLayersToTop('wms');
    moveMeasurementLayersToTop('linework');

    // window.map &&
    //   window.map.flyTo({
    //     center: action.payload.flight.location.coordinates,
    //     zoom: 15,
    //     speed: 2,
    //   });

    if (alreadySelectedFlightId !== flightId) {
      //  hide raster layer
      const rasterLayers = yield select((state) => state.rasterLayers);
      Object.keys(rasterLayers).forEach((layer) => {
        if (layer.includes(alreadySelectedFlightId)) {
          window.map.setLayoutProperty(layer, "visibility", "none");
        }
      });

      //  hide geojson layer
      const geoJsonLayers = yield select((state) => state.geoJsonLayers);
      Object.keys(geoJsonLayers).forEach((layer) => {
        if (layer.includes(alreadySelectedFlightId)) {
          window.map.setLayoutProperty(layer, "visibility", "none");
        }
      });
      moveMeasurementLayersToTop('user-measurement');
    }
  } catch (error) {
    console.log(error);
  }
}

export function* removeSelectedFlightHandler() {
  try {
    yield put(setSeeListDataAction({ data: [] }));
  } catch (error) {}
}

export function* checkRawFlightToRemoveHandler() {
  try {
    const allRawFlight = yield select((state) => state.flightCreate.rawFlight);
    for (const rawFlight in allRawFlight) {
      if (uuidValidate(rawFlight)) {
        if (
          allRawFlight[rawFlight].uploading !== true &&
          allRawFlight[rawFlight].data &&
          allRawFlight[rawFlight].data.length > 0
        ) {
          allRawFlight[rawFlight].data.forEach((item) =>
            URL.revokeObjectURL(item.image)
          );
          yield put(removeRawFlightItemAction({ flightUuid: rawFlight }));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* createFlightWebSocketHandler() {
  try {
    const projectUuid = yield select((state) => state.selectedProject.uuid);

    const socket = yield call(createFlightWebSocketRequest, projectUuid);
    console.log(socket);
  } catch (error) {
    console.log(error);
  }
}

export function* handleAddWmsLayer() {
  try {
 

  } catch (error) {
    console.error("Error adding WMS layer:", error);
  }
}


export function* handleToggleWmsVisibility(action) {
  try {
    const visibility = window.map.getLayoutProperty('wms-layer', 'visibility');
    if (visibility === 'visible') {
      window.map.setLayoutProperty('wms-layer', 'visibility', 'none');
    } else {
      window.map.setLayoutProperty('wms-layer', 'visibility', 'visible');
    }
    yield put(toggleWmsVisibility());
  } catch (error) {
    console.error("Error toggling WMS visibility:", error);
  }
}

export function* handleSetWmsOpacity(action) {
  try {
    window.map.setPaintProperty('wms-layer', 'raster-opacity', action.payload);
    yield put(setWmsOpacity(action.payload));
  } catch (error) {
    console.error("Error setting WMS opacity:", error);
  }
}

