import Button from "@mui/material/Button";
import {getIconComponentByType} from "../../../utils/assets";
import {Typography} from "@mui/material";
import {useState} from "react";
import SelectMaterialMenu from "../Menu/SelectMaterialMenu";


const SmartTrackingMaterialSelect = () => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        endIcon={getIconComponentByType("expandMore")}
        onClick={handleToggleMenuOpen}
        sx={{
          height: 23,
          width: 138
        }}
      >
        <Typography variant="inherit">Select Material</Typography>
      </Button>
      <SelectMaterialMenu anchorEl={anchorEl} handleToggleMenuClose={handleToggleMenuClose}/>
    </>
  )
}

export default SmartTrackingMaterialSelect