import { useDispatch, useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { styled, Tooltip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import ListItemIcon from "@mui/material/ListItemIcon";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StraightenIcon from "@mui/icons-material/Straighten";
import TerrainIcon from "@mui/icons-material/Terrain";
import TransformIcon from "@mui/icons-material/Transform";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import GetAppIcon from "@mui/icons-material/GetApp";
import QuizIcon from '@mui/icons-material/Quiz';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import NoteAddIcon from '@mui/icons-material/NoteAdd'


import Box from "@mui/material/Box";
import diamondImage from "../../../images/diamond.svg";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import { updateDrawerItemSelectedAction } from "../../../redux/slices/dashboard/drawerSlice";
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';

import { NavLink } from "react-router-dom";
import { BorderAllRounded } from "@mui/icons-material";

const iconMap = {
  FolderSpecialIcon: <FolderSpecialIcon />,
  FlightTakeoffIcon: <FlightTakeoffIcon />,
  ViewQuiltIcon: <ViewQuiltIcon />,
  VisibilityIcon: <VisibilityIcon />,
  StraightenIcon: <StraightenIcon />,
  TerrainIcon: <TerrainIcon />,
  TransformIcon: <TransformIcon />,
  CreateNewFolderIcon: <CreateNewFolderIcon />,
  GetAppIcon: <GetAppIcon />,
  profileIcon: <SsidChartIcon />,
  AccountCircleIcon: <AccountCircleIcon />,
  QuizIcon: <QuizIcon />,
  NoteAddIcon: <NoteAddIcon />


};

const StyledBadge = styled(Badge)`
    right: 10px;
    top: -10px;
    transform: translateY(-50%);
    color: red;
    font-weight: bold;
    font-size: 12px;
    background: #FFC500;
    padding: 2px;
    border-radius: 6px; 
    border: 1px solid yellow;
`;


const IconPaperStyled = styled(Paper)(({ theme, selected }) => ({
  width: 34.06,
  height: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

const IconMenuItems = () => {
  const menuItems = useSelector((state) => state.drawerState.menuItems);
  const dispatch = useDispatch();
  const handleClick = (e, index) => {};

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box>

<List disablePadding>
  {menuItems.map((item, index) => {
    if (item?.name === 'Account') {
      return null; 
    } else {
      return (
        <ListItemButton
          component={NavLink}
          to={item.link}
          onClick={(e) => {
            if (item?.name === 'Support') {
              e.preventDefault();
              return;
            } 
            dispatch(updateDrawerItemSelectedAction({ index: index }))
            }
          } 
          key={index}
          sx={{ position: 'relative' }}
        >
          <Tooltip placement="right" title={item?.name}>
            <ListItemIcon sx={{ width: "100%", minWidth: "100%" }}>
              <IconPaperStyled
                elevation={item.selected ? 1 : 2}
                selected={item.selected}
              >
                {iconMap.hasOwnProperty(item.icon) && iconMap[item.icon]}
              </IconPaperStyled>
            </ListItemIcon>
          </Tooltip>
         {item.name === 'LineWork' && (
            <StyledBadge badgeContent={'Beta'} color="primary" >
            </StyledBadge>
            
          )}
        </ListItemButton>
      );
    }
  })}
</List>


      </Box>
      {/* <Box marginTop="auto" display="flex" alignItems="center" flexDirection="column">
        <Avatar
          src={diamondImage}
          variant="rounded"
          alt="Upgrade Plan"
          imgProps={{
            sx: {
              minWidth: 34,
              minHeight: 30,
              maxWidth: 34,
              maxHeight: 30,
              backgroundColor: "black",
            }
          }}
        />
        <IconButton
        size="large"
      >
        <AccountCircleIcon
          fontSize="large"
        />
      </IconButton>
      </Box> */}
    </Box>
  );
};

export default IconMenuItems;
