import PrivateRequestBase from "./rootRequest/PrivateRequestBase";

export function createMeasurementRequest(payload) {
    return PrivateRequestBase("post", "api/measurement/", payload)
}

export function getMeasurementListRequest(flightId) {
    return PrivateRequestBase("get", `api/measurement/?flight=${flightId}`)
}

export function editMeasurementRequest(measurementId, payload) {
    return PrivateRequestBase("put", `api/measurement/${measurementId}/`, payload)
}

export function deleteMeasurementRequest(measurementId) {
    return PrivateRequestBase("delete", `api/measurement/${measurementId}/`)
}

export function createMeasurementProfileRequest(measurementId) {
    return PrivateRequestBase('get', `api/measurement/${measurementId}/generate_profile/`)
}