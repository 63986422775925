import React, { useState } from 'react';
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import isNull from "lodash/isNull";
import { AppBar, Toolbar, Typography, Avatar, Box, Button, IconButton, Accordion, 
    List, ListItem, ListItemButton,
    AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore, CameraAlt, ExitToApp } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PrivateRequestBase from "../../redux/sagas/requests/rootRequest/PrivateRequestBase";
import { panelData } from './panelData.js';

const CustomAccordionSummary = styled(AccordionSummary)`
  height: 35px !important; 
  min-height: 35px !important; 
  border-radius: 4px;
  padding: 0 !important; 
  margin: 0 !important; 
  border: 0 !important; 
  background-color: #EDEDED; 
`;

const CustomAccordionDetails = styled(AccordionDetails)`
  overflow: hidden; 
  word-wrap: break-word; 
  display: flex; 
  flex-direction: column; 
  height: 100%;
  position: relative; 
`;

const VerticalLineContainer = styled.div`
  position: absolute;
  top: 0;
  width: 1px; 
  height: 100%;
  margin-top: 5px;
  margin-left: -5px;
  margin-botton: 5px;
  background-color: #EDEDED;
`;

const AccountMain = () => {
  const dispatch = useDispatch();
  const flights = useSelector((state) => state.flightList.data);
  const projectUuid = useSelector((state) => state.selectedProject.uuid);
  const flightEdit = useSelector((state) => state.flightEdit);
  const flightSelectedId = useSelector((state) => state.flightSelected.id);
  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    let socket = null;

    return () => {
      if (!isNull(socket)) socket.close();
    };
  }, [flights]);


  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <>
    <Box 
alignItems="center"
display="flex"
flexDirection="column"
pt={2}
pb={2}
>
<Avatar
  src="https://generation-sessions.s3.amazonaws.com/d4b019eed7a7c17f06f8d996c137f40d/img/avatar@2x.png"
  alt="User Avatar"
  sx={{
    width: 100,
    height: 100,
    backgroundColor: 'grey',
    marginRight: '16px',
    border: '2px solid #FFC500',
    position: 'relative',
  }}
>
  <IconButton
    sx={{
      backgroundColor: 'grey',
      color: 'white',
      position: 'absolute',
      bottom: '0',
      left: '50%',
      transform: 'translateX(-50%)',
      '&:hover': {
        backgroundColor: '#FFC500',
      },
    }}
  >
    <CameraAlt />
  </IconButton>
</Avatar>
<Typography style={{
    width: '190px',
    height: '37px',
    top: '195px',
    left: '98px',
    borderRadius: '4px',
    backgroundColor: '#FFC500',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }} variant="h3">Basharat Iqbal</Typography>


</Box>
  

      <Box
        display="flex"
        flexDirection="column"
        mt={4}
        style={{ maxHeight: '100vh', overflowY: 'hidden' }}
      >
       {panelData.map((panel, index) => (
  <Box
    key={index}
    display="flex"
    flexDirection="column"
    pt={0}
    pb={0}
    mt={0.5}
    mr={1}
    ml={1}
  >
    <Accordion sx={{ boxShadow: 'none' }} defaultExpanded>
      <CustomAccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`panel-content-${index}`}
        id={`panel-header-${index}`}
      >
        <IconButton>
          {panel.icon}
        </IconButton>
        <Box flexGrow={1} display="flex" alignItems="center">
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            {panel.name}
          </Typography>
        </Box>
      </CustomAccordionSummary>
      <CustomAccordionDetails id={`panel-content-${index}`}>
      <VerticalLineContainer /> 
      {panel.buttons.map((button, buttonIndex) => (
          <ListItem key={buttonIndex} disablePadding>
          <ListItemButton
            // onClick={() => handleButtonClick(button)}
            component={Link} 
            to="/invoice"
            style={{
              borderRadius: '4px',
              margin: '5px',
              border: '0.5px solid #FFC500',
              background: activeButton === button ? '#EDEDED' : 'transparent',
              width: '100%',
            }}
          >
            {button}
          </ListItemButton>
        </ListItem>
          
          
        ))}
        
      </CustomAccordionDetails>
    </Accordion>
    
  </Box>
  
))}
        
        <Button
          key='signout'
          variant="h1"
          fullWidth
          component={Link} 
          to="/invoice"
          style={{
            marginTop: '8px',
            background: '#EDEDED',
            marginBottom: '1px',
          }}
        >
          Logout
        </Button>
      </Box>
    </>
  );
};

export default AccountMain;
