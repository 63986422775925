import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PointButton from "../Buttons/PointButton";
import PolyLineButton from "../Buttons/PolyLineButton";
import PolygonButton from "../Buttons/PolygonButton";
import {styled} from "@mui/material";

const MenuItemStyled = styled(MenuItem)(({theme}) => ({
  height: 30
}))

const AddMeasurementMenu = ({handleToggleMenuClose, anchorEl}) => {
  const openMenu = Boolean(anchorEl)
  return (
    <Menu
      id="measurement-add-dialog"
      aria-labelledby="measurement-add-dialog"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={openMenu}
      anchorEl={anchorEl}
      onClose={handleToggleMenuClose}
    >
      <MenuItemStyled onClick={handleToggleMenuClose}>
        <PointButton showLabel={true}/>
      </MenuItemStyled>
      <MenuItemStyled onClick={handleToggleMenuClose}>
        <PolyLineButton showLabel={true}/>
      </MenuItemStyled>
      <MenuItemStyled onClick={handleToggleMenuClose}>
          <PolygonButton showLabel={true}/>
      </MenuItemStyled>
    </Menu>
  )
}

export default AddMeasurementMenu