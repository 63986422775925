import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SmartTrackingButtons from "../../components/SmartTracking/Buttons";
import SmartTrackingList from "../../components/SmartTracking/List";

const SmartTrackingPage = () => {
  return (
    <>
     <Box alignItems="center" display="flex" flexDirection="column" pt={2} pb={2}>
        <Typography variant="h4">Smart Tracking</Typography>
        <Box pt={2} width="100%">
          <SmartTrackingButtons/>
        </Box>
      </Box>
      <Divider/>
      <SmartTrackingList/>
    </>

  )
}

export default SmartTrackingPage