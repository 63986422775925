import { createSlice } from '@reduxjs/toolkit';

const lineworkCreateSlice = createSlice({
  name: 'lineworkCreate',
  initialState: {
    loading: false,
    error: null,
    createdLinework: null,
  },
  reducers: {
    createLineworkAction: (state) => {
      state.loading = true;
    },
    resetLineworkFileAction: (state) => {
      state.loading = false;
      state.error = null;
      state.createdLinework = null;
    },
    setLineworkCreateError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setCreatedLinework: (state, action) => {
      state.loading = false;
      state.createdLinework = action.payload;
    },
  },
});

export const {
  createLineworkAction,
  resetLineworkFileAction,
  setLineworkCreateError,
  setCreatedLinework,
} = lineworkCreateSlice.actions;

export default lineworkCreateSlice.reducer;
