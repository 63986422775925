import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import { getIconComponentByType, getIconComponentByTypeWithProps } from "../../utils/assets";
import { formatDate } from "../../utils/formattings";
import Avatar from "@mui/material/Avatar";
import { cut } from "utils/layers/mapUtils";
import { styled } from "@mui/material";
import {
  toggleLineworkVisibility,
  deleteLineworkAction,
  setDeleteLineworkIdAction
} from "redux/slices/linework/list";
import DeleteDialog from "components/Common/DeleteDialog";



const LineworkItem = ({ LineworkItem }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const layerVisibility = useSelector((state) => state.lineworkList.visibleLayers?.[LineworkItem?.id] || false);
  const loading = useSelector((state) => state.lineworkList.loading);
  const deleteSelectedId = useSelector(
    (state) => state.lineworkList.selectedId
  );

  const handleToggleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleToggle = () => {
    dispatch(toggleLineworkVisibility({ id: LineworkItem.id, uuid: LineworkItem?.uuid }));
  };

  const handleDelete = () => {
    dispatch(deleteLineworkAction({ uuid: LineworkItem.uuid }))
  };

  const deletePopup = () => {
    // handleToggleMenuOpen();
    dispatch(setDeleteLineworkIdAction({ id: LineworkItem.id }));

    handleToggleMenuClose();
  };

  const selectLinework = () => {
    const coordinates = LineworkItem?.geometry?.coordinates || [];
    // window.map.flyTo({ center: coordinates, zoom: 15 });
  };

  return (
  <>
      <Card className="lineworkClass" sx={{ width: "100%" }} raised>
        <CardHeader
          component={Link}
          sx={{ padding: "5px 5px 5px 16px", cursor: "pointer" }}
          onClick={selectLinework}
          action={
              <>
              <IconButton color="primary" onClick={handleToggle}>
                  {layerVisibility ? getIconComponentByType("eyeClose") : getIconComponentByTypeWithProps("eyeOpen")({
                    color: "secondary"
                  })}
                </IconButton>
              <IconButton color="primary" onClick={deletePopup}>
                  { getIconComponentByTypeWithProps("delete")({
                    color: "primary"
                  })}
                </IconButton>
              </>
          
          }
          title={<Tooltip title={LineworkItem?.name}><span>{cut(LineworkItem?.name, 22)}</span></Tooltip>}
          style={{ textTransform: "capitalize", textDecoration: "none" }}
          subheader={formatDate(LineworkItem?.created_at, "dd-mm-yyyy")}
        />
      </Card>
      <DeleteDialog
          item={LineworkItem}
          selectedId={deleteSelectedId}
          onClose={() => dispatch(setDeleteLineworkIdAction({ id: null}))}
          handleDelete={handleDelete}
          loading={loading}
        />
  </>

  );
};

export default LineworkItem;
