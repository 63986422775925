import { styled } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ChevronRightRounded from "@mui/icons-material/ChevronRightRounded";
import Fab from "@mui/material/Fab";
import ChevronLeftRounded from "@mui/icons-material/ChevronLeftRounded";
import InitialDrawer from "./InitialDrawer";
import MainDrawer from "./MainDrawer";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerExpandAction } from "../../redux/slices/dashboard/drawerSlice";

const drawerWidth = 264;
const mobileDrawerWidth = 256;

const fabOpenMixin = (theme, maindrawer) => ({
  marginLeft: maindrawer ? 305 : drawerWidth,
  [theme.breakpoints.down("sm")]: {
    marginLeft: maindrawer ? 290 : mobileDrawerWidth,
  },
  transition: theme.transitions.create("margin-left", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const fabClosedMixin = (theme, maindrawer) => ({
  transition: theme.transitions.create("margin-left", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: maindrawer ? "66.047px" : `0px`,
});

const FabStyled = styled(Fab)(({ theme, open, maindrawer }) => ({
  top: "50%",
  position: "fixed",
  zIndex: 1,
  padding: 0,
  minHeight: "54px",
  minWidth: "19px",
  width: "19px",
  borderRadius: "0px 4px 4px",
  ...(open && {
    ...fabOpenMixin(theme, maindrawer),
    // '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...fabClosedMixin(theme, maindrawer),
  }),
}));

const openedMixin = (theme, maindrawer) => ({
  width: maindrawer ? 305 : drawerWidth,
  [theme.breakpoints.down("sm")]: {
    width: maindrawer ? 290 : mobileDrawerWidth,
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme, maindrawer) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: maindrawer ? "66.047px" : `0px`,
});

const DrawerStyled = styled(Drawer)(({ theme, open, maindrawer }) => ({
  // position: "absolute",
  width: maindrawer ? 305 : drawerWidth,
  [theme.breakpoints.down("sm")]: {
    width: maindrawer ? 290 : mobileDrawerWidth,
    ...(open && {
      ...openedMixin(theme, maindrawer),
      "& .MuiDrawer-paper": openedMixin(theme, maindrawer),
    }),
    ...(!open && {
      ...closedMixin(theme, maindrawer),
      "& .MuiDrawer-paper": closedMixin(theme, maindrawer),
    }),
  },
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  flexShrink: 0,
  ...(open && {
    ...openedMixin(theme, maindrawer),
    "& .MuiDrawer-paper": openedMixin(theme, maindrawer),
  }),
  ...(!open && {
    ...closedMixin(theme, maindrawer),
    "& .MuiDrawer-paper": closedMixin(theme, maindrawer),
  }),
  // position: "absolute",
  // zIndex: 3,
}));

export default function AppDrawer({ children }) {
  const selectedItem = useSelector(
    (state) =>
      state.drawerState.menuItems.filter((item) => item.selected === true)[0]
  );
  // const [drawerExpand, setDrawerExpand] = useState(true)
  const drawerExpand = useSelector((state) => state.drawerState.drawerExpand);
  const dispatch = useDispatch();
  return (
    <>
      {!selectedItem.initialDrawer && (
        <DrawerStyled
          variant="permanent"
          open={drawerExpand}
          maindrawer={!selectedItem.initialDrawer ? 1 : 0}
        >
          <Toolbar
            sx={{
              minHeight: {
                xs: 54,
                sm: 64,
              },
            }}
          />

          <>
            <MainDrawer>{children}</MainDrawer>

            {/* <FabStyled
              component={IconButton}
              onClick={() =>
                dispatch(setDrawerExpandAction({ drawerExpand: !drawerExpand }))
              }
              color="primary"
              open={drawerExpand}
              maindrawer={!selectedItem.initialDrawer ? 1 : 0}
            >
              {!drawerExpand ? (
                <ChevronRightRounded color="secondary" />
              ) : (
                <ChevronLeftRounded color="secondary" />
              )}
            </FabStyled> */}
          </>
        </DrawerStyled>
      )}
      {selectedItem.initialDrawer && (
        <div style={{ width: 230, padding: "16px 0px" }}>
          <InitialDrawer />
        </div>
      )}
    </>
  );
}
