import {createSlice} from "@reduxjs/toolkit";

const flightUploadLayers = createSlice({
  name: "flightUploadLayers",
  initialState: {
    rawImage: {},
    gcp: {},
    flightCenter: [],
  },
  reducers: {
    createRawFlightImageLayerAction(state, action) {
      return action.payload
    },
    setRawFlightImageLayerDataAction(state, action) {
      return {
        ...state,
        rawImage: {...action.payload.layerData}
      }
    },
    createRawFlightGCPLayerAction(){},
    setRawFlightGCPLayerDataAction(state, action){
      return {
        ...state,
        gcp: {...action.payload.layerData}
      }
    },
    removeRawFlightLayersAction(){}
  }
})

export const {
  createRawFlightImageLayerAction,
  setRawFlightImageLayerDataAction,
  createRawFlightGCPLayerAction,
  setRawFlightGCPLayerDataAction,
  removeRawFlightLayersAction
} = flightUploadLayers.actions

export default flightUploadLayers.reducer