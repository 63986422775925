const white = '#FFFFFF';
const black = '#000000';

const palette = {
  black,
  white,
  primary: {
    contrastText: black,
    main: '#FFC500',
  },
  secondary: {
    contrastText: white,
    dark: '#626262',
    main: black,
    light: '#ABA8A8',
  },
  error: {
    contrastText: white,
    main: '#F30000',
  },
  text: {
    primary: '#252F40',
    secondary: '#ABA8A8',
    link: '#626262',
  },
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  icon: {
    grey: '#626262'
  },
  divider: '#979797',
  borderPrimary: '#FFC500',
  borderSecondary: '#00000061',
  grey: {
    200: '#eeeeee',
    300: '#e0e0e0',
    700: '#474747'
  },
};

export default palette
