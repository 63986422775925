import IconButton from "@mui/material/IconButton";
import { getIconComponentByType } from "../../../utils/assets";
import { useContext, useState } from "react";
import { DrawToolContext } from "../../../utils/layers/draw/DrawToolContext";
import { drawGeometryType } from "../../../utils/layers/draw/drawGeometryType";
import { useDispatch } from "react-redux";
import { setMeasurementGeoJsonAndType } from "../../../redux/slices/measurement/create";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const MeasurePointButton = ({ buttonStyle, showLabel = false }) => {
  const dispatch = useDispatch();
  const drawTool = useContext(DrawToolContext);
  const history = useHistory();

  const pointGeometryData = (feature) => {
    if (feature && feature.length > 0) {
      dispatch(
        setMeasurementGeoJsonAndType({ geojson: feature[0], type: "Point" })
      );
      history.push("/measure/add");
    }
  };
  const handleClick = (callback) => {
    drawTool.drawGeometry(drawGeometryType.point, callback);
  };

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      onClick={() => handleClick(pointGeometryData)}
    >
      <IconButton sx={{ ...buttonStyle }}>
        {getIconComponentByType("addLocation")}
      </IconButton>
      {showLabel && <Typography>Point</Typography>}
    </Box>
  );
};

export default MeasurePointButton;
